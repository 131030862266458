import { ChainIdEnum } from 'config/constants/network';
import { QueryDepositStatusEnums } from 'config/constants/transactions';

export enum TransactionStatusEnum {
  Failed = -1,
  Pending = 1,
  InBatch = 2,
  Reviewing = 3,
  Succeeded = 10,
}

export enum TransactionTypeEnum {
  Deposit,
  Withdraw,
  Reward,
  Bonus,
  Swap,
}

export class Transaction {
  id?: number;

  status: TransactionStatusEnum;

  txnHash?: string;

  value: string;

  createTime?: number;

  currency: string;

  network: ChainIdEnum;

  type: TransactionTypeEnum;

  fee: string;
}

export class TransactionWithdraw {
  status: TransactionStatusEnum;

  code?: string;

  value: string;

  createTime?: number;

  currency: string;

  network: ChainIdEnum;

  type: TransactionTypeEnum;

  fee: string;
}

export class QueryDepositInfo {
  depositInfo: {
    currency: string;
    network: ChainIdEnum;
    value: string;
    status: TransactionStatusEnum;
  };

  status: QueryDepositStatusEnums;
}
