// INTERGRATE SOLANA
// import { WalletContextState, useWallet } from '@solana/wallet-adapter-react'
import { TransactionResponse } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Token } from 'config/types';
import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'state';
import { deposit as depositEvmToken } from 'utils/infura';

function useDeposit(token: Token) {
  const { library, account: evmAccount } = useWeb3React();
  const [submiting, setSubmiting] = useState(false);
  // const solanaWallet: WalletContextState = useWallet()

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes);
  const tokenDepositInfo = useMemo(() => {
    if (!token) return null;

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress;

    if (!spender) return null;

    return {
      spender,
      token,
    };
  }, [token, tokenInfoes]);

  const depositEvm = useCallback(
    async (value: string): Promise<TransactionResponse> => {
      const hash = await depositEvmToken(
        library.getSigner(evmAccount),
        tokenDepositInfo.token,
        value,
        tokenDepositInfo.spender,
      );
      return hash;
    },
    [tokenDepositInfo, library, evmAccount],
  );

  // const depositSolana = useCallback(
  //   async (value: string) => {
  //     const hash = await depositSolToken(tokenDepositInfo.token, value, solanaWallet)
  //     return hash
  //   },
  //   [tokenDepositInfo, solanaWallet],
  // )

  const deposit = useCallback(
    async (value: string) => {
      // const depositFn = isSolToken(tokenDepositInfo.token) ? depositSolana : depositEvm
      const depositFn = depositEvm;

      setSubmiting(true);
      const hash = await depositFn(value);
      setSubmiting(false);

      return hash;
    },
    [depositEvm],
  );

  return {
    deposit,
    submiting,
  };
}

export default useDeposit;
