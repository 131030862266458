import BigNumber from 'bignumber.js';
import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import FormControl from 'components/FormControl';
import FormInput from 'components/FormControl/FormInput';
import { StyledControlContainer } from 'components/FormControl/styled';
import { InputLabel, InputMessage, StyledInput } from 'components/Input/styled';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import TokenInput from 'components/TokenInput';
import { LoginMethod } from 'config/constants/auth';
import FormValidator from 'config/constants/formValidator';
import { Token } from 'config/types';
import { ValidationError } from 'config/types/validator';
import useDebounce from 'hooks/useDebounce';
import useForm from 'hooks/useForm';
import useModal from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'state';
import { useListNetworks, useTokenSelected } from 'state/app/hooks';
import { useUserInfo } from 'state/auth/hooks';
import { useTokenAppBalance } from 'state/profile/hooks';
import { useAddWithdrawTransaction } from 'state/transaction/hooks';
import styled from 'styled-components';
import { Icons } from 'svgs';
import theme from 'theme';
import { getBalanceTokenKey } from 'utils';
import { getFullDisplayBalance } from 'utils/formatBalance';
import HistoryTransaction from 'views/HistoryTransaction';
import { ChainIdEnum } from 'config/constants/network';
import tokens from 'config/constants/tokens';
import PaymentTokenSelect from '../PaymentTokenSelect';
import TraditionalWithdrawButton from './TraditionalWithdrawButton';
import WalletWithdrawButton from './WalletWithdrawButton';
import { useWithdrawFee } from './hooks';

const WithdrawInputErrorMessages = {
  receiveAddress: {
    [ValidationError.Required]: 'Address is required',
    [ValidationError.IsEtherAddress]: 'Your address is invalid',
  },
  withdrawValue: {
    [ValidationError.Insufficient]: 'Insufficient Balance',
    [ValidationError.LessThanEqual]: 'Amount must be greater than withdraw fee',
  },
};

const Withdraw: React.FC = () => {
  const { loginBy, username } = useUserInfo();
  const { wallet } = useAppSelector((state) => state.auth);
  const isLoginByEmail = loginBy === LoginMethod.Email;
  const { t } = useTranslation();
  const [onPresentModalHistoryTxn] = useModal(HistoryTransaction);

  const initialToken = useTokenSelected();
  const networks = useListNetworks({
    excludeChains: [ChainIdEnum.HPO],
  });

  const fallbackToken = useMemo(() => {
    return initialToken.network === ChainIdEnum.HPO ? tokens[networks[0]?.chainId].BNB : initialToken;
  }, [networks, initialToken]);

  const [selectedToken, setSelectedToken] = useState<Token>(fallbackToken);

  const tokenBalances = useAppSelector((state) => state.profile.balances);

  const selectTokenAmount = useTokenAppBalance(selectedToken);

  const { withdrawFee, updateFeeWithdraw } = useWithdrawFee(selectedToken);
  const handleAddWithdrawTransaction = useAddWithdrawTransaction();

  const { states, controls, validateAll, isValid, validate } = useForm({
    value: {
      validators: [FormValidator.lte(withdrawFee), FormValidator.max(selectTokenAmount?.amount)],
      value: '',
    },
    receiveAddress: {
      validators: [FormValidator.required, FormValidator.etherAddress],
      value: isLoginByEmail ? '' : wallet?.address || username,
    },
  });
  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      return Promise.resolve(new BigNumber(tokenBalances[getBalanceTokenKey(token.network, token.code)] || 0));
    },
    [tokenBalances],
  );

  const onWithdrawSuccess = async (txnCode: string) => {
    handleAddWithdrawTransaction(
      txnCode,
      selectTokenAmount.token,
      states.value.value.toString(),
      withdrawFee.toString(),
    );
    controls.value.onValueChanged('');
  };

  const amountDebounce = useDebounce(states.value.value, 1000);
  const feeDebounce = useDebounce(withdrawFee.toString(), 1000);

  useEffect(() => {
    if (states.value.isDirty && !Number.isNaN(feeDebounce)) {
      validate('value');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeDebounce]);

  useEffect(() => {
    if (!Number.isNaN(amountDebounce) && Number(amountDebounce) > 0) {
      updateFeeWithdraw(amountDebounce.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountDebounce]);

  const handleSelectToken = (token: Token) => {
    setSelectedToken(token);
    controls.value.onValueChanged('');
  };

  return (
    <OpenEffect
      openType="fade"
      duration={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <StyledWrapper flexDirection="column" width="100%" height="100%" justifyContent="space-between" pb="24px">
        <Flex flex="1 1" flexDirection="column">
          <PaymentTokenSelect
            token={selectedToken}
            onTokenSelect={handleSelectToken}
            fetchTokenBalanceFn={fetchTokenBalanceFn}
            networks={networks}
          />

          <>
            {loginBy === LoginMethod.Email && (
              <StyledFormControl
                mt="16px"
                state={states.receiveAddress}
                label={t('Address')}
                formatErrorMessage={(errors) => WithdrawInputErrorMessages.receiveAddress[errors[0]]}
              >
                <FormInput control={controls.receiveAddress} placeholder={t('Address')} type="address" name="address" />
              </StyledFormControl>
            )}

            <StyledControlContainer mt="16px" state={states.value}>
              <StyledLabel>
                <InputLabel mb="0px !important">
                  <Trans>Amount</Trans>
                </InputLabel>
                <InputLabel mb="0px !important">
                  <Trans>Withdrawable</Trans>: {getFullDisplayBalance(selectTokenAmount?.amount, 0, 5)}{' '}
                  {selectedToken?.name}
                </InputLabel>
              </StyledLabel>
              <TokenInput
                value={states.value.value}
                token={selectedToken}
                errors={states.value.errors}
                validators={controls.value.validators}
                onErrorChanged={controls.value.onErrorChanged}
                onValueChanged={controls.value.onValueChanged}
                max={selectTokenAmount?.amount}
              />
              <Flex flexDirection={['column']} justifyContent="space-between">
                <InputMessage textAlign="right">
                  <Trans>{WithdrawInputErrorMessages.withdrawValue[states.value.errors[0]]}</Trans>
                </InputMessage>

                <Text color="textAlt" fontSize="14px" textAlign="right" mt="4px">
                  <Trans>
                    Withdrawal fee{' '}
                    {{ withdrawFee: Number.isNaN(withdrawFee.toNumber()) ? '...' : withdrawFee.toNumber() }}{' '}
                    {{ currency: selectedToken?.name }}
                  </Trans>
                </Text>
              </Flex>
            </StyledControlContainer>

            <Flex mt="30px" justifyContent="space-between" height="16px">
              {states.value.value && Number(states.value.value) > withdrawFee.toNumber() && (
                <>
                  <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                    <Trans>You will get</Trans>:
                  </Text>

                  <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                    {getFullDisplayBalance(
                      new BigNumber(states.value.value).minus(withdrawFee),
                      0,
                      selectedToken.decimals,
                    )}{' '}
                    {selectedToken.name}
                  </Text>
                </>
              )}
            </Flex>
          </>
        </Flex>

        <Box width="100%" mt="12px" mb="10px">
          {loginBy === LoginMethod.Email ? (
            <TraditionalWithdrawButton
              width="100%"
              fee={withdrawFee.toString()}
              selectedToken={selectedToken}
              toAddress={states.receiveAddress.value}
              validateAll={validateAll}
              onSuccess={onWithdrawSuccess}
              value={states.value.value}
              disabled={!isValid || !states.value.value}
            />
          ) : (
            <WalletWithdrawButton
              width="100%"
              selectedToken={selectedToken}
              validateAll={validateAll}
              onSuccess={onWithdrawSuccess}
              value={states.value.value}
              disabled={!isValid || !states.value.value}
            />
          )}
        </Box>

        <Flex justifyContent="flex-end" alignItems="center" mt={2} onClick={onPresentModalHistoryTxn}>
          <StyledViewHistoryText>
            <Trans>View History</Trans>
          </StyledViewHistoryText>
          <Box position="relative" top={1.6}>
            <Icons.ChevronRightIcon width={20} height={12} fill={theme.colors.text} />
          </Box>
        </Flex>
      </StyledWrapper>
    </OpenEffect>
  );
};

const StyledWrapper = styled(Flex)`
  flex: 1;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 480px;
  }
`;

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  ${StyledInput} {
    height: 46px;
  }
`;

const StyledViewHistoryText = styled(Text)`
  cursor: pointer;
  font-size: 12px;
`;

export default Withdraw;
