import BigNumber from 'bignumber.js';
import {
  BonusCondition,
  BonusInfomation,
  BonusStatusEnums,
  BonusTagEnums,
  IBonusCondition,
  ICommonBonus,
  IDepositCommonBonus,
  IWageringCondition,
  WageringBonusStatusEnums,
} from '.';

export enum ApiBonusStatusEnums {
  WaitDeposit = 1, // deposit bonus
  WaitActive = 2, // no deposit bonus
  Processing = 3,
  Completed = 4,
  UserCancel = 5,
  Cancel = 6,
}

export interface IUserBonus extends ICommonBonus {
  info: BonusInfomation;
  condition: IBonusCondition;
  id: string;
  apiStatus?: ApiBonusStatusEnums;
}

export abstract class UserBonus implements IUserBonus {
  id: string;

  info: BonusInfomation;

  condition: BonusCondition;

  apiStatus?: ApiBonusStatusEnums;

  tags?: BonusTagEnums[];

  status?: BonusStatusEnums;

  constructor(bonus: IUserBonus) {
    this.id = bonus.id;
    this.info = bonus.info;
    this.condition = new BonusCondition(bonus.condition);
    this.apiStatus = bonus.apiStatus;

    this.status = BonusStatusEnums.Available;
  }
}

export interface IUserWageringCondition extends IWageringCondition {
  currentWager?: string;
  targetWager?: string;
}

export class WageringConditionBonus extends UserBonus implements IUserWageringCondition {
  readonly tags = [BonusTagEnums.CanActive];

  minBet: number;

  rakeFeeRequirement: number;

  minDeposit: string;

  maxBonus: string;

  wageringBonusStatus: WageringBonusStatusEnums;

  currentWager?: string;

  targetWager?: string;

  activeDurationInDays: number;

  public get currentWagerBigAmount() {
    const currentWagerBigAmount = new BigNumber(this.currentWager);
    return currentWagerBigAmount.gt(this.targetWagerBigAmount) ? this.targetWagerBigAmount : currentWagerBigAmount;
  }

  public get targetWagerBigAmount() {
    return new BigNumber(this.targetWager);
  }

  public get progress() {
    if (this.targetWagerBigAmount.eq(0)) return 100;

    return Math.floor(this.currentWagerBigAmount.div(this.targetWagerBigAmount).multipliedBy(10000).toNumber()) / 100;
  }

  public get minDepositBigAmount() {
    return new BigNumber(this.minDeposit);
  }

  public get maxBonusBigAmount() {
    return new BigNumber(this.maxBonus);
  }

  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus);

    this.rakeFeeRequirement = wageringConditionValues.rakeFeeRequirement;
    this.minDeposit = wageringConditionValues.minDeposit;
    this.currentWager = wageringConditionValues.currentWager;
    this.targetWager = wageringConditionValues.targetWager;
    this.activeDurationInDays = wageringConditionValues.activeDurationInDays;
    this.status = BonusStatusEnums.Available;
  }
}

export class DepositBonus extends WageringConditionBonus implements IDepositCommonBonus {
  percent: number;

  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus, wageringConditionValues);
    this.percent = new BigNumber(wageringConditionValues.currentWager)
      .dividedBy(wageringConditionValues.targetWager)
      .multipliedBy(100)
      .toNumber();
  }
}

export class NoDepositBonus extends WageringConditionBonus {
  readonly tags = [BonusTagEnums.CanActive, BonusTagEnums.NoDeposit];

  // eslint-disable-next-line no-useless-constructor
  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus, wageringConditionValues);
  }
}
