import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import TextError from 'components/Text/TextError';
import { LoginMethod } from 'config/constants/auth';
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
import tokens from 'config/constants/tokens';
import { Token } from 'config/types';
import useModal from 'hooks/useModal';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'state';
import { useListNetworks, useTokenSelected } from 'state/app/hooks';
import { useUserInfo } from 'state/auth/hooks';
import styled from 'styled-components';
import { Icons } from 'svgs';
import theme from 'theme';
import { getCurrencyBalance } from 'utils/infura';
import { validateTokenBlackList } from 'utils/token';
import HistoryTransaction from 'views/HistoryTransaction';
import { useQueryDepositModal } from 'views/QueryDeposit/useQueryDepositModal';
import PaymentTokenSelect from '../PaymentTokenSelect';
import DepositByContract from './DepositByContract';
import DepositByQRCode from './DepositByQRCode';
import WaitingDepositBonusList from './WaitingDepositBonusList';
import { useWaitingDepositToActiveBonus } from './hooks';

const Deposit: React.FC = () => {
  const { t } = useTranslation();
  const [onPresentModalHistoryTxn] = useModal(HistoryTransaction);
  const { onPresentQueryDeposit } = useQueryDepositModal();

  const { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired } = useWaitingDepositToActiveBonus();

  const [isDepositing, setIsDepositing] = useState(false);
  const playToken = useTokenSelected();
  const networks = useListNetworks({
    excludeChains: [ChainIdEnum.HPO],
  });

  const fallbackToken = useMemo(() => {
    return playToken.network === ChainIdEnum.HPO ? tokens[networks[0].chainId].BNB : playToken;
  }, [networks, playToken]);

  const [selectedToken, setSelectedToken] = useState<Token>(fallbackToken);
  const wallet = useAppSelector((state) => state.auth.wallet);
  const address = wallet?.address;
  const { loginBy } = useUserInfo();

  // INTERGRATE SOLANA
  // wallet?.type == WalletType.SOL
  //   ? { selectChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] }
  //   : { excludeChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] },

  const isDepositByQRCorde = loginBy === LoginMethod.Email;

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      if (!address) return null;
      const amount = await getCurrencyBalance(token, address);
      return amount.amount;
    },
    [address],
  );

  const isTokenBlackList = validateTokenBlackList(selectedToken);

  return (
    <StyledContainer id="modalpayment" mb="15px" openType="grow" duration={0.4}>
      <PaymentTokenSelect
        disabled={isDepositing}
        token={selectedToken}
        onTokenSelect={(token) => setSelectedToken(token)}
        networks={networks}
        fetchTokenBalanceFn={isDepositByQRCorde ? null : fetchTokenBalanceFn}
      />

      {isTokenBlackList ? (
        <TextError
          maxWidth="100% !important"
          my="12px"
          message={t(
            `Deposit with ${selectedToken.name}(${
              NETWORK_MAP[selectedToken.network].networkInfo.shortName
            }) has been suspended.`,
          )}
        />
      ) : (
        <>
          <WaitingDepositBonusList
            mt="20px"
            disabled={isDepositing}
            selectedToken={selectedToken}
            bonuses={bonuses}
            handleSelectedBonus={handleSelectedBonus}
            selectedBonus={selectedBonus}
            isSelectedBonusExpired={isSelectedBonusExpired}
          />
          {isDepositByQRCorde ? (
            <DepositByQRCode depositToken={selectedToken} />
          ) : (
            <DepositByContract
              setIsDepositing={setIsDepositing}
              isDepositing={isDepositing}
              depositToken={selectedToken}
              flex="1 1"
              handleSelectedBonus={handleSelectedBonus}
              selectedBonus={selectedBonus}
              isSelectedBonusExpired={isSelectedBonusExpired}
            />
          )}
          <Flex justifyContent="center" mt={2}>
            <Box
              id="link-querydeposit"
              color="textHighlight"
              display="block"
              margin="auto"
              onClick={() => {
                onPresentQueryDeposit();
              }}
            >
              <Text
                fontSize="12px"
                color="textTertiary"
                fontWeight={300}
                lineHeight="18px"
                style={{
                  letterSpacing: '-0.02em',
                  cursor: 'pointer',
                }}
              >
                <Trans>Deposit haven't arrived?</Trans>
                <span
                  style={{
                    color: theme.colors.textHighlight,
                    fontWeight: 400,
                    marginLeft: '6px',
                  }}
                >
                  <Trans>Click here</Trans>
                </span>
              </Text>{' '}
            </Box>
          </Flex>

          <Flex
            id="view-history"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
            onClick={onPresentModalHistoryTxn}
          >
            <StyledViewHistoryText>
              <Trans>View History</Trans>
            </StyledViewHistoryText>
            <Box position="relative" top={1.6}>
              <Icons.ChevronRightIcon width={20} height={12} fill={theme.colors.text} />
            </Box>
          </Flex>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledViewHistoryText = styled(Text)`
  cursor: pointer;
  font-size: 12px;
`;

export default Deposit;
