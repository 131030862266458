import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Text from 'components/Text';
import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { getIcon, Icons } from 'svgs';
import { colors } from 'theme/colors';
import theme from '../theme';

const toastContainerStyle = {
  backgroundColor: `${colors.inputAlt}`,
  borderRadius: `${theme.radii.default}`,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
};

export function showToast(
  content: string | React.ReactNode,
  config?: ToastOptions,
  progressColor: keyof typeof colors = 'info',
) {
  return toast(content, {
    style: toastContainerStyle,
    progressStyle: { height: 2, background: colors[progressColor] as string },
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...config,
  });
}

const ToastTemplate: React.FC<{
  icon: keyof typeof Icons;
  color: keyof typeof colors;
  content: string;
  desc?: string;
}> = ({ icon, color, content, desc }) => {
  const Icon = getIcon(icon);
  return (
    <Box>
      <Flex alignItems="flex-start">
        <Box width="24px" height="24px">
          <Icon width="24px" height="24px" fill={colors[color]} />
        </Box>
        <Box ml="8px" mt="4px">
          <Text small bold color={color}>
            {content}
          </Text>
          {desc && (
            <Text small color="textSubtle" mt="6px" lineHeight="1.05em">
              {desc}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export const HunnyToast = {
  show: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(<ToastTemplate icon="InfoIcon" color="info" content={content} desc={desc} />, config, 'info');
  },
  error: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="CloseCircleIcon" color="error" content={content} desc={desc} />,
      config,
      'error',
    );
  },
  success: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="SuccessIcon" color="success" content={content} desc={desc} />,
      config,
      'success',
    );
  },
  warn: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="WarningIcon" color="warning" content={content} desc={desc} />,
      config,
      'warning',
    );
  },
  dismiss: (toastId: React.ReactText) => {
    toast.dismiss(toastId);
  },
};
