import Flex from 'components/Box/Flex';
import Text from 'components/Text';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import React from 'react';

interface TabLabelProps {
  label: string;
  Icon: React.ReactNode;
}

const TabLabel: React.FC<TabLabelProps> = ({ Icon, label }) => {
  return (
    <StyledTabLabel>
      {Icon}
      <Text fontSize="14px" ml="4px" fontWeight={600}>
        <Trans>{label}</Trans>
      </Text>
    </StyledTabLabel>
  );
};

const StyledTabLabel = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.text};
  align-items: center;
  justify-content: center;
  display: flex;
`;

export default TabLabel;
