import { useUserInfo } from 'state/auth/hooks';

type UserPropertiesKey = 'language' | 'coin' | 'type' | 'wallet' | 'vip' | 'referral_by';
type EventKey = 'search' | 'select_balance' | 'swap_husd' | 'withdraw' | 'deposit';

export const useAnalytics = () => {
  const { uid } = useUserInfo();

  const recordEvent = (event: EventKey, value: any) => {
    let leafValue = value || {};
    let parsedValue = `[${event}]`;

    while (typeof leafValue === 'object') {
      const key = Object.keys(leafValue)[0];
      leafValue = leafValue[key];
      if (key) parsedValue = `${parsedValue}[${key}]`;
    }

    if (leafValue) parsedValue = `${parsedValue}[${leafValue}]`;

    window.dataLayer.push({
      event: parsedValue,
      userId: uid,
    });
  };

  const addEventString = (event: string) => {
    window?.dataLayer?.push({ event, userId: uid });
  };

  const updateUserType = (key: UserPropertiesKey, value: string) => {
    // window?.dataLayer?.push({ [key]: value, userId: Number(uid) });
  };

  const updateUserId = (uid: string) => {
    window?.dataLayer?.push({ userId: uid });
  };

  return { recordEvent, updateUserType, updateUserId, addEventString };
};

// Initialize Analytics and get a reference to the service
