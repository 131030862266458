import { useCallback } from 'react';
import { useAppDispatch } from 'state';
import { useAuth, useClientLogout } from 'state/auth/hooks';
import { updatePreparedBonusId } from 'state/bonus/actions';

import { updateMyProfile } from 'state/profile/actions';
import { fetchMyUserInfo } from 'state/profile/calls/fetchUserInfos';

export const useFetchUserProfile = () => {
  const { isSigned } = useAuth();
  const dispatch = useAppDispatch();
  const logout = useClientLogout();

  const fetch = useCallback(async () => {
    if (!isSigned) return null;
    const result = await fetchMyUserInfo();
    if (!result || !result?.address) {
      return logout();
    }

    dispatch(updatePreparedBonusId(result?.preparedBonusId));
    dispatch(updateMyProfile({ data: result }));

    return result;
  }, [dispatch, isSigned, logout]);

  return fetch;
};
