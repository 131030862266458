import BigNumber from 'bignumber.js';
import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Text from 'components/Text';
import TokenLogo from 'components/TokenLogo';
import tokens from 'config/constants/tokens';
import { Token } from 'config/types';
import { Transaction, TransactionStatusEnum, TransactionTypeEnum } from 'config/types/transaction';
import React from 'react';
import { Trans } from 'react-i18next';
import { Icons } from 'svgs';
import theme from 'theme';
import { colors } from 'theme/colors';
import { getFullDisplayBalance } from 'utils/formatBalance';

const CompletedTransaction: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const token = tokens[transaction.network][transaction.currency] as Token;
  const displayAmount = getFullDisplayBalance(new BigNumber(transaction.value), 0, 5);

  const color = transaction.status === TransactionStatusEnum.Succeeded ? colors.success : colors.error;

  return (
    <Box minWidth="250px">
      <Flex alignItems="center">
        <Box
          mr="12px"
          height="100%"
          padding="6px"
          border={`1px solid ${color}`}
          background={`${color}33`}
          borderRadius={theme.radii.tiny}
        >
          {transaction.status === TransactionStatusEnum.Succeeded ? (
            <Icons.SuccessCheckIcon height="24px" fill={color} />
          ) : (
            <Icons.ClearIcon height="24px" fill={color} />
          )}
        </Box>
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Text color="success" fontSize="16px" bold>
            {transaction.type === TransactionTypeEnum.Deposit ? (
              <Trans>Deposit confirmed!</Trans>
            ) : transaction.status === TransactionStatusEnum.Succeeded ? (
              <Trans>Withdraw compeleted!</Trans>
            ) : (
              <Trans>Withdraw failed!</Trans>
            )}
          </Text>
          <Flex mt="4px" alignItems="center">
            <TokenLogo token={token} width="20px" />
            <Text ml="6px" fontSize="14px">
              {displayAmount} {token.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CompletedTransaction;
