import { useEffect, useState } from 'react';
import privateSocket from 'sockets/privateSocket';
import { useAuth } from 'state/auth/hooks';
import { Socket } from 'socket.io-client';
import { useIsInGame } from 'state/app/hooks';

export const usePrivateSocket = (): Socket => {
  const { isSigned, accessToken } = useAuth();

  const [socket, setSocket] = useState(null);
  const isInGame = useIsInGame();

  const handleCloseSocket = () => {
    privateSocket.Socket.disconnect();
    privateSocket.Socket.close();
    privateSocket.Socket.off('*');
    privateSocket.disconnect();
    privateSocket.close();
    setSocket(null);
  };

  useEffect(() => {
    if (!isSigned || !accessToken || isInGame) return;

    privateSocket.Socket.emit('Subscribe', {
      accessToken,
    });

    privateSocket.Socket.on('Subscribe', () => {});

    setSocket(privateSocket.Socket); // SOCKET

    return () => {
      if (socket) {
        handleCloseSocket();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned, accessToken]);

  useEffect(() => {
    if (isInGame && socket) {
      handleCloseSocket();
    }
  }, [isInGame, socket]);

  return socket;
};
