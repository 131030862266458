/* eslint-disable no-param-reassign */
import { BscConnector } from '@binance-chain/bsc-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WALLETCONNECT_SUPPORTED } from 'config/constants/network';
import { ConnectorNameEnum } from 'config/constants';
import { ethers } from 'ethers';
import { logError } from './sentry';

const POLLING_INTERVAL = 12000;

class HunnyInjectedConnector extends InjectedConnector {
  public name = ConnectorNameEnum.Injected;
}

export class HunnyBscConnector extends BscConnector {
  public name = ConnectorNameEnum.BSC;
}

export class HunnyWalletConnectConnector extends WalletConnectConnector {
  public name = ConnectorNameEnum.WalletConnect;
}

const injected = new HunnyInjectedConnector({});

/// Connect wallet by QR Code
const walletconnect = new HunnyWalletConnectConnector({
  rpc: WALLETCONNECT_SUPPORTED.reduce((result, network) => {
    result[network.chainId] = network.rpcCollections[0];
    return result;
  }, {}),
  bridge: 'https://walletconnect-bridge.hunny.finance/',
  supportedChainIds: WALLETCONNECT_SUPPORTED.map((item) => item.chainId),
  qrcode: true,
});

/// Connect
const bscConnector = new HunnyBscConnector({});

export const connectorsByName: { [connectorName in ConnectorNameEnum]: any } = {
  [ConnectorNameEnum.Injected]: injected,
  [ConnectorNameEnum.WalletConnect]: walletconnect,
  [ConnectorNameEnum.BSC]: bscConnector,
};

export const getLibrary = (provider): any => {
  const library = new ethers.providers.Web3Provider(provider) as any;
  library.pollingInterval = POLLING_INTERVAL;
  library.mapProvider = provider;
  return library;
};

export const signMessage = async (
  provider: any,
  account: string,
  message: any,
  callback: (sign: string, err?: any) => void,
): Promise<void> => {
  const connectorID = window.localStorage.getItem('connectorId');

  try {
    if (window.BinanceChain && connectorID === ConnectorNameEnum.BSC) {
      const { signature } = await window.BinanceChain.bnbSign(account, message);
      callback(signature, null);
      return;
    }

    /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
    if (provider.provider?.wc && connectorID === ConnectorNameEnum.WalletConnect) {
      const walletMeta = provider.provider?.walletMeta;
      const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));

      /**
       * Salepal wallet sign PersonalMessage wrong!
       */
      if (walletMeta.url === 'https://www.isafepal.io' || walletMeta.name === 'SafePal Wallet') {
        provider.provider.send(
          {
            method: 'eth_sign',
            params: [account, message],
            account,
          },
          (err: any, result: any) => {
            callback(result.result, err);
          },
        );

        return;
      }
      const signature = await provider.provider.wc.signPersonalMessage([wcMessage, account]);
      callback(signature);
      return;
    }

    const signature = await provider.getSigner(account).signMessage(message);
    callback(signature);
  } catch (e) {
    logError('EVM sign message', e);
    callback('', e);
  }
};
