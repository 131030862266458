import Text from 'components/Text';
import { TextProps } from 'components/Text/types';
import React from 'react';
import styled from 'styled-components';

const TextError: React.FC<TextProps & { message: string }> = ({ message, ...props }) => {
  return (
    <StyledWrapper fontSize="14px" lineHeight="20px" fontWeight="bold" textAlign="center" {...props}>
      {message}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Text)`
  max-width: 300px;
  padding: 12px;
  border-radius: ${({ theme }) => theme.radii.small};
  background: ${({ theme }) => theme.colors.error}47;
  color: ${({ theme }) => theme.colors.error};
  border: 1px solid ${({ theme }) => theme.colors.error};
`;

export default TextError;
