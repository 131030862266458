import Flex from 'components/Box/Flex';
import EmptyContent from 'components/EmptyContent';
import HunnyLazyList from 'components/HunnyLazyList/HunnyLazyList';
import React, { useCallback } from 'react';
import PaymentService from 'services/PaymentService';
import { useAuth } from 'state/auth/hooks';
import HistoryTransactionListItem, { HistoryTransactionListLoader } from './HistoryTransactionListItem';

const LazyHistoryItem = ({ item }) => <HistoryTransactionListItem transaction={item} />;

const DepositTransactionList: React.FC = () => {
  const { hasSession, isSigned } = useAuth();

  const request = useCallback((offset: number, limit: number) => {
    return PaymentService.getTransactionHistory(offset + 1, limit);
  }, []);

  return hasSession ? (
    <Flex flexDirection="column" className="hunny-lazy-list">
      <HunnyLazyList
        Item={LazyHistoryItem}
        Loader={
          <>
            <HistoryTransactionListLoader />
            <HistoryTransactionListLoader />
            <HistoryTransactionListLoader />
            <HistoryTransactionListLoader />
          </>
        }
        request={isSigned ? request : null}
        limit={12}
        noContent={
          <EmptyContent
            width="100%"
            height="100%"
            minHeight="300px"
            alignItems="center"
            justifyContent="center"
            content="No transaction to show"
          />
        }
      />
    </Flex>
  ) : (
    <EmptyContent justifyContent="center" content="No transaction to show" />
  );
};

export default DepositTransactionList;
