/* eslint-disable no-param-reassign */
export function animate(property: string, element: any, to: number, duration = 150, cb = () => {}) {
  const from = element[property];
  let start = null;
  let cancelled = false;

  const cancel = () => {
    cancelled = true;
  };

  const step = (timestamp) => {
    if (cancelled) {
      return;
    }

    if (start === null) {
      start = timestamp;
    }

    const time = Math.min(1, (timestamp - start) / duration);
    element[property] = time * (to - from) + from;
    if (time >= 1) {
      requestAnimationFrame(() => {
        cb();
      });
      return;
    }

    requestAnimationFrame(step);
  };

  if (from === to) {
    return cancel;
  }

  requestAnimationFrame(step);
  return cancel;
}
