import BigNumber from 'bignumber.js';
import { TokenAmount } from '..';

export enum BonusTagEnums {
  CanActive,
  OnlyUse,
  NoDeposit,
}

export enum BonusStatusEnums {
  Available = 1,
  Active = 2,
  Cancel = 3,
  Used = 10,
  Unknown = -999,
}

export enum BonusStatusFilterEnums {
  Available = 1,
  History = -1,
}

export enum ApiBonusTypeEnums {
  DepositBonus = 1,
  NoDepositBonus = 2,
}

export enum WageringBonusStatusEnums {
  Available,
  WaitingDeposit,
  InProgressWagering,
  Claimed,
  Cancelled,
  Unknown,
}

export interface IBonusCondition {
  expiryDate?: number;
}

export class BonusCondition implements IBonusCondition {
  expiryDate?: number;

  constructor(condition: IBonusCondition) {
    this.expiryDate = condition.expiryDate;
  }

  public get isExpired() {
    return new Date().getTime() > this.expiryDate;
  }
}

export interface BonusInfomation {
  image: string;
  name?: string;
  badge?: string;
  activeDate?: number;
}

export interface ICommonBonus {
  info: BonusInfomation;
  condition: IBonusCondition;
  tags?: BonusTagEnums[];
  status?: BonusStatusEnums;
}
export abstract class CommonBonus implements ICommonBonus {
  code: string;

  info: BonusInfomation;

  condition: BonusCondition;

  tags?: BonusTagEnums[];

  status = BonusStatusEnums.Unknown;

  constructor(bonus: ICommonBonus) {
    this.info = bonus.info;
    this.condition = new BonusCondition(bonus.condition);
  }
}

// ---------------------- Wagering Common Bonus --------------------------
export interface IWageringCondition {
  rakeFeeRequirement: number;
  minDeposit?: string;
  bonusAmount?: TokenAmount;
  activeDurationInDays: number;
  wageringBonusStatus?: WageringBonusStatusEnums;
}

export class WageringConditionCommonBonus extends CommonBonus implements IWageringCondition {
  rakeFeeRequirement: number;

  minDeposit?: string;

  activeDurationInDays: number;

  wageringBonusStatus = WageringBonusStatusEnums.Unknown;

  public get minDepositBigAmount() {
    return new BigNumber(this.minDeposit);
  }

  constructor(bonus: ICommonBonus, wageringConditionValues: IWageringCondition) {
    super(bonus);

    this.rakeFeeRequirement = wageringConditionValues.rakeFeeRequirement;
    this.minDeposit = wageringConditionValues.minDeposit;
    this.activeDurationInDays = wageringConditionValues.activeDurationInDays;
  }

  bonusAmount?: TokenAmount;
}

// ---------------------- Deposit Common Bonus -------------------------- DepositBonus
export interface IDepositCommonBonus {
  percent: number;
}

export class DepositCommonBonus extends WageringConditionCommonBonus implements IDepositCommonBonus {
  percent: number;

  tags = [BonusTagEnums.CanActive];

  constructor(
    bonus: ICommonBonus,
    wageringConditionValues: IWageringCondition,
    depositCommonBonus: IDepositCommonBonus,
  ) {
    super(bonus, wageringConditionValues);
    this.percent = depositCommonBonus.percent;
  }
}

export class NoDepositCommonBonus extends WageringConditionCommonBonus {
  minDeposit = '0';

  tags = [BonusTagEnums.NoDeposit, BonusTagEnums.CanActive];
}
