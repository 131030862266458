import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Button from 'components/Button';
import Image from 'components/Image';
import CircleLoader from 'components/Loader/CircleLoader';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/components/ModalBody';
import { ModalProps } from 'components/Modal/Modal';
import Text from 'components/Text';
import { LoginMethod } from 'config/constants/auth';
import { WalletInfo } from 'config/types/wallet';
import { useReferralCode } from 'hooks/useReferralCode';
import { useRequest } from 'hooks/useRequest';
import useSignMessage from 'hooks/useSignMessage';
import useWalletAccount from 'hooks/useWalletAccount';
import { RowMiddle } from 'layout/Components/Row';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import AuthenticationService from 'services/AuthenticationService';
import { SignInByWalletPayload } from 'services/types';
import { useAppDispatch } from 'state';
import { setIsSigned, updateDeviceUid } from 'state/app/actions';
import { signedExecuteLogin, updateAuthToken } from 'state/auth/action';
import { useAuth } from 'state/auth/hooks';
import styled from 'styled-components';
import { shortedAddress } from 'utils';
import { getID } from 'utils/fingerprint';
import { getTrackingWalletName } from 'utils/trackingWallet';

type SignWalletModalProps = {
  wallet: WalletInfo;
};
const SignWalletModal: React.FC<ModalProps<SignWalletModalProps>> = ({ data, onDismiss }) => {
  const { wallet } = data;
  const refCode = useReferralCode();
  const accounts = useWalletAccount();
  const { execute } = useRequest();
  const signMessage = useSignMessage(wallet.type);
  const [signing, setSigning] = useState(false);

  const { isSigned } = useAuth();
  const dispatch = useAppDispatch();
  const address = accounts[wallet.type];

  useEffect(() => {
    if (!address || isSigned) {
      onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, wallet, isSigned]);

  const handleSignWallet = async () => {
    setSigning(true);

    // TODO check type error
    const {
      data: { acceptMessageText, acceptMessage },
    } = await execute(AuthenticationService.getMessageToWalletSign({ address, walletType: wallet.type }));
    const messageSignature = await signMessage(acceptMessageText);
    if (!messageSignature) {
      setSigning(false);
      return;
    }
    const deviceUid = await getID();
    const params: SignInByWalletPayload = {
      deviceUid,
      address,
      messageSignature,
      acceptMessage,
    };

    params.referralCode = refCode.refCode;

    const result = await AuthenticationService.signInByWallet(params);

    if (result.data?.accessToken) {
      const walletTracked = await getTrackingWalletName(wallet);

      dispatch(
        updateAuthToken({
          accessToken: result.data.accessToken,
          refreshToken: result.data.refreshToken,
        }),
      );

      dispatch(updateDeviceUid({ deviceUid }));
      dispatch(
        signedExecuteLogin({
          loginBy: LoginMethod.Wallet,
          wallet: {
            name: wallet.name,
            type: wallet.type,
            connectorId: wallet.adapter.name,
            address,
            trackingName: walletTracked,
          },
        }),
      );

      dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }));
    }
    setSigning(false);
  };

  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody>
        <Box p="40px 34px">
          <Flex alignItems="center" flexDirection="column">
            {signing ? (
              <Image alt="join" width={80} height={80} src="/images/signing.png" />
            ) : (
              <Image alt="join" width={80} height={80} src="/images/join.png" />
            )}
            <Text bold fontSize="18px" lineHeight="24px" mt="16px">
              <Trans>Signing with this address: </Trans>
            </Text>
            <Text bold fontSize="18px" mt="2px">
              {shortedAddress(address)}
            </Text>

            <Text color="textSubtle" fontSize="14px" mt="28px" textAlign="center">
              <Trans>
                You need to sign a message on your wallet to prove ownership of the address you are connected with.
              </Trans>
            </Text>

            <StyledButton id="signmessage-button" width="100%" mt="40px" onClick={handleSignWallet} disabled={signing}>
              {signing ? (
                <RowMiddle>
                  <Box mr="8px">
                    <CircleLoader />
                  </Box>
                  <Trans>Waiting for Signature</Trans>
                </RowMiddle>
              ) : (
                <Trans>Sign Message</Trans>
              )}
            </StyledButton>
          </Flex>
        </Box>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`;

export default SignWalletModal;
