import { useCallback, useEffect, useMemo, useRef } from 'react';
import { HunnyRequest } from 'services/types';

export const useRequest = () => {
  const requests = useRef([]);

  const cancelAllRequest = useCallback(() => {
    while (requests.current.length) {
      const request = requests.current.pop();
      request.cancel();
    }
  }, [requests]);

  useEffect(() => {
    return () => {
      // Cancel all pending request before component destroyed
      cancelAllRequest();
    };
  }, [cancelAllRequest]);

  const execute = useCallback(
    <S>(request: HunnyRequest<S>): Promise<S> => {
      requests.current.push(request);
      return request.call();
    },
    [requests],
  );

  return useMemo(() => ({ execute, cancelAllRequest }), [execute, cancelAllRequest]);
};
