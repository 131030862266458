import Text from 'components/Text';
import { useRequest } from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { BaseResponse, HunnyRequest, Paging } from 'services/types';
import { delayed } from 'utils';
import { forkjoinRequest } from 'utils/requestHelper';

interface HunnyLazyListProps {
  Item: React.FC<{ item: any }>;
  Loader: React.ReactNode;
  limit: number;
  request?: (offset: number, limit: number) => HunnyRequest<BaseResponse<Paging<any>>>;
  uniqueKey?: string;
  noContent?: any;
}

const HunnyLazyList: React.FC<HunnyLazyListProps> = ({ Item, request, Loader, limit, uniqueKey = 'id', noContent }) => {
  const [data, setData] = useState(null);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTriggerLoad, setIsTriggerLoad] = useState(null);
  const { execute, cancelAllRequest } = useRequest();

  useEffect(() => {
    setData(null);
    cancelAllRequest();
    setIsTriggerLoad({});
  }, [request]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      let response: BaseResponse<Paging<any>>;

      if (typeof request === 'function') {
        const offset = data ? data.length * limit : 0;
        const [res] = await forkjoinRequest([execute(request(offset, limit)), delayed(500)]);
        response = res;
      }

      if (response?.data && response.data.items.length) {
        setData([...(data || []), response.data.items]);
        setIsLoadMore(response.data.items.length === limit);
      } else {
        if (!data) {
          setData([]);
        }
        setIsLoadMore(false);
      }

      setIsLoading(false);
    };

    if (isTriggerLoad !== null) fetch();
  }, [isTriggerLoad]);

  return (
    <>
      {!isLoading && data && !data.length && noContent && <Text color="textSubtle">{noContent}</Text>}

      {data?.map((pageData, index) => (
        <ItemList key={`lazy-item-list-${index.toString()}`} data={pageData} Item={Item} uniqueKey={uniqueKey} />
      ))}

      {isLoading && <>{Loader}</>}

      {!isLoading && isLoadMore && data && data.length > 0 && (
        <LazyLoad height={100} offset={0}>
          <LoadMoreTrigger setIsTriggerLoad={setIsTriggerLoad} />
        </LazyLoad>
      )}
    </>
  );
};

const ItemList = React.memo(
  ({ data, Item, uniqueKey }: { data: any[]; Item: React.FC<{ item: any }>; uniqueKey: string }) => {
    return (
      <>
        {data.map((item) => (
          <Item item={item} key={item[uniqueKey]} />
        ))}
      </>
    );
  },
);

const LoadMoreTrigger = React.memo(({ setIsTriggerLoad }: any) => {
  useEffect(() => {
    setIsTriggerLoad({});
  }, []);

  return null;
});

export default HunnyLazyList;
