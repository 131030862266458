import styled from 'styled-components';
import { space } from 'styled-system';

const Table = styled.table`
  max-width: 100%;
  width: 100%;

  ${space}
`;

const TableBody = styled.tbody`
  & tr {
    td {
      vertical-align: middle;
    }
  }
`;

export { TableBody };
export default Table;
