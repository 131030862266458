import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Link from 'components/Link';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import TokenLogo from 'components/TokenLogo';
import configTokens from 'config/constants/tokens';
import { Transaction, TransactionTypeEnum } from 'config/types/transaction';
import { RowBetween } from 'layout/Components/Row';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { shortedAddress } from 'utils';
import { formatDisplayDateTime } from 'utils/dateHelper';
import React from 'react';
import { StyledValueTable, TransactionStatus } from './TableTransaction';

export const HistoryTransactionListLoader = () => {
  return (
    <StyledContainer>
      <RowBetween mb="16px">
        <Flex alignItems="center">
          <Text color="textSubtle" fontSize="14px" mr={2}>
            <Skeleton width={60} height={5} />
          </Text>
          <Skeleton width={60} height={5} />
        </Flex>

        <Skeleton width="30%" height={5} />
      </RowBetween>

      <RowBetween mb="16px">
        <Flex alignItems="center">
          <Text color="textSubtle" fontSize="14px">
            <Trans>Amount</Trans>
          </Text>

          <Skeleton width={75} height={5} />
        </Flex>

        <Skeleton width="30%" height={5} />
      </RowBetween>

      <RowBetween mb="16px">
        <Text color="textSubtle" fontSize="14px">
          <Trans>Fee</Trans>
        </Text>

        <Skeleton width="30%" height={5} />
      </RowBetween>

      <RowBetween>
        <Text color="textSubtle" fontSize="14px">
          <Trans>Hash</Trans>
        </Text>

        <Skeleton width="30%" height={5} />
      </RowBetween>
    </StyledContainer>
  );
};

const DepositTransactionListITem: React.FC<{
  transaction: Transaction;
}> = ({ transaction }) => {
  const token = configTokens[transaction.network]?.[transaction.currency];

  return (
    <StyledContainer>
      <RowBetween mb="16px">
        <Flex alignItems="center">
          <Text color="textSubtle" fontSize="14px" mr={2}>
            <Trans>{TransactionTypeEnum[transaction.type]}</Trans>
          </Text>
          <TransactionStatus status={transaction.status} />
        </Flex>

        <StyledValueTable>{formatDisplayDateTime(new Date(transaction.createTime))}</StyledValueTable>
      </RowBetween>

      <RowBetween mb="16px" alignItems="flex-start">
        <Flex alignItems="center">
          <Text color="textSubtle" fontSize="14px">
            <Trans>Amount</Trans>
          </Text>

          <Text color="textSubtle" fontSize="14px" ml={1}>
            ({token.name})
          </Text>
        </Flex>

        <Flex flexDirection="column" alignItems="flex-end">
          <Flex alignItems="center">
            <TokenLogo token={token} size={24} />
            <StyledValueTable ml={2}>{transaction.value}</StyledValueTable>
          </Flex>
        </Flex>
      </RowBetween>

      <RowBetween mb="16px">
        <Text color="textSubtle" fontSize="14px">
          <Trans>Fee</Trans>
        </Text>

        <StyledValueTable>{transaction.fee ? transaction.fee : '_'}</StyledValueTable>
      </RowBetween>

      <RowBetween>
        <Text color="textSubtle" fontSize="14px">
          <Trans>Hash</Trans>
        </Text>

        <Link href={transaction.txnHash} external>
          <StyledHash>{transaction.txnHash ? shortedAddress(transaction.txnHash) : '_'}</StyledHash>
        </Link>
      </RowBetween>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  border-radius: ${({ theme: { radii } }) => radii.small};
  background: ${({ theme }) => theme.colors.backgroundAlt7};

  padding: 12px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
  }

  margin-bottom: 12px;
`;

const StyledHash = styled(StyledValueTable)`
  text-decoration: underline;
  margin-left: 2.5px;
`;

export default DepositTransactionListITem;
