import Box from 'components/Box/Box';
import styled from 'styled-components';
import { PolymorphicComponent } from 'utils/polymorphic';
import Button from './Button';
import { BaseButtonProps } from './types';

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button).attrs({
  as: Box,
})<BaseButtonProps>`
  background: transparent;
  padding: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle} !important;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text} !important;
    }
  }
`;

export default IconButton;
