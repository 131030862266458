import useDebounceCallback from 'hooks/useDebounceCallback';
import React, { useRef, useState } from 'react';
import { forkjoinRequest } from 'utils/requestHelper';

import { InputValidator, ValidationError } from 'config/types/validator';
import useIsMounted from 'hooks/useIsMounted';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import { useRequest } from 'hooks/useRequest';
import { HunnyRequest } from 'services/types';

export interface InputProps {
  value: any;
  onValueChanged: (value: any) => void;
  onErrorChanged?: (errors: ValidationError[]) => void;
  onValidating?: () => void;
  onValidated?: () => void;
  validators?: InputValidator[];
  validateOnchange?: boolean;
}

export const validateFormControl = async (validators: Promise<ValidationError>[]): Promise<ValidationError[]> => {
  const result = await forkjoinRequest(validators);
  return result.filter((error) => error);
};

const Input: React.FC<
  InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
  value,
  validators,
  validateOnchange,
  type,
  onValueChanged,
  onErrorChanged,
  onValidating,
  onValidated,
  ...props
}) => {
  const debounceCallback = useDebounceCallback();
  const [errors, setErrors] = useState([]);
  const validatedValue = useRef(null);
  const isMounted = useIsMounted();

  const { execute, cancelAllRequest } = useRequest();

  const validate = async () => {
    if (validators && validators.length > 0 && onErrorChanged) {
      if (validatedValue.current === value) return errors;
      let hasAsyncValidator = false;
      const parsedValidators = validators
        .map((validator) => {
          const result = validator(value);
          const hunnyRequest = result as HunnyRequest;

          if (hunnyRequest?.call && hunnyRequest?.cancel) {
            hasAsyncValidator = true;
            if (onValidating) onValidating();
            return execute(hunnyRequest);
          }

          return Promise.resolve(result as ValidationError);
        })
        .filter((validator) => validator);
      const validateErrors = await validateFormControl(parsedValidators);

      validatedValue.current = value;
      if (hasAsyncValidator && onValidated) {
        onValidated();
      }

      onErrorChanged(validateErrors);
      setErrors(validateErrors);
    }
  };

  useIsomorphicEffect(() => {
    if (!isMounted()) {
      return;
    }

    if (errors.length > 0 || validateOnchange) {
      debounceCallback(() => {
        validate();
      }, 50);
    }

    return () => {
      cancelAllRequest();
    };
  }, [value]);

  const handleOnBlur = async () => {
    validate();
  };

  const onChange = (e: any) => {
    if (type === 'checkbox') {
      onValueChanged(e.target.checked);
    } else {
      onValueChanged(e.target.value);
    }
  };

  const valueProps = type === 'checkbox' ? { checked: value } : { value };

  return <input {...props} onBlur={handleOnBlur} onChange={onChange} type={type} {...valueProps} />;
};

export default Input;
