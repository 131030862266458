import { BigNumber } from 'bignumber.js';

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_NINE = new BigNumber(9);
export const BIG_TEN = new BigNumber(10);

export const UINT256_MAX = new BigNumber(
  '115792089237316195423570985008687907853269984665640564039457584007913129639935',
);
