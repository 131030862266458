import { useWeb3React } from '@web3-react/core';

// INTERGRATE SOLANA
// import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { WalletType } from 'config/types/wallet';
import { useMemo } from 'react';

const useWalletAccount = () => {
  // const { publicKey } = useSolWallet()
  const { account } = useWeb3React();

  return useMemo(
    () => ({
      [WalletType.EVM]: account,
      [WalletType.BSC]: account,
      // [WalletType.SOL]: publicKey?.toString(),
    }),
    [account],
  );
};

export default useWalletAccount;
