import crypto from 'crypto';

export const hashPassword = (plainTextPassword: string) => {
  return crypto.createHash('sha512').update(plainTextPassword).digest('base64');
};

// Encrypt password from client side
export const encryptPassword = (hashPassword: string, key: string, iv: string) => {
  try {
    // Create cipher with key and iv
    const cipher = crypto.createCipheriv('aes-128-gcm', key, iv);

    // Encrypt the plain text password
    const encrypted = Buffer.concat([cipher.update(hashPassword), cipher.final()]);

    // Get authentication tag and concatenate the iv, encrypted message
    const authTag = cipher.getAuthTag();
    const encryptedData = Buffer.concat([encrypted, authTag]);
    return encryptedData.toString('base64');
  } catch (e: any) {
    return null;
  }
};

export const constructEncryptedPassword = (
  plaintextPwd: string,
  nonce: { key: string; value: string; token: string },
) => {
  const hashedPassword = hashPassword(plaintextPwd + nonce.value); // plaintext password + nonce

  const encryptedPassword = encryptPassword(hashedPassword, nonce.key, nonce.token); // hashed password, nonceId, token

  return encryptedPassword;
};
