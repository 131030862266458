import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import { BoxProps } from 'components/Box/types';
import Tabs from 'components/Tabs';
import Text from 'components/Text/Text';
import { Token } from 'config/types';
import { WageringConditionBonus } from 'config/types/bonus/userBonus';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Icons } from 'svgs';
import { colors } from 'theme/colors';
import WaitingDepositBonusItem from './WaitingDepositBonusItem';

const WaitingDepositBonusList: React.FC<
  BoxProps & {
    selectedToken: Token;
    disabled?: boolean;
    bonuses: WageringConditionBonus[];
    selectedBonus: WageringConditionBonus;
    handleSelectedBonus: (bonus: WageringConditionBonus) => Promise<boolean>;
    isSelectedBonusExpired: boolean;
  }
> = ({ selectedToken, selectedBonus, bonuses, isSelectedBonusExpired, disabled, handleSelectedBonus, ...props }) => {
  return (
    bonuses.length > 0 && (
      <Box {...props} width="100%">
        <Flex alignItems="center" justifyContent="space-between" mb="12px">
          <Text small bold>
            <Trans>Choose a bonus</Trans>
          </Text>
        </Flex>

        <StyledTabs
          value={selectedBonus}
          onTagChanged={handleSelectedBonus}
          indicator={<></>}
          backgroundBlurColor={colors.modalBackground}
        >
          {bonuses.map((bonus) => (
            <WaitingDepositBonusItem
              disabled={disabled}
              key={bonus.id}
              value={bonus}
              height="132px"
              maxWidth="114px"
              marginRight="8px"
              bonus={bonus}
              selectedToken={selectedToken}
              isSelected={bonus === selectedBonus}
            />
          ))}
        </StyledTabs>
        {isSelectedBonusExpired ? (
          <Flex mt="8px" alignItems="center">
            <Box minWidth="16px" mr="4px">
              <Icons.WarningIcon width="16px" fill={colors.error} />
            </Box>
            <Text color="error" fontSize="12px" lineHeight="14.5px">
              <Trans>Your bonus has been ended. Please choose another bonus</Trans>
            </Text>
          </Flex>
        ) : (
          <Flex mt="8px">
            <Text color="textSubtle" display="block" fontSize="12px" lineHeight="14.5px">
              <Box as="span" minWidth="16px" mr="4px">
                <Icons.WarningIcon
                  style={{
                    transform: 'translateY(2px)',
                  }}
                  width="16px"
                  fill={colors.textSubtle}
                />
              </Box>
              <Trans>
                Don't change the selected bonus until your deposit is credited to your game balance to activate it
              </Trans>
            </Text>
          </Flex>
        )}
      </Box>
    )
  );
};

const StyledTabs = styled(Tabs)`
  .tabs-right-blur {
    background: radial-gradient(
      586.71% 162.98% at 460.71% 50%,
      ${({ theme: { colors } }) => colors.modalBackground} 65.62%,
      rgba(22, 28, 36, 0) 79.69%
    );
    width: 40px;
  }

  .tabs-left-blur {
    background: radial-gradient(
      586.71% 162.98% at 460.71% 50%,
      ${({ theme: { colors } }) => colors.modalBackground} 65.62%,
      rgba(22, 28, 36, 0) 79.69%
    );
    width: 40px;
    transform: rotate(180deg) translateY(50%);
  }
`;

export default WaitingDepositBonusList;
