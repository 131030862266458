import copyToClipboard from 'copy-to-clipboard';
import { useState } from 'react';

const useCopy = () => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = (copyStr: string) => {
    const copiedString = copyToClipboard(copyStr);
    setCopied(copiedString);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return { copied, handleCopy };
};

export default useCopy;
