import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import Text from 'components/Text/Text';
import { WageringConditionBonus } from 'config/types/bonus/userBonus';
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Token } from 'config/types';
import { useTokenUsdPrice } from 'state/app/hooks';
import { getFullDisplayBalance } from 'utils/formatBalance';
import BigNumber from 'bignumber.js';

import { Icons } from 'svgs';
import Flex from 'components/Box/Flex';
import Tab, { TabProps } from 'components/Tab';
import { useDisplayBonusName } from 'state/bonus/hooks';
import WaitingDepositBonusCountdown from './WaitingDepositBonusCountdown';

const WaitingDepositBonusItem: React.FC<
  BoxProps & {
    bonus: WageringConditionBonus;
    selectedToken: Token;
    disabled?: boolean;
    isSelected?: boolean;
  } & TabProps
> = ({ bonus, selectedToken, isSelected, disabled, ...props }) => {
  const [name] = useDisplayBonusName(bonus);
  const tokenPrice = useTokenUsdPrice(selectedToken);

  const [isExpired, setIsExpired] = useState(false);

  const handleBonusExpired = useCallback(() => {
    setIsExpired(true);
  }, []);

  const isDisabled = bonus.condition?.isExpired || isExpired || disabled;

  return (
    <Tab disabled={isDisabled} {...props}>
      <StyleContainer $active={isSelected} $disabled={isDisabled} height="100%" width="114px">
        <StyledCheck width="20px" height="20px">
          <Icons.SuccessCheckIcon width="16px" fill="#fff" />
        </StyledCheck>

        {bonus.id ? (
          <>
            <Box>
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="14.5px"
                color={isDisabled && !isSelected ? 'textAlt1' : 'text'}
              >
                {bonus.info.name || name}
              </Text>

              <Text
                fontSize="10px"
                fontWeight="300"
                mt="8px"
                color={isDisabled && !isSelected ? 'textAlt1' : 'textSubtle'}
              >
                <Trans>Min Deposit</Trans>
              </Text>
              <Text
                fontSize="12px"
                fontWeight="500"
                mt="4px"
                lineHeight="15px"
                color={isDisabled && !isSelected ? 'textAlt1' : 'text'}
              >
                {getFullDisplayBalance(
                  new BigNumber(bonus.minDeposit).dividedBy(tokenPrice),
                  0,
                  tokenPrice.gt(10) ? 6 : 2,
                )}{' '}
                {selectedToken.code}
              </Text>
              <Text
                fontSize="10px"
                fontWeight="300"
                mt="4px"
                color={isDisabled && !isSelected ? 'textAlt1' : 'textSubtle'}
              >
                ~${bonus.minDeposit}
              </Text>
            </Box>
            <WaitingDepositBonusCountdown
              color={isDisabled && !isSelected ? 'textAlt1' : 'text'}
              expiryDate={bonus.condition.expiryDate}
              onBonusExpired={handleBonusExpired}
            />
          </>
        ) : (
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Text
              fontSize="12px"
              width="64px"
              textAlign="center"
              lineHeight="15px"
              color={isDisabled && !isSelected ? 'textAlt1' : 'text'}
            >
              <Trans>Without bonus</Trans>
            </Text>
          </Flex>
        )}
      </StyleContainer>
    </Tab>
  );
};

const StyleContainer = styled(Box)<{ $active: boolean; $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  background: linear-gradient(97.95deg, rgba(255, 255, 255, 0.04) -1.76%, rgba(255, 255, 255, 0) 131.09%),
    radial-gradient(at 39% 66%, #2af6ff40 -215%, transparent 70%);
  backdrop-filter: blur(6px);
  border-radius: 12px;
  padding: 10px;
  border: 1px solid ${({ theme: { colors } }) => colors.providerBackground};

  ${({ $active, theme }) =>
    $active
      ? css`
          border: 1px solid ${theme.colors.success};
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 65.91%),
            radial-gradient(at 39% 66%, #2af6ff87 -215%, transparent 70%) !important;
          ${StyledCheck} {
            display: flex;
          }
        `
      : ''};

  ${({ $disabled }) =>
    $disabled
      ? css`
          background: linear-gradient(132deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%),
            radial-gradient(at 39% 66%, #2af6ff20 -215%, transparent 70%);
        `
      : ''};
`;

const StyledCheck = styled(Box)`
  border-radius: 0px 10px 0px 8px;
  background: ${({ theme }) => theme.colors.success};
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
`;

export default WaitingDepositBonusItem;
