import { DepositedTokenInfo } from 'config/types';
import { BaseResponse, MetaDataResponse, TokenUsdPriceResponse } from 'services/types';
import { formatApiNetworkField } from 'utils';
import { ChainIdEnum } from 'config/constants/network';
import { parseNetworkToApiPoker } from 'utils/network';
import tokens from 'config/constants/tokens';

export const MetaDataMapper = (rawResponse: string): BaseResponse<MetaDataResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  const data = response?.data;

  if (!response) {
    return {
      code: 'error',
      data: null,
    };
  }

  const tokenUSD = {
    network: ChainIdEnum.HPO,
    order: 100,
    token: tokens[ChainIdEnum.HPO].USD.code,
    contractAddress: '',
  };

  const tokenUSDB = {
    network: ChainIdEnum.HPO,
    order: 100,
    token: tokens[ChainIdEnum.HPO].USDB.code,
    contractAddress: '',
  };

  const tokenInfoes: DepositedTokenInfo[] = [tokenUSD, tokenUSDB];
  const networkIds: string[] = ['HPO'];

  data?.wallets.forEach((wallet) => {
    const networkCode = parseNetworkToApiPoker(wallet.network);
    wallet.currencies.forEach((currency) => {
      tokenInfoes.push({
        network: ChainIdEnum[formatApiNetworkField(networkCode, false)],
        order: 1,
        token: currency,
        contractAddress: wallet?.address || '',
      });
    });
    networkIds.push(formatApiNetworkField(networkCode, false));
  });

  return {
    code: response.code,
    data: {
      serverTime: (data?.server_time || 0) * 1000,
      networkIds,
      tokenInfoes,
    },
  };
};

export const TokenInUsdPriceMapper = (rawResponse: string): TokenUsdPriceResponse => {
  const response = JSON.parse(rawResponse);
  return response;
};
