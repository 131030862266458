import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import hunnyKeyframes from 'theme/keyframes';

type OpenType = keyof typeof hunnyKeyframes;

const StyledContainer = styled(Box)<{ openType: OpenType; duration: number }>`
  animation: ${({ openType }) => hunnyKeyframes[openType]} ${({ duration }) => `${duration}s`};
`;

export const OpenEffect = forwardRef<HTMLDivElement, { openType?: OpenType; duration?: number } & BoxProps>(
  ({ children, openType = 'fade', duration = 0.3, ...props }, ref) => {
    return (
      <StyledContainer ref={ref} openType={openType} duration={duration} {...props}>
        {children}
      </StyledContainer>
    );
  },
);

export default OpenEffect;
