import Button from 'components/Button';
import { ModalProps } from 'components/Modal/Modal';
import Text from 'components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationLayoutModal from './ConfirmationLayoutModal';

export type ConfirmationProps = {
  onAccept: () => void;
  onCancel?: () => Promise<void> | void;
  acceptButtonContent?: string;
  cancelButtonContent?: string;
  content: string | React.ReactNode;
};
const ConfirmationModal: React.FC<ModalProps<ConfirmationProps>> = ({
  onDismiss,
  data: { onAccept, onCancel = () => {}, content, cancelButtonContent = 'Cancel', acceptButtonContent = 'Yes' },
}) => {
  const { t } = useTranslation();
  return (
    <ConfirmationLayoutModal onDismiss={onDismiss}>
      {typeof content === 'string' ? (
        <Text
          mt="8px"
          fontSize="12px"
          color="textSubtle"
          textAlign="center"
          maxWidth="320px"
          marginX="auto"
          lineHeight="14.5px"
        >
          {t(content)}
        </Text>
      ) : (
        <Text
          mt="8px"
          fontSize="12px"
          color="textSubtle"
          textAlign="center"
          maxWidth="320px"
          marginX="auto"
          lineHeight="14.5px"
        >
          {content}
        </Text>
      )}

      <Button
        maxWidth="250px"
        marginX="auto"
        width="100%"
        mt="40px"
        onClick={() => {
          onAccept();
          onDismiss();
        }}
      >
        <Text small bold>
          {t(acceptButtonContent)}
        </Text>
      </Button>

      <Button
        variant="text"
        maxWidth="250px"
        marginX="auto"
        width="100%"
        mt="4px"
        onClick={async () => {
          await onCancel();
          onDismiss();
        }}
      >
        <Text small color="textSubtle">
          {t(cancelButtonContent)}
        </Text>
      </Button>
    </ConfirmationLayoutModal>
  );
};

export default ConfirmationModal;
