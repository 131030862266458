import { AuthModalMethodEnums, AuthModalPageEnums } from 'config/types/authentication';
import { useCallback } from 'react';
import Authentication from 'views/Authentication';
import useModal from './useModal';

const useAuthenticationModal = () => {
  // const { previousLoginBy } = useSuggestedLogin();
  const [onPresentModal, onDismissModal] = useModal(Authentication);

  const handlePresentModal = useCallback(
    ({ page, method }: { page?: AuthModalPageEnums; method?: AuthModalMethodEnums } = {}) => {
      const loginMethod = method || AuthModalMethodEnums.WEB3;
      onPresentModal({ page, method: loginMethod });
    },
    [onPresentModal],
  );

  return [handlePresentModal, onDismissModal];
};

export default useAuthenticationModal;
