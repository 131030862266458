import BigNumber from 'bignumber.js';
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
import { BIG_ZERO } from 'config/constants/number';
import tokens from 'config/constants/tokens';
import { Network, Token } from 'config/types';
import { useMemo } from 'react';
import { useAppSelector } from 'state';
import { getNetworkInfo } from 'utils/network';

// INTERGRATE SOLANA
// export const useSolNetworkInfo = () => {
//   const networkIds = useAppSelector((state) => state.app.networkIds)
//   const solChain = networkIds?.find((chain) => chain === 'SOL_TESTNET' || chain === 'SOL')

//   return NETWORK_MAP[ChainIdEnum[solChain] || ChainIdEnum.SOL]
// }

export const useTokenUsdPrices = () => {
  const tokens = useAllTokens();
  const tokenUsdPrices = useAppSelector((state) => state.app.tokenUsdPrices);
  return useMemo(
    () =>
      tokenUsdPrices && tokens && tokens.length > 0
        ? tokens
            .filter((token) => token.coinGeckoInfo)
            .reduce((result, token) => {
              result[token.code] = tokenUsdPrices[token.coinGeckoInfo?.id]?.usd;
              return result;
            }, {})
        : {},
    [tokens, tokenUsdPrices],
  );
};

export const useTokenUsdPrice = (token: Token) => {
  const tokenUsdPrices = useAppSelector((state) => state.app.tokenUsdPrices);
  return new BigNumber(tokenUsdPrices?.[token.code]?.usd || BIG_ZERO);
};

export const useListNetworks = (filter: { excludeChains?: ChainIdEnum[]; selectChains?: ChainIdEnum[] }): Network[] => {
  const { excludeChains, selectChains } = filter;

  const networkIds = useAppSelector((state) => state.app.networkIds);

  return useMemo(() => {
    return networkIds
      ?.filter((network) => !selectChains || selectChains.includes(ChainIdEnum[network]))
      .filter((network) => !excludeChains || !excludeChains.includes(ChainIdEnum[network]))
      .map((network) => NETWORK_MAP[ChainIdEnum[network]]);
  }, [excludeChains, networkIds, selectChains]);
};

export const useAllTokens = (condition: (token: Token) => boolean = () => true, dependencies: any[] = []): Token[] => {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes);

  return useMemo(() => {
    return tokenInfoes
      ?.map((tokenInfo) => {
        const isExist = tokens[tokenInfo.network] && tokens[tokenInfo.network][tokenInfo.token];
        if (!isExist) {
          // logError(`token not exist at UI ${tokenInfo.token}-${tokenInfo.network} `, undefined);
          return null;
        }
        return isExist;
      })
      .filter((token) => token)
      .filter((token) => {
        return condition(token);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, tokenInfoes, ...dependencies]);
};

export const useListTokens = (networkId?: ChainIdEnum): Token[] => {
  const tokenInfoes = useAllTokens((token: Token) => !networkId || token.network === networkId, [networkId]);
  return tokenInfoes;
};

export const useNetwork = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const { network, native } = getNetworkInfo(chainId);

  return useMemo(() => {
    return { network, native };
  }, [network, native]);
};

export const useTokenSelected = (): Token => {
  const selectToken = useAppSelector((state) => state.app.selectToken);

  return useMemo(() => {
    return selectToken && tokens[selectToken.network] && tokens[selectToken.network][selectToken.token];
  }, [selectToken]);
};

export const useConnectedSystem = () => {
  const serverTime = useAppSelector((state) => state.app.serverTime);

  return !!serverTime;
};

export const useIsInGame = () => {
  const isInGame = useAppSelector((state) => state.app.isInGame);
  return useMemo(() => isInGame, [isInGame]);
};
