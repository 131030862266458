import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
import { logError } from './sentry';

export const setupNetwork = async (chainId: ChainIdEnum, library?: any) => {
  const network = NETWORK_MAP[chainId];
  if (!network) {
    return;
  }

  const chainName = network.networkInfo.displayName;
  const rpcUrls = network.rpcCollections;
  const blockExplorerUrls = [network.blockExplorerUrls];

  const provider = library?.provider || window.ethereum;

  if (provider) {
    if (chainId && provider) {
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        return true;
      } catch (switchError) {
        if ((switchError as any)?.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId?.toString(16) || 0}`,
                  chainName,
                  nativeCurrency: {
                    name: network.networkInfo.native.name,
                    symbol: network.networkInfo.native.name,
                    decimals: network.networkInfo.native.decimals,
                  },
                  rpcUrls,
                  blockExplorerUrls,
                },
              ],
            });
            return true;
          } catch (error) {
            logError('Failed to setup the network in Metamask:', error);
            return false;
          }
        }
        return false;
      }
    }
  } else {
    // eslint-disable-next-line quotes, @typescript-eslint/quotes
    console.error("Can't setup the network on metamask because window.ethereum is undefined");
    return false;
  }
};
