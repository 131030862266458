import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import CopySuccessLoader from 'components/Loader/CopySuccessLoader';
import useCopy from 'hooks/useCopy';
import { Icons } from 'svgs';
import theme from 'theme';
import React from 'react';
import IconButton from './IconButton';

interface CopyButtonProps extends BoxProps {
  text: string;
  icon?: React.ReactNode;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text, icon, ...props }) => {
  const { copied, handleCopy } = useCopy();

  return (
    <Box {...props}>
      <IconButton
        onClick={() => {
          handleCopy(text);
        }}
      >
        {!copied && (icon || <Icons.CopyIcon fill={theme.colors.textSubtle} />)}
        {copied && <CopySuccessLoader />}
      </IconButton>
    </Box>
  );
};

export default CopyButton;
