import axios, { AxiosRequestConfig } from 'axios';
import { APIEndpointEnum, BASE_API, MessageResponseServer } from 'config/constants/server';
import { TokenKeyEnum } from 'config/constants/auth';
import { BaseResponse } from './types';
import { LoginResponseMapper } from './mapper/Authenication';

export const hunnyAxios = axios.create({
  timeout: 30000,
});

hunnyAxios.interceptors.response.use((response) => {
  try {
    const originalRequest: any = response?.config;
    const data = JSON.parse(response?.request?.response || '{}');
    if (
      response.status === 401 &&
      data?.message?.toLowerCase() === MessageResponseServer.unauthorized &&
      !originalRequest?._retry
    ) {
      originalRequest._retry = true;
      const token = localStorage.getItem(TokenKeyEnum.RefreshToken);
      const user = JSON.parse(localStorage.getItem(TokenKeyEnum.UserInfo) || '{}');
      if (!token) return response;

      return axios({
        method: 'POST',
        url: `${BASE_API}api/v1/auth/refresh/`,
        data: {
          address: user.email,
          deviceId: user.deviceUid,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        transformResponse: LoginResponseMapper,
      })
        .then((result) => {
          const accessToken = result?.data?.data?.accessToken;

          if (accessToken) {
            window.localStorage.setItem(TokenKeyEnum.AccessToken, accessToken);
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axios(originalRequest);
          }
          window.location.reload();
          localStorage.removeItem(TokenKeyEnum.AccessToken);
          localStorage.removeItem(TokenKeyEnum.RefreshToken);
          localStorage.removeItem(TokenKeyEnum.UserInfo);
        })
        .catch((error) => {
          window.location.reload();
          localStorage.removeItem(TokenKeyEnum.AccessToken);
          localStorage.removeItem(TokenKeyEnum.RefreshToken);
          localStorage.removeItem(TokenKeyEnum.UserInfo);
          return error;
        });
    }
  } catch (e) {
    return response;
  }

  return response;
});

export abstract class BaseRequest {
  protected get AxiosCancelToken() {
    const _cancelToken = axios.CancelToken;
    return _cancelToken.source();
  }

  protected abstract _request(
    url: string,
    payload: any,
    config: any,
    mapperKey?: APIEndpointEnum,
  ): Promise<BaseResponse<any>>;

  protected abstract callRequest(url: string, config: AxiosRequestConfig, payload?: any): Promise<any>;

  protected abstract buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig;
}
