import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Dots from 'components/Loader/Dots';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import TokenLogo from 'components/TokenLogo';
import { NETWORK_MAP } from 'config/constants/network';
import configTokens from 'config/constants/tokens';
import { QueryDepositStatusEnums } from 'config/constants/transactions';
import { QueryDepositInfo, TransactionStatusEnum } from 'config/types/transaction';
import { Trans } from 'react-i18next';
import theme from 'theme';

interface InternalQueryDepositResultProps {
  info: QueryDepositInfo & { txnHash: string };
  isFetching: boolean;
}

const InternalQueryDepositResult: React.FC<InternalQueryDepositResultProps> = ({ info, isFetching }) => {
  const token = configTokens[info?.depositInfo.network]?.[info?.depositInfo.currency];

  return (
    <Box my={32}>
      {isFetching ? (
        <Flex alignItems="center" width="100%" justifyContent="center">
          <Text fontSize="14px">
            <Dots>
              <Trans>Finding your deposit</Trans>
            </Dots>
          </Text>
        </Flex>
      ) : null}
      {!isFetching && info && (
        <OpenEffect openType="grow">
          <Box py={20} px={15} background={theme.colors.backgroundAlt}>
            <Box>
              {info?.depositInfo.status === TransactionStatusEnum.Succeeded && (
                <Text fontSize="16" color="success" mb={2} bold>
                  <Trans>Deposit successful</Trans>
                </Text>
              )}
              {info?.depositInfo.status === TransactionStatusEnum.Pending && (
                <Text fontSize="16" color="info" mb={2} bold>
                  <Trans>Deposit Pending</Trans>
                </Text>
              )}
              {info?.depositInfo.status === TransactionStatusEnum.Failed && (
                <Text fontSize="16" color="error" mb={2} bold>
                  <Trans>Deposit Expired</Trans>
                </Text>
              )}
            </Box>
            {info?.status === QueryDepositStatusEnums.Success && (
              <>
                {' '}
                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                  <Text fontSize="14px">
                    <Trans>Coin</Trans>/<Trans>Token</Trans>
                  </Text>
                  <Text fontSize="14px" bold>
                    {token?.name}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center" my={2}>
                  <Text fontSize="14px">
                    <Trans>Amount</Trans>
                  </Text>
                  <Flex alignItems="center">
                    <Text fontSize="14px" mr={2} bold>
                      {info?.depositInfo.value}
                    </Text>
                    <TokenLogo token={token} size={25} />
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="14px" textTransform="capitalize">
                    <Trans>network</Trans>
                  </Text>
                  <Text fontSize="14px" mr={2} bold>
                    {NETWORK_MAP[token?.network]?.networkInfo?.displayName}
                  </Text>
                </Flex>
              </>
            )}
            {[
              QueryDepositStatusEnums.InvalidData,
              QueryDepositStatusEnums.InvalidMinimumFee,
              QueryDepositStatusEnums.ScanFailed,
            ].includes(info?.status) && (
              <Box maxWidth={415}>
                <Text fontSize="14px" color="textSubtle">
                  {info.status === QueryDepositStatusEnums.InvalidData && (
                    <Trans>
                      Sorry! This is an invalid TxID/TxHash. Please double check on blockchain explorer. If your deposit
                      is confirmed in the blockchain, you can contact our Support with your deposit info so we can help.
                    </Trans>
                  )}
                  {info.status === QueryDepositStatusEnums.ScanFailed && (
                    <Trans>Sorry! Something went wrong. Please try again later.</Trans>
                  )}
                  {info.status === QueryDepositStatusEnums.InvalidMinimumFee && (
                    <Trans>The deposit amount is less than the minimum amount requirement to be credited.</Trans>
                  )}
                </Text>
              </Box>
            )}
          </Box>
        </OpenEffect>
      )}
    </Box>
  );
};

export default InternalQueryDepositResult;
