import Box from 'components/Box/Box';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import { Trans } from 'react-i18next';
import theme from 'theme';

const ExternalQueryInstruction = () => {
  return (
    <OpenEffect openType="grow">
      <Box
        background="rgba(255, 255, 255, 0.04)"
        borderRadius={theme.radii.small}
        py={20}
        px={12}
        mt={20}
        maxWidth={445}
      >
        <Text fontSize="14px" color="textSubtle" lineHeight={1.5}>
          <Trans>
            If your transaction status is pending/failed on the other platform, please contact the other platform for
            more information. We are unable to retrieve assets that have not reached our platform.
          </Trans>
        </Text>
      </Box>
    </OpenEffect>
  );
};

export default ExternalQueryInstruction;
