import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import { Checkbox } from 'components/Checkbox';
import Text from 'components/Text';
import useQueryParam from 'hooks/useQueryParam';
import { useReferralCode } from 'hooks/useReferralCode';
import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const ReferralCode: React.FC<BoxProps> = (props) => {
  const refCode = useReferralCode();
  const catchedRefCode = useRef(refCode);
  const { removeParam, addParam } = useQueryParam();

  const handleToggleApplyReferral = () => {
    if (refCode.refCode) {
      removeParam('join');
    } else {
      addParam('join', catchedRefCode.current.refCode);
    }
  };

  return (
    catchedRefCode.current?.refCode && (
      <StyledReferralContainer onClick={handleToggleApplyReferral} {...props}>
        <Checkbox width="18px" height="18px" mr="8px" value={!!refCode.refCode} onValueChanged={() => {}} />
        <Text color="textTertiary" fontSize="12px">
          <Trans>Applied referral code {{ referralCode: catchedRefCode.current.refCode }}</Trans>
        </Text>
      </StyledReferralContainer>
    )
  );
};

export const StyledReferralContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
export default ReferralCode;
