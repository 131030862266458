import Box from 'components/Box/Box';
import Button from 'components/Button';
import { ButtonProps } from 'components/Button/types';
import CircleLoader from 'components/Loader/CircleLoader';
import Text from 'components/Text';
import { ChainIdEnum } from 'config/constants/network';
import { Token } from 'config/types';
import { useAnalytics } from 'hooks/useAnalytics';
import useModal from 'hooks/useModal';
import { useRequest } from 'hooks/useRequest';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import PaymentService from 'services/PaymentService';
import { useAppSelector } from 'state';
import { transformHunnyRequest } from 'utils/requestHelper';
import EmailVerificationModal from 'views/Authentication/components/Traditional/modals/EmailVerificationModal';

type TraditionalWithdrawButtonProps = {
  selectedToken: Token;
  fee: string;
  value: string;
  toAddress: string;
  validateAll: () => Promise<boolean>;
  onSuccess: (txnId: string) => void;
};

const TraditionalWithdrawButton: React.FC<TraditionalWithdrawButtonProps & ButtonProps> = ({
  selectedToken,
  validateAll,
  toAddress,
  fee,
  value,
  onSuccess,
  disabled,
  ...props
}) => {
  const { email } = useAppSelector((state) => state.auth);
  const { execute } = useRequest();

  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal);
  const { recordEvent } = useAnalytics();
  const [submiting, setSubmiting] = useState<boolean>(false);

  const handleWithdraw = async () => {
    setSubmiting(true);
    const isValid = await validateAll();
    if (!isValid) {
      setSubmiting(false);
      return;
    }

    const parsedWithdrawPayload = {
      currency: selectedToken,
      toAddress,
      amount: value,
    };

    const verification = await execute(PaymentService.prepareTraditionalWithdraw(parsedWithdrawPayload));
    if (!verification?.data) return setSubmiting(false);
    handlePresentEmailVerificationModal({
      email,
      initialVerifiedResult: verification.data,
      verifiedHunnyRequestFn: () =>
        transformHunnyRequest(PaymentService.prepareTraditionalWithdraw(parsedWithdrawPayload), (res) => res?.data),
      async onSubmit(verification, otp) {
        const withdrawResponse = await PaymentService.traditionalWithdraw({
          ...parsedWithdrawPayload,
          otp,
          verificationCode: verification.token,
        });

        if (withdrawResponse?.data) {
          recordEvent('withdraw', {
            [ChainIdEnum[selectedToken.network]]: selectedToken.name,
          });
          onSuccess(withdrawResponse?.data?.ref.toString());
        }

        return withdrawResponse;
      },
    });

    setSubmiting(false);
  };

  return (
    <Button disabled={submiting || disabled} onClick={handleWithdraw} {...props}>
      {submiting && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
        <Trans>Withdraw</Trans>
      </Text>
    </Button>
  );
};

export default TraditionalWithdrawButton;
