import { useRouter } from 'hooks/useRouter';
import { useMemo } from 'react';

export const useReferralCode = (): { refCode: string } => {
  const router = useRouter();
  const hunnypokerRefCode = router.query?.join?.toString();

  return useMemo(
    () => ({
      refCode: hunnypokerRefCode,
    }),
    [hunnypokerRefCode],
  );
};
