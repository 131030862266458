import Modal, { ModalBody, ModalHeader } from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal';
import Tab from 'components/Tab';
import Tabs, { StyledIndicator } from 'components/Tabs';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icons } from 'svgs';
import theme from 'theme';
import { colors } from 'theme/colors';

import Column from 'layout/Components/Column';
import TabLabel from './components/TabLabel';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';

export enum PaymentPageEnums {
  Deposit,
  Withdraw,
}

interface PaymentProps {
  defaultPage?: PaymentPageEnums;
}

const Payment: React.FC<ModalProps<PaymentProps>> = ({ data: { defaultPage }, onDismiss }) => {
  const [paymentPage, setPaymentPage] = useState<PaymentPageEnums>(defaultPage || PaymentPageEnums.Deposit);
  const { t } = useTranslation();

  return (
    <StyledModal id="payment" onDismiss={onDismiss}>
      <StyledModalHeader>
        <StyledTabs
          indicator={<StyledIndicator width="50% !important" borderRadius="20px" />}
          value={paymentPage}
          onTagChanged={(value) => {
            setPaymentPage(value);
          }}
        >
          <StyledTab value={PaymentPageEnums.Deposit} $active={paymentPage === PaymentPageEnums.Deposit}>
            <TabLabel Icon={<Icons.DepositIcon fill={colors.text} />} label={t('Deposit')} />
          </StyledTab>
          <StyledTab value={PaymentPageEnums.Withdraw} $active={paymentPage === PaymentPageEnums.Withdraw}>
            <TabLabel Icon={<Icons.WithdrawIcon fill={colors.text} />} label={t('Withdraw')} />
          </StyledTab>
        </StyledTabs>
      </StyledModalHeader>

      <ModalBody
        height="100%"
        overflowY="auto"
        borderTopLeftRadius={theme.radii.default}
        borderTopRightRadius={theme.radii.default}
      >
        <StyledContent>
          {paymentPage === PaymentPageEnums.Deposit && <Deposit />}
          {paymentPage === PaymentPageEnums.Withdraw && <Withdraw />}
        </StyledContent>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};
  min-height: 85vh;

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  & .modal-closebutton {
    right: 12px;
    top: 24px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 420px;
    max-width: 420px;
    min-height: 576px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;

const StyledTabs = styled(Tabs)`
  width: calc(100% - 48px);
  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    width: 100%;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  align-items: flex-end;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    padding-left: 6px;
  }
`;

const StyledTab = styled(Tab)<{ $active: boolean }>`
  padding: 12px;

  svg {
    min-width: 20px;
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.primary} !important` : theme.colors.textSubtle)};
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.primary} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
  }
`;

const StyledContent = styled(Column)`
  flex: 1;

  width: 100%;
  height: 100%;

  padding: 20px 20px;

  border-top-left-radius: ${({ theme: { radii } }) => radii.small};
  border-top-right-radius: ${({ theme: { radii } }) => radii.small};

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    padding: 12px 32px;
  }
`;

export default Payment;
