import Modal from 'components/Modal/Modal';
import React from 'react';
import styled from 'styled-components';
import Heading from 'components/Heading';
import ModalBody from 'components/Modal/components/ModalBody';
import { ModalHeader } from 'components/Modal';
import theme from 'theme';
import IconButton from 'components/Button/IconButton';
import { Icons } from 'svgs';
import { BoxProps } from 'components/Box/types';
import { Trans } from 'react-i18next';

const ForgotPasswordModal: React.FC<{ onDismiss: () => void; label: string } & BoxProps> = ({
  onDismiss,
  label,
  children,
  ...props
}) => {
  return (
    <StyledModal onDismiss={onDismiss} {...props}>
      <ModalHeader>
        <IconButton ml="24px" onClick={onDismiss}>
          <Icons.BackIcon width="24px" height="24px" />
        </IconButton>
        <Heading scale="xs" ml="12px">
          <Trans>{label}</Trans>
        </Heading>
      </ModalHeader>
      <ModalBody borderRadius={theme.radii.modal} height="100%" p={['24px', '24px', '40px']}>
        {children}
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`;

export default ForgotPasswordModal;
