import useModal from 'hooks/useModal';
import { useMemo } from 'react';
import QueryDeposit from '.';

export const useQueryDepositModal = () => {
  const [onPresent] = useModal(QueryDeposit);

  return useMemo(
    () => ({
      onPresentQueryDeposit: onPresent,
    }),
    [onPresent],
  );
};
  