import { TokenKeyEnum } from 'config/constants/auth';
import { APIEndpointEnum, MessageResponseServer } from 'config/constants/server';
import { HunnyPokerRequest } from './HunnyPokerRequest';
import {
  BaseResponse,
  DeepLinkResponse,
  HunnyRequest,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessagePayload,
  PrepareSignMessageResponse,
  RegisteredEmailPayload,
  SignInByEmailPayload,
  SignInByWalletPayload,
  TraditionalSignUpPreparePayload,
  Verification,
  VerificationResponse,
} from './types';

class AuthenticationService extends HunnyPokerRequest {
  public async getDeepLinkToken(): Promise<BaseResponse<DeepLinkResponse>> {
    return this._request(APIEndpointEnum.DeepLink, {});
  }

  public getTraditionalSignUpPrepare(
    payload: TraditionalSignUpPreparePayload,
  ): HunnyRequest<BaseResponse<PasswordNonceResponse>> {
    const result = this._post(
      APIEndpointEnum.SignUpPrepare,
      {
        email: payload.email,
      },
      { excludeErrors: [MessageResponseServer.emailExist] },
    );

    return result;
  }

  public verifyRegisterEmail(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyRegisterUser, {
      email,
    });
  }

  public verifyResetPassword(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyResetPassword, {
      email,
    });
  }

  public async registerEmail(payload: RegisteredEmailPayload): Promise<BaseResponse<LoginResponse>> {
    const result: BaseResponse<LoginResponse> = await this._request(
      APIEndpointEnum.Register,
      {
        email: payload.email,
        password: payload.password,
        verification: {
          token: payload.verification.token,
          otp: payload.verification.otp,
        },
        referralCode: payload.referralCode || '',
        deviceId: payload.deviceUid,
      },
      {
        excludeErrors: ['error_auth_input', 'error_data_expired'],
      },
    );
    if (result?.data?.accessToken) {
      window.localStorage.setItem(TokenKeyEnum.AccessToken, result?.data?.accessToken);
      window.localStorage.setItem(TokenKeyEnum.RefreshToken, result?.data?.refreshToken);
      window.localStorage.setItem(
        TokenKeyEnum.UserInfo,
        JSON.stringify({
          email: payload.email,
          deviceUid: payload.deviceUid,
        }),
      );
    }

    return result;
  }

  public async validateResetPassword(
    email: string,
    verification: Verification,
  ): Promise<BaseResponse<PasswordNonceResponse>> {
    const result = await this._request(
      APIEndpointEnum.ValidateResetPassword,
      {
        email,
        otp: verification.otp,
        token: verification.token,
      },
      { excludeErrors: ['error_auth_input', 'error_data_expired'] },
    );
    return result;
  }

  public async submitResetPassword(payload: {
    newPassword: string;
    nonce: PasswordNonceResponse;
    email: string;
  }): Promise<BaseResponse<any>> {
    const result = await this._request(APIEndpointEnum.SubmitResetPassword, {
      email: payload.email,
      newPassword: payload.newPassword,
      resetToken: payload.nonce.resetToken,
    });
    return result;
  }

  public async refresh(email: string, deviceId: string): Promise<BaseResponse<LoginResponse>> {
    const result = await this._request(APIEndpointEnum.Refresh, {
      address: email,
      deviceId,
    });

    if (result?.data?.accessToken) {
      window.localStorage.setItem(TokenKeyEnum.AccessToken, result?.data?.accessToken);
      window.localStorage.setItem(
        TokenKeyEnum.UserInfo,
        JSON.stringify({
          email,
          deviceUid: deviceId,
        }),
      );
    } else {
      localStorage.removeItem(TokenKeyEnum.AccessToken);
      localStorage.removeItem(TokenKeyEnum.RefreshToken);
    }

    return result;
  }

  public getMessageToWalletSign(
    payload: PrepareSignMessagePayload,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const parsedPayload = {
      address: payload.address,
      // chain_code: 'mainet',
      // chain_type: payload.walletType === WalletType.SOL ? 'solana' : 'evm', // INTERGRATE SOLANA
      // chain_type: 'evm',
    };
    const result = this._post(APIEndpointEnum.WalletLoginPrepare, parsedPayload);
    return result;
  }

  public async signInByWallet(payload: SignInByWalletPayload): Promise<BaseResponse<LoginResponse>> {
    const result = await this._request(APIEndpointEnum.SignInByWallet, {
      deviceId: payload.deviceUid,
      address: payload.address,
      signature: payload.messageSignature,
      referralCode: payload.referralCode || '',
    });

    if (result?.data?.accessToken) {
      window.localStorage.setItem(TokenKeyEnum.AccessToken, result?.data?.accessToken);
      window.localStorage.setItem(TokenKeyEnum.RefreshToken, result?.data?.refreshToken);
      window.localStorage.setItem(
        TokenKeyEnum.UserInfo,
        JSON.stringify({
          email: payload.address,
          deviceUid: payload.deviceUid,
        }),
      );
    }

    return result;
  }

  public getTraditionalSignInPrepare(email: string): HunnyRequest<BaseResponse<PasswordNonceResponse>> {
    return this._post(APIEndpointEnum.PrepareTraditionalLogin, { email });
  }

  public async signInByEmail(payload: SignInByEmailPayload): Promise<BaseResponse<LoginResponse>> {
    const result: BaseResponse<LoginResponse> = await this._request(APIEndpointEnum.SignInByEmail, {
      deviceId: payload.deviceUid,
      email: payload.email,
      password: payload.password,
    });

    if (result?.data?.accessToken) {
      window.localStorage.setItem(TokenKeyEnum.AccessToken, result?.data?.accessToken);
      window.localStorage.setItem(TokenKeyEnum.RefreshToken, result?.data?.refreshToken);
      window.localStorage.setItem(
        TokenKeyEnum.UserInfo,
        JSON.stringify({
          email: payload.email,
          deviceUid: payload.deviceUid,
        }),
      );
    }

    return result;
  }

  public async logout(deviceId: string): Promise<BaseResponse<null>> {
    const result = await this._request(APIEndpointEnum.Logout, {
      deviceId,
    });
    localStorage.removeItem(TokenKeyEnum.AccessToken);
    localStorage.removeItem(TokenKeyEnum.RefreshToken);
    localStorage.removeItem(TokenKeyEnum.UserInfo);
    return result;
  }
}

export default new AuthenticationService();
