import Box from 'components/Box/Box';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAuth } from 'state/auth/hooks';
import styled from 'styled-components';
import hunnyKeyframes from 'theme/keyframes';
import useAuthenticationModal from 'hooks/useAuthenticationModal';
import { AuthModalPageEnums } from 'config/types/authentication';
import Modal, { ModalProps } from 'components/Modal/Modal';
import { ModalBody, ModalHeader } from 'components/Modal';
import IconButton from 'components/Button/IconButton';
import ExternalQueryInstruction from './components/ExternalQueryInstruction';
import InternalQueryDeposit from './components/InternalQueryDeposit';

enum QueryViewEnums {
  Failed,
  Completed,
}

const QueryDeposit: React.FC<ModalProps> = ({ onDismiss }) => {
  const [view, setView] = useState<QueryViewEnums>(null);

  const { isSigned } = useAuth();

  useEffect(() => {
    if (!isSigned) setView(null);
  }, [isSigned]);

  const [onPresentLoginModal] = useAuthenticationModal();

  const handleSelectQueryDeposit = (view: QueryViewEnums) => {
    if (!isSigned) return onPresentLoginModal({ page: AuthModalPageEnums.LOG_IN });
    setView(view);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalHeader p={['12px', '', '24px']}>
        <Heading>Query Deposit</Heading>
      </ModalHeader>
      <ModalBody overflowY="scroll" maxHeight="90vh" minHeight="300px" p={['12px', '', '24px']}>
        <Box mt="12px">
          <Heading>
            <Trans>Check the Deposit that Hasn't Arrived</Trans>
          </Heading>
          <Text fontSize="14px" mt={22} mb={27}>
            <Trans>What is the transaction status on explorer or withdrawal platform?</Trans>
          </Text>

          <Box>
            <IconButton mb="12px" onClick={() => handleSelectQueryDeposit(QueryViewEnums.Completed)}>
              <StyledRadioButton
                $active={view === QueryViewEnums.Completed}
                onClick={() => handleSelectQueryDeposit(QueryViewEnums.Completed)}
              />
              <Box position="relative" ml="8px">
                <Text fontSize="14px" bold={view === QueryViewEnums.Completed}>
                  <Trans>Success</Trans> / <Trans>Completed</Trans>
                </Text>
              </Box>
            </IconButton>

            <IconButton onClick={() => handleSelectQueryDeposit(QueryViewEnums.Failed)}>
              <StyledRadioButton
                $active={view === QueryViewEnums.Failed}
                onClick={() => handleSelectQueryDeposit(QueryViewEnums.Failed)}
              />
              <Box position="relative" ml="8px">
                <Text fontSize="14px" bold={view === QueryViewEnums.Failed}>
                  <Trans>Pending</Trans> / <Trans>Failed</Trans>
                </Text>
              </Box>
            </IconButton>
          </Box>

          {isSigned && (
            <Box maxWidth={512}>
              {view === QueryViewEnums.Completed && <InternalQueryDeposit />}
              {view === QueryViewEnums.Failed && <ExternalQueryInstruction />}
            </Box>
          )}
        </Box>
      </ModalBody>
    </Modal>
  );
};

const StyledRadioButton = styled(Box)<{ $active: boolean }>`
  width: 24px;
  height: 24px;
  position: relative;

  cursor: pointer;

  border-radius: 50%;
  border: ${({ theme: { colors }, $active }) => `2px solid ${$active ? colors.primary : colors.text}`};

  transition: ${({ theme: { transitions } }) => transitions.fast};
  animation: ${({ $active }) => ($active ? hunnyKeyframes.ripple : null)} 1s linear;

  &:hover {
    transform: scale(1.1);
  }

  &:before {
    content: '';
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 75%;
    height: 75%;

    border-radius: 50%;
    background: ${({ $active, theme: { colors } }) => ($active ? colors.primary : 'transparent')};
  }
`;

export default QueryDeposit;
