import { useEffect, useMemo, useState } from 'react';

const useDetectKeyboardOpen = (minKeyboardHeight = 300, defaultValue = false) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

  useEffect(() => {
    const listener = () => {
      if (!window) return;

      const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height;
      if (isKeyboardOpen !== newState) {
        setIsKeyboardOpen(newState);
      } else {
        setIsKeyboardOpen(false);
      }
    };

    window.visualViewport.addEventListener('resize', listener, {
      passive: true,
    });
    return () => {
      window.visualViewport.removeEventListener('resize', listener);
    };
  }, []);

  return useMemo(() => isKeyboardOpen, [isKeyboardOpen]);
};

export default useDetectKeyboardOpen;
