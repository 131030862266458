import Box from 'components/Box/Box';
import Grid from 'components/Box/Grid';
import { WALLET_INFO } from 'config/constants/wallet';
import useModal from 'hooks/useModal';
import useWalletAccount from 'hooks/useWalletAccount';
import Column from 'layout/Components/Column';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import hunnyKeyframes from 'theme/keyframes';
import { AuthModalPageEnums } from 'config/types/authentication';
import CheckCondition from '../Traditional/CheckCondition';
import WalletCard from '../WalletCard';
import SignIn2FA from './SignWallet';
import { ConditionAcccess } from '../Template';

const SignWithWallet: React.FC<{
  isShowMore: boolean;
  setShowMore: (more: boolean) => void;
  page: AuthModalPageEnums;
}> = ({ isShowMore, page }) => {
  const displayWallets = isShowMore ? WALLET_INFO : WALLET_INFO.slice(0, 10);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const accounts = useWalletAccount();
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [handlePresent] = useModal(SignIn2FA);

  useEffect(() => {
    if (!selectedWallet || !accounts[selectedWallet?.type]) {
      return;
    }
    setSelectedWallet(null);
    handlePresent({ wallet: selectedWallet });
  }, [accounts, handlePresent, selectedWallet]);

  return (
    <Wrapper $more={isShowMore}>
      <WrapListWallet confirmed={isConfirmed}>
        <WrapGridWallet $more={isShowMore}>
          {displayWallets.map((wallet) => (
            <WalletCard
              id="wallet-select"
              key={wallet.name}
              wallet={wallet}
              onSelect={isConfirmed ? setSelectedWallet : () => {}}
            />
          ))}
        </WrapGridWallet>
      </WrapListWallet>

      {page === AuthModalPageEnums.SIGN_UP ? (
        <Box mt="24px" height="50px">
          <CheckCondition value={Boolean(isConfirmed)} onValueChanged={setIsConfirmed} />
        </Box>
      ) : (
        <ConditionAcccess />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Column)<{ $more: boolean }>`
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const WrapListWallet = styled(Column)<{
  confirmed?: boolean;
}>`
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  padding-bottom: 10px;
  position: relative;

  ${({ confirmed }) =>
    !confirmed &&
    css`
      opacity: 0.4;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        width: 100%;
        height: 100%;
      }
    `};
`;

const WrapGridWallet = styled(Grid).attrs({ gridTemplateColumns: ['1fr 1fr'], gridGap: '10px' })<{
  $more: boolean;
}>`
  animation: ${({ $more }) => ($more ? hunnyKeyframes.slideTopToBottom : hunnyKeyframes.slideBottomToTop)} 0.5s ease-out
    forwards;
  margin-right: -12px;
  padding-right: 12px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px !important;
  }
`;

export default SignWithWallet;
