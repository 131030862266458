import Flex from 'components/Box/Flex';
import styled from 'styled-components';
import Text from 'components/Text';
import Image from 'components/Image';
import BackgroundImage from 'components/Image/BackgroundImage';
import { Trans } from 'react-i18next';
import React from 'react';
import Box from 'components/Box/Box';

export interface IntroductionProps {
  title: string;
}
export const Introduction: React.FC<IntroductionProps> = ({ title }) => {
  return (
    <Container src="/images/auth-background.png">
      <Box mt="20px">
        <Image width={140} height={20} alt="hunny-logo" src="/logo-text.png" />
      </Box>
      <Content>
        <Text mb="8px" fontSize={['16px']} textAlign="center" fontWeight="bold" lineHeight="20px">
          {title}
        </Text>

        <Text
          width="70%"
          fontFamily="Montserrat"
          fontSize={['12px']}
          fontWeight={400}
          lineHeight="16px"
          textAlign="center"
        >
          <Trans>The Most Engaging and Fun Poker Game on Chain!</Trans>
        </Text>
      </Content>
    </Container>
  );
};

const Container = styled(BackgroundImage)`
  display: none;
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  border-top-left-radius: ${({ theme }) => theme.radii.extraLarge};
  border-bottom-left-radius: ${({ theme }) => theme.radii.extraLarge};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  height: 100%;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    display: flex;
  }
`;

const Content = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  line-height: 18px;
  z-index: 1;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    padding: 20px 20px;
  }
`;

export default Introduction;
