import BigNumber from 'bignumber.js';
import Flex from 'components/Box/Flex';
import Image from 'components/Image';
import CircleLoader from 'components/Loader/CircleLoader';
import Text from 'components/Text';
import { BIG_ZERO } from 'config/constants/number';
import { Token } from 'config/types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFullDisplayBalance } from 'utils/formatBalance';

export type TokenSelectItemProps = {
  token: Token;
  amountRequest?: Promise<BigNumber>;
};

const TokenSelectItem: React.FC<{ item: TokenSelectItemProps }> = ({ item: { token, amountRequest } }) => {
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const amount = await amountRequest;
      setAmount(amount);
    };

    fetch();
  }, [amountRequest]);

  return (
    <StyledActiveToken id="token-select-item">
      <Flex alignItems="center">
        <Image src={token.logo} width={24} height={24} />
        <Text fontSize="14px" fontWeight="bold" color="textSubtle" ml="10px">
          {token.name}
        </Text>
      </Flex>

      {amountRequest && amount !== BIG_ZERO && (
        <Text fontSize="14px" fontWeight="bold" color="textSubtle">
          {amount ? <>{getFullDisplayBalance(amount)}</> : <CircleLoader />}
        </Text>
      )}
    </StyledActiveToken>
  );
};

const StyledActiveToken = styled(Flex)`
  width: 100%;

  justify-content: space-between;
  align-items: center;
`;

export default TokenSelectItem;
