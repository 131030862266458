import { useCallback } from 'react';
import { RouteConfig } from 'config/constants/route';
import { useRouter } from 'next/router';
import { useAuth, useAuthRefresh } from 'state/auth/hooks';
import { ActionQueryEnum } from './useActionQueryListener';

export const usePlayGame = () => {
  const { isSigned } = useAuth();
  const router = useRouter();

  const handleRefreshToken = useAuthRefresh();

  const handlePlayGame = useCallback(async () => {
    if (isSigned) {
      const refreshSuccess = await handleRefreshToken();
      if (refreshSuccess) {
        router.push(RouteConfig.Play);
        return;
      }
    }

    router.push({
      pathname: router.pathname,
      query: {
        action: ActionQueryEnum.Login,
      },
    });
  }, [handleRefreshToken, isSigned, router]);

  return handlePlayGame;
};
