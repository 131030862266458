import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import CopyButton from 'components/Button/CopyButton';
import { InputContainer } from 'components/Input/styled';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { Token } from 'config/types';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { useRequest } from 'hooks/useRequest';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import PaymentService from 'services/PaymentService';
import styled from 'styled-components';
import theme from 'theme';

interface DepositByQRCodeProps {
  depositToken: Token;
}

const DepositByQRCode: React.FC<DepositByQRCodeProps> = ({ depositToken }) => {
  const [depositAddress, setDepositAddress] = useState<string>('');
  const [minDepositAmount, setMinDepositAmount] = useState<string>('');
  const [isFetchingDepositInfo, setIsFetchingDepositInfo] = useState<boolean>(false);
  const debounce = useDebounceCallback();

  const { execute } = useRequest();

  useEffect(() => {
    debounce(() => PaymentService.subscribeDeposit(depositToken.network, depositToken), 5000);
  }, [debounce, depositToken]);

  useEffect(() => {
    if (depositToken) {
      const fetchDepositAddress = async () => {
        setIsFetchingDepositInfo(true);

        const response = await execute(
          PaymentService.traditionalDeposit({
            currency: depositToken,
          }),
        );

        if (response) {
          setDepositAddress(response.data?.address);
          setMinDepositAmount(response.data?.minAmount);
        }
        setIsFetchingDepositInfo(false);
      };

      fetchDepositAddress();
    }
  }, [depositToken, execute]);

  return (
    <Box>
      <InputContainer p="15px" my="20px">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <StyledDepositAddress fontSize={['12px', '12px', '14px']}>{depositAddress}</StyledDepositAddress>
          <CopyButton text={depositAddress} />
        </Flex>
      </InputContainer>

      <Box m="20px auto" p="20px" background={theme.colors.text} width="fit-content" borderRadius={theme.radii.small}>
        {!isFetchingDepositInfo && <StyledQRCode value={depositAddress} renderAs="svg" />}
        {isFetchingDepositInfo && <StyledQRCodeLoader />}
      </Box>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        background="rgba(255, 255, 255, 0.04)"
        borderRadius={theme.radii.small}
        height="48px"
        padding="12px"
      >
        <Text fontSize={['12px', '12px', '14px']} color="textSubtle" textTransform="capitalize">
          <Trans>minimum deposit</Trans>
        </Text>
        <Box>
          <Text fontSize={['12px', '12px', '14px']} as="span">
            {minDepositAmount}
          </Text>
          <Text fontSize={['12px', '12px', '14px']} as="span" ml="5px">
            {depositToken?.code}
          </Text>
        </Box>
      </Flex>

      <Box width="100%" mt="30px">
        <Text fontSize={['12px', '12px', '14px']} textAlign="center" fontStyle="italic">
          *<Trans>Send only {{ tokenName: depositToken?.name }} to this deposit address</Trans>
        </Text>
      </Box>
    </Box>
  );
};

const StyledDepositAddress = styled(Text)`
  max-width: 90%;
  word-break: break-word;
`;
const StyledQRCodeLoader = styled(Skeleton)`
  width: 180px;
  height: 180px;

  padding: 20px;
`;

const StyledQRCode = styled(QRCode)`
  width: 180px !important;
  height: 180px !important;
`;

export default DepositByQRCode;
