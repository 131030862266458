import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state';
import { removeEmail } from 'state/auth/action';
import { AuthModalPageEnums } from 'config/types/authentication';
import TraditionalLoginForm from './TraditionalLoginForm';
import TraditionalSignUpForm from './TraditionalSignUpForm';

type TraditionalAuthProps = {
  page: AuthModalPageEnums;
  hide?: boolean;
  setPage: (page: AuthModalPageEnums) => void;
  onClose: () => void;
};

const TraditionalAuth: React.FC<TraditionalAuthProps & BoxProps> = ({ page, setPage, hide, onClose, ...props }) => {
  const dispatch = useAppDispatch();
  const previousUserEmail = useAppSelector((state) => state.auth.email);
  const [draftEmail, setDraftEmail] = useState(previousUserEmail);

  useIsomorphicEffect(() => {
    if (previousUserEmail) {
      dispatch(removeEmail());
    }
  }, []);

  return (
    !hide && (
      <Box {...props}>
        {page === AuthModalPageEnums.SIGN_UP && (
          <TraditionalSignUpForm
            onClose={onClose}
            saveEmail={setDraftEmail}
            initialEmail={draftEmail}
            setPage={setPage}
          />
        )}
        {page === AuthModalPageEnums.LOG_IN && (
          <TraditionalLoginForm saveEmail={setDraftEmail} initialEmail={draftEmail} setPage={setPage} />
        )}
      </Box>
    )
  );
};

export default TraditionalAuth;
