import Flex from 'components/Box/Flex';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { FlexProps } from 'components/Box/types';
import Tabs from 'components/Tabs';
import Box from 'components/Box/Box';
import Tab from 'components/Tab';
import Text from 'components/Text';
import React from 'react';
import { AuthModalMethodEnums } from 'config/types/authentication';

const SwitchMethod: React.FC<
  { onMethodChange: (method: AuthModalMethodEnums) => void; method: AuthModalMethodEnums } & FlexProps
> = ({ method, onMethodChange, ...props }) => {
  return (
    <Flex {...props}>
      <Tabs value={method} onTagChanged={onMethodChange} indicator={<StyledIndicator />} width="100%">
        <StyledTabContainer
          id="switchview-wallet"
          value={AuthModalMethodEnums.WEB3}
          $active={method === AuthModalMethodEnums.WEB3}
        >
          <Text fontSize="14px">
            <Trans>Wallet</Trans>
          </Text>
        </StyledTabContainer>

        <StyledTabContainer
          id="switchview-email"
          value={AuthModalMethodEnums.TRADITIONAL}
          $active={method === AuthModalMethodEnums.TRADITIONAL}
        >
          <Text fontSize="14px">
            <Trans>Email</Trans>
          </Text>
        </StyledTabContainer>
      </Tabs>
    </Flex>
  );
};

const StyledIndicator = styled(Box)`
  background: ${({ theme }) => `${theme.colors.backgroundAlt3}a0`};
  border-radius: ${({ theme }) => theme.radii.small};
  height: 100%;
  width: 100%;
`;

const StyledTabContainer = styled(Tab)<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  width: 50%;

  ${Text} {
    z-index: 1;
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
  }

  &:hover,
  &:focus-visible {
    ${Text} {
      color: ${({ theme }) => theme.colors.text};
      white-space: pre !important;
    }
  }
`;
export default SwitchMethod;
