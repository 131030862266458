import BigNumber from 'bignumber.js';
import { LoginMethod } from 'config/constants/auth';
import { Token, TokenAmount } from 'config/types';
import { useFetchUserProfile } from 'hooks/useFetchUserProfile';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state';
import { selectPlayBalance, updateTokenUsdPrices } from 'state/app/actions';
import { useListTokens, useTokenSelected } from 'state/app/hooks';
import { login } from 'state/auth/action';
import { useAuth } from 'state/auth/hooks';
import { useUserBonus } from 'state/bonus/hooks';
import { getBalanceTokenKey } from 'utils';
import { getCurrencyBalance } from 'utils/infura';
import { forkjoinRequest } from 'utils/requestHelper';
import { isSolToken } from 'utils/token';
import { ProfileState } from '.';
import { updateBalances } from './actions';
import { fetchUserBalances } from './calls/fetchUserBalances';

export const useHandleFetchUserBalance = () => {
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    const results = await fetchUserBalances();
    if (results?.data) {
      dispatch(updateBalances({ data: results.data.balances }));
      dispatch(updateTokenUsdPrices({ data: results.data.tokensPrice }));
    }
  }, [dispatch]);
};

export const usePollUserBalances = () => {
  const { isSigned } = useAuth();
  const handleFetchUserBalances = useHandleFetchUserBalance();

  useIsomorphicEffect(() => {
    if (!isSigned) return;

    handleFetchUserBalances();
  }, [isSigned]);

  // useEffect(() => {
  //   if (!isSigned || !socket) {
  //     return;
  //   }

  //   socket.on('balance.changed', (data) => {
  //     const balance: BalanceResponse = {
  //       amount: data.after_balance,
  //       currency: data.currency,
  //       network: ChainIdEnum[formatApiNetworkField(data.network)],
  //     };
  //     dispatch(updateBalances({ data: [balance] }));
  //   });

  //   return () => {
  //     if (socket) {
  //       socket.off('balance.changed');
  //     }
  //   };
  // }, [isSigned, socket]);
};

export const usePollUserProfile = () => {
  const dispatch = useAppDispatch();
  const { isSigned, loginBy } = useAuth();
  const fetchUserInfo = useFetchUserProfile();
  const { fetchBonuses } = useUserBonus();

  useEffect(() => {
    if (!isSigned) return;

    const fetch = async () => {
      const result = await fetchUserInfo();

      if (result) {
        dispatch(
          login({
            uid: result.uid,
            username: result.username,
            address: result.address,
            email: loginBy === LoginMethod.Wallet ? '' : result.address,
          }),
        );
        fetchBonuses();
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchBonuses, fetchUserInfo, isSigned]);
};

export const useUpdateUserToken = () => {
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state) => state.auth.wallet);
  const supportedTokens = useListTokens();
  const selectedToken = useTokenSelected();
  const { isSigned } = useAuth();

  useEffect(() => {
    if (!isSigned || supportedTokens.length <= 0) return;
    // INTERGRATE SOLANA
    // if (wallet?.type === WalletType.SOL) {
    //   if (!selectedToken || !isSolToken(selectedToken)) {
    //     const token = supportedTokens.find(
    //       (token) => token.network === ChainIdEnum.SOL || token.network === ChainIdEnum.SOL_TESTNET,
    //     )
    //     dispatch(selectPlayBalance({ token }))
    //   }
    // } else {
    if (!selectedToken || isSolToken(selectedToken)) {
      dispatch(selectPlayBalance({ token: supportedTokens[0] }));
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned, wallet]);
};

export const useProfileInfo = (): ProfileState => {
  const profile = useAppSelector((state) => state.profile);

  return useMemo(() => {
    return profile;
  }, [profile]);
};

export const useTokenWalletBalance = (token: Token, address: string): TokenAmount => {
  const tokens = useMemo(() => [token], [token]);
  const [tokenBalance] = useTokenWalletBalances(tokens, address);

  return useMemo(() => {
    return tokenBalance;
  }, [tokenBalance]);
};

export const useTokenWalletBalances = (tokens: Token[], address: string): TokenAmount[] => {
  const [tokenBalances, setTokenBalances] = useState<TokenAmount[]>([]);

  useEffect(() => {
    if (!tokens || !address) return;
    const fetch = async () => {
      const getTokenBalanceRequests = tokens.map((token) => {
        return getCurrencyBalance(token, address);
      });

      const tokenBalances: TokenAmount[] = await forkjoinRequest(getTokenBalanceRequests);
      if (tokenBalances) setTokenBalances(tokenBalances);
    };

    fetch();
    const interval = setInterval(fetch, 10000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, address]);

  return useMemo(() => {
    return tokenBalances;
  }, [tokenBalances]);
};

export const useTokenAppBalance = (token: Token): TokenAmount => {
  const amounts = useTokenAppBalances([token]);
  return useMemo(() => {
    return amounts[0];
  }, [amounts]);
};

export const useTokenAppBalances = (tokens: Token[]): TokenAmount[] => {
  const tokenBalances = useAppSelector((state) => state.profile.balances);

  return useMemo(() => {
    if (!tokens) return [];
    return tokens
      .filter((token) => token)
      .map((token) => {
        const tokenBalance = tokenBalances[getBalanceTokenKey(token.network, token.code)];
        return {
          amount: new BigNumber(tokenBalance || 0),
          token,
        };
      });
  }, [tokenBalances, tokens]);
};
