import { ChainIdEnum } from 'config/constants/network';
import { DEV_MODE } from 'config/constants/server';
import { QueryDepositInfo, Transaction } from 'config/types/transaction';
import {
  BalanceResponse,
  BaseResponse,
  Paging,
  PrepareSignMessageResponse,
  TokenUsdPriceResponse,
  VerificationResponse,
  WithdrawResponse,
} from 'services/types';
import { formatApiNetworkField } from 'utils';
import { parseDepositTransaction, parseTimeDate, parseTransactionHistory, parseWithdrawalTransaction } from './utils';

export const parseUserBalanceItem = (data: any) => ({
  amount: data.balance,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(`${data.chain}${DEV_MODE ? ':TESTNET' : ''}`, false)],
});

export const parseUsdCurrencyItem = (data: any): TokenUsdPriceResponse => {
  return data.reduce((state, item) => {
    state[item.currency] = {
      usd: item.price,
    };
    return state;
  }, {});
};

export const BalanceMapper = (
  rawResponse: string,
): BaseResponse<{
  balances: BalanceResponse;
  tokensPrice: TokenUsdPriceResponse;
}> => {
  const response = JSON.parse(rawResponse);

  if (!response?.data) {
    return {
      code: 'network_error',
      data: null,
    };
  }
  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      balances: response.data.balances.map(parseUserBalanceItem),
      tokensPrice: parseUsdCurrencyItem(response.data.tokensPrice),
    },
  };
};

export const TraditionalDepositMapper = (rawResponse: string): BaseResponse<{ address: string; minAmount: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      address: response.data.address,
      minAmount: response.data.minAmount,
    },
  };
};

export const WithdrawFeeMapper = (rawResponse: string): BaseResponse<{ withdrawFee: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      withdrawFee: response.data.fee,
    },
  };
};

export const PrepareTraditionalWithdrawMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      token: response.data.token,
      expireTime: parseInt(response.data.expireTime, 10),
      nextResendTime: parseInt(response.data.nextResendTime, 10),
    },
  };
};

export const PrepareWalletWithdrawMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      acceptMessage: response.data.message,
      acceptMessageText: response.data.message,
      address: response.data.address,
    },
  };
};

export const WithdrawMapper = (rawResponse: string): BaseResponse<WithdrawResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      ref: response.data.ref,
      createdAt: parseTimeDate(response.data.createdAt),
    },
  };
};

export const WithdrawalTransactionMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data ? parseWithdrawalTransaction(response.data) : null,
  };
};

export const WithdrawalTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseWithdrawalTransaction(item)),
    },
  };
};

export const DepositTransactionMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseWithdrawalTransaction(item)),
    },
  };
};

export const TransactionHistoryMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,

    data: response?.data && {
      // paging: response.data.paging,
      items: response.data.txns && response.data.txns.map((item) => parseTransactionHistory(item)),
    },
  };
};

export const DepositTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      items: response.data && response.data.map((item) => parseDepositTransaction(item)),
    },
  };
};

export const QueryDepositByHashMapper = (rawResponse: string): BaseResponse<QueryDepositInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      status: response.data.watch.status,
      depositInfo: response.data.deposit
        ? {
            currency: response.data.deposit.currency,
            network: ChainIdEnum[formatApiNetworkField(response.data.deposit.network)],
            value: response.data.deposit.amount,
            status: response.data.deposit.status,
          }
        : null,
    },
  };
};
