import BigNumber from 'bignumber.js';
import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Text from 'components/Text';
import { Token } from 'config/types';
import { Trans } from 'react-i18next';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { Icons } from 'svgs';
import { colors } from 'theme/colors';
import theme from 'theme';
import TokenLogo from 'components/TokenLogo';
import { Transaction, TransactionTypeEnum } from 'config/types/transaction';
import tokens from 'config/constants/tokens';
import React from 'react';

const PendingTransaction: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const token = tokens[transaction.network][transaction.currency] as Token;
  const displayAmount = getFullDisplayBalance(new BigNumber(transaction.value).minus(transaction.fee || 0), 0, 5);
  return (
    <Box minWidth="250px">
      <Flex alignItems="center">
        <Box
          mr="12px"
          height="100%"
          padding="6px"
          border={`1px solid ${colors.info}`}
          background={`${colors.info}33`}
          borderRadius={theme.radii.tiny}
        >
          <Icons.HourGlassIcon height="24px" fill={colors.info} />
        </Box>
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Text color="info" fontSize="16px" bold>
            {transaction.type === TransactionTypeEnum.Deposit ? (
              <Trans>Your Deposit processing</Trans>
            ) : (
              <Trans>Your withdrawal processing</Trans>
            )}
          </Text>
          <Flex mt="4px" alignItems="center">
            <TokenLogo token={token} width="20px" />
            <Text ml="6px" fontSize="14px">
              {displayAmount} {token.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PendingTransaction;
