import { APIEndpointEnum } from 'config/constants/server';
import { UserBonus } from 'config/types/bonus/userBonus';
import { HunnyPokerRequest } from './HunnyPokerRequest';
import { ApiStatusCode, BaseResponse, HunnyRequest, Paging } from './types';

class BonusService extends HunnyPokerRequest {
  public getUserBonuses(page = 1, limit = 10): HunnyRequest<BaseResponse<Paging<UserBonus>>> {
    return this._post(APIEndpointEnum.UserBonuses, { page, limit });
  }

  public async prepareDepositBonus(bonusId: string): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.BonusPrepareDeposit, { bonusId });
    return result.code === ApiStatusCode.Success;
  }

  public getUserBonusDetails(bonusId: string): HunnyRequest<BaseResponse<UserBonus>> {
    const result = this._post(APIEndpointEnum.UserBonusDetails, { bonusId });
    return result;
  }
}

const instance = new BonusService();
export default instance;
