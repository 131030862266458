import Box from 'components/Box/Box';
import React from 'react';
import styled from 'styled-components';
import { Icons } from 'svgs';
import hunnyKeyframes from 'theme/keyframes';

const CircleLoader = () => {
  return (
    <Wrapper>
      <Icons.CircleLoadingBackgroundIcon />
      <StyledSpin>
        <Icons.CircleLoadingIcon />
      </StyledSpin>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  svg {
    max-width: 24px;
  }
`;

const StyledSpin = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: ${hunnyKeyframes.rotate} 2s linear infinite;
`;
export default React.memo(CircleLoader);
