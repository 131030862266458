import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledContainer = styled(Box)<{ $active: boolean; $disabled?: boolean }>`
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export type TabProps = {
  value: any;
  onTagChanged?: (value: any) => void;
  selectedValue?: any;
  disabled?: boolean;
};

const Tab: React.FC<TabProps & BoxProps> = ({ value, onTagChanged, selectedValue, children, disabled, ...props }) => {
  const ref = useRef(null);

  const handleSelect = () => {
    if (disabled) return;
    onTagChanged(value);
  };

  return (
    <StyledContainer
      id={value}
      ref={ref}
      $disabled={disabled}
      $active={selectedValue === value}
      onClick={handleSelect}
      tabIndex={selectedValue === value ? 0 : -1}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

export default Tab;
