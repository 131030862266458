import BigNumber from 'bignumber.js';
import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import { BoxProps } from 'components/Box/types';
import { InputProps } from 'components/Input';
import NumericalInput from 'components/Input/NumericalInput';
import { InputContainer } from 'components/Input/styled';
import Text from 'components/Text';
import { BIG_ZERO } from 'config/constants/number';
import { Token } from 'config/types';
import { ValidationError } from 'config/types/validator';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

interface TokenInputProps extends BoxProps, InputProps {
  token: Token;
  placeholder?: string;
  max: BigNumber;
  min?: BigNumber;
  hideMax?: boolean;
  errors?: ValidationError[];
  disabled?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  getFee?: () => Promise<BigNumber>;
}

const TokenInput: React.FC<TokenInputProps> = ({
  token,
  value,
  placeholder = '',
  onValueChanged,
  onErrorChanged,
  max,
  disabled,
  errors,
  autoFocus = false,
  validators = [],
  tabIndex,
  getFee,
  ...props
}) => {
  const [fetchingFee, setFetchingFee] = useState(false);

  const handleFillMaxBalance = async () => {
    let fee = BIG_ZERO;

    if (getFee) {
      setFetchingFee(true);
      fee = await getFee();
      setFetchingFee(false);
    }
    if (max.gt(fee.multipliedBy(3))) {
      onValueChanged(max.minus(fee.multipliedBy(3)).toString(10));
    } else {
      onValueChanged(max.toNumber().toString());
    }
  };

  return (
    <InputContainer id="token-input" {...props}>
      <StyledTokenInput
        tabIndex={tabIndex}
        value={value}
        validators={validators}
        onValueChanged={onValueChanged}
        onErrorChanged={onErrorChanged}
        validateOnchange
        placeholder={placeholder}
      />
      <Flex alignItems="center" p="15px 20px">
        <Text mr="20px" color="textAlt">
          {token?.name}
        </Text>
        {!disabled && (
          <>
            <StyledMaxField id="max-button" onClick={() => !fetchingFee && handleFillMaxBalance()}>
              <Text textTransform="uppercase" color="textHighlight" fontSize="14px" fontWeight="700">
                <Trans>MAX</Trans>
              </Text>
            </StyledMaxField>
          </>
        )}
      </Flex>
    </InputContainer>
  );
};

const StyledMaxField = styled(Box)`
  cursor: pointer;
`;

const StyledTokenInput = styled(NumericalInput)`
  text-align: right;
  height: auto !important;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.text};
`;

export default TokenInput;
