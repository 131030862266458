import { APIEndpointEnum, MessageResponseServer } from 'config/constants/server';
import { MyProfileInfo } from 'config/types/profile';
import { HunnyPokerRequest } from './HunnyPokerRequest';
import { BaseResponse, HunnyRequest } from './types';

class ProfileService extends HunnyPokerRequest {
  public getUserInfo(): HunnyRequest<BaseResponse<MyProfileInfo>> {
    return this._post(APIEndpointEnum.Profile, { excludeErrors: [MessageResponseServer.unauthorized] });
  }
}

const instance = new ProfileService();
export default instance;
