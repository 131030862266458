import React, { forwardRef, useMemo } from 'react';
import NextLink from 'next/link';
import { filterAllowedParams } from 'utils/urlHelper';
import { useRouter } from 'hooks/useRouter';
import { ActionQueryEnum, useActionQueryHandler } from 'hooks/useActionQueryListener';

// react-router-dom LinkProps types
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: any;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
  // next
  prefetch?: boolean;
}

/**
 * temporary solution for migrating React Router to Next.js Link
 */

export const NextLinkFromReactRouter = forwardRef<any, LinkProps>(
  ({ to, href, replace, children, prefetch, ...props }, ref) => {
    const router = useRouter();
    const handleAction = useActionQueryHandler();

    const urlDetails = useMemo(() => {
      try {
        const link = new URL(to || href || '/', window.location.origin);
        return {
          pathname: link.pathname,
          query: Object.fromEntries(link.searchParams.entries()),
        };
      } catch {
        return {
          pathname: to || href,
          query: {} as { [key: string]: string },
        };
      }
    }, [to, href]);

    const action = useMemo(() => {
      if (router.pathname === urlDetails.pathname) {
        if (urlDetails.query.action && Object.keys(urlDetails.query).length === 1) {
          return urlDetails.query.action;
        }
      }

      return null;
    }, [urlDetails, router]);

    return (
      <NextLink
        ref={ref}
        replace={replace}
        passHref
        prefetch={prefetch}
        {...props}
        onClick={(e) => {
          if (!e.ctrlKey && action) {
            e.preventDefault();
            handleAction(action as ActionQueryEnum);
          }
        }}
        href={{
          pathname: urlDetails.pathname,
          query: {
            ...urlDetails.query,
            ...filterAllowedParams({
              ...router.query,
            }),
          },
        }}
      >
        {children}
      </NextLink>
    );
  },
);
