import { APIEndpointEnum } from 'config/constants/server';
import {
  DeepLinkMapper,
  LoginResponseMapper,
  NewPasswordNonceMapper,
  PasswordNonceResponseMapper,
  PrepareSignMessageMapper,
  RegisterMapper,
  ResetPasswordVerificationResponseMapper,
  VerificationResponseMapper,
} from './Authenication';

import { UserBonusDetailsMapper, UserBonusMapper } from './Bonus';
import { MetaDataMapper, TokenInUsdPriceMapper } from './Meta';
import {
  BalanceMapper,
  DepositTransactionMapper,
  DepositTransactionsMapper,
  PrepareTraditionalWithdrawMapper,
  PrepareWalletWithdrawMapper,
  QueryDepositByHashMapper,
  TraditionalDepositMapper,
  TransactionHistoryMapper,
  WithdrawFeeMapper,
  WithdrawMapper,
  WithdrawalTransactionMapper,
  WithdrawalTransactionsMapper,
} from './Payment';
import { UserProfileMapper } from './ProfileMapper';

class Mapper {
  private _mapper = {
    [APIEndpointEnum.Meta]: MetaDataMapper,
    [APIEndpointEnum.TokenUsdPrices]: TokenInUsdPriceMapper,
    [APIEndpointEnum.WalletLoginPrepare]: PrepareSignMessageMapper,
    [APIEndpointEnum.SignInByWallet]: LoginResponseMapper,
    [APIEndpointEnum.SignInByEmail]: LoginResponseMapper,
    [APIEndpointEnum.Refresh]: LoginResponseMapper,
    [APIEndpointEnum.DeepLink]: DeepLinkMapper,

    [APIEndpointEnum.PrepareTraditionalLogin]: PasswordNonceResponseMapper,
    [APIEndpointEnum.Register]: RegisterMapper,
    [APIEndpointEnum.SignUpPrepare]: PasswordNonceResponseMapper,
    [APIEndpointEnum.VerifyRegisterUser]: VerificationResponseMapper,
    [APIEndpointEnum.VerifyResetPassword]: ResetPasswordVerificationResponseMapper,
    [APIEndpointEnum.ValidateResetPassword]: NewPasswordNonceMapper,

    [APIEndpointEnum.Balances]: BalanceMapper,
    [APIEndpointEnum.TraditionalDeposit]: TraditionalDepositMapper,
    [APIEndpointEnum.WithdrawFee]: WithdrawFeeMapper,
    [APIEndpointEnum.PrepareTraditionalWithdraw]: PrepareTraditionalWithdrawMapper,
    [APIEndpointEnum.PrepareWalletWithdraw]: PrepareWalletWithdrawMapper,
    [APIEndpointEnum.WalletWithdraw]: WithdrawMapper,
    [APIEndpointEnum.TraditionalWithdraw]: WithdrawMapper,

    [APIEndpointEnum.WithdrawalTransaction]: WithdrawalTransactionMapper,
    [APIEndpointEnum.WithdrawalTransactions]: WithdrawalTransactionsMapper,
    [APIEndpointEnum.DepositTransaction]: DepositTransactionMapper,
    [APIEndpointEnum.DepositTransactions]: DepositTransactionsMapper,
    [APIEndpointEnum.QueryDepositByHash]: QueryDepositByHashMapper,
    [APIEndpointEnum.TransactionHistory]: TransactionHistoryMapper,

    [APIEndpointEnum.UserBonuses]: UserBonusMapper,
    [APIEndpointEnum.UserBonusDetails]: UserBonusDetailsMapper,

    // Profile update this file when have api balance
    [APIEndpointEnum.Profile]: UserProfileMapper,
  };

  public getMapper(url: string) {
    return (this._mapper as any)[url];
  }
}

const mapper = new Mapper();

export default mapper;
