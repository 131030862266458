import IconButton from 'components/Button/IconButton';
import { ButtonProps } from 'components/Button/types';
import React from 'react';
import { Icons } from 'svgs';
import { StyledAdornment } from '.';

interface RemoveValueInputAdornmentProps extends ButtonProps {
  value: any;
  onRemove: () => void;
}
const RemoveValueInputAdornment: React.FC<RemoveValueInputAdornmentProps> = ({ value, onRemove, ...props }) => {
  const isDisplayRemoveIcon = value !== null && value !== '' && value !== undefined;

  return (
    isDisplayRemoveIcon && (
      <StyledAdornment>
        <IconButton onClick={onRemove} display={isDisplayRemoveIcon ? 'block' : 'none'} {...props}>
          <Icons.CloseCircleIcon height="20px" width="20px" />
        </IconButton>
      </StyledAdornment>
    )
  );
};

export default RemoveValueInputAdornment;
