import { ModalProps } from 'components/Modal/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Text from 'components/Text';
import Box from 'components/Box/Box';
import { Icons } from 'svgs';
import FormValidator from 'config/constants/formValidator';
import useForm from 'hooks/useForm';
import FormControl from 'components/FormControl';
import FormInput from 'components/FormControl/FormInput';
import CircleLoader from 'components/Loader/CircleLoader';
import { ValidationError } from 'config/types/validator';
import { useCountDown } from 'hooks/useCountdown';
import { HunnyRequest, VerificationResponse } from 'services/types';
import { useRequest } from 'hooks/useRequest';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import Dots from 'components/Loader/Dots';
import ForgotPasswordModal from './ForgotPasswordModal';

export interface EmailVerificationModalProps {
  email: string;
  initialVerifiedResult: VerificationResponse;
  verifiedHunnyRequestFn: () => HunnyRequest<VerificationResponse>;
  onSubmit: (verification: VerificationResponse, code: string) => Promise<any>;
}

export const verificationErrorMessages = {
  [ValidationError.Required]: 'Please provide verification code',
  [ValidationError.IncorrectLength]: 'Please enter the 6-digits verification code',
  [ValidationError.InCorrect]: 'Incorrect code',
  [ValidationError.DataExpired]: 'Your OTP is expired. Please resend new OTP',
};

const EmailVerificationModal: React.FC<ModalProps<EmailVerificationModalProps>> = ({
  onDismiss,
  data: { email, initialVerifiedResult, verifiedHunnyRequestFn, onSubmit },
}) => {
  const { t } = useTranslation();
  const { execute } = useRequest();

  const [submiting, setSubmiting] = useState(false);
  const [verification, setVerifiedResult] = useState(initialVerifiedResult);
  const [canResend, setCanResend] = useState(false);
  const [requestingResend, setRequestingResend] = useState(false);

  useIsomorphicEffect(() => {
    setCanResend(false);
    setRequestingResend(false);
  }, [verification]);

  const resendCodeTextRef = useCountDown(
    verification?.nextResendTime,
    (s, m) => `${s + m * 60}`,
    () => {
      setCanResend(true);
    },
  );

  const expireTimeTextRef = useCountDown(
    verification?.expireTime,
    (s, m) => `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`,
  );

  const { states, controls, validateAll, isValid } = useForm({
    code: {
      validators: [FormValidator.required, FormValidator.requiredLength(6)],
      value: '',
    },
  });

  const handleResendCode = async () => {
    setRequestingResend(true);
    const verification = await execute(verifiedHunnyRequestFn());
    if (!verification) {
      // Show error
      return;
    }

    setVerifiedResult(verification);
  };

  const handleSubmit = async () => {
    setSubmiting(true);
    const isValid = await validateAll();
    if (!isValid) {
      setSubmiting(false);
      return;
    }

    const data = await onSubmit(verification, states.code.value);
    if (data?.code === 'error_auth_input') {
      controls.code.onErrorChanged([ValidationError.InCorrect]);
    }
    if (data?.code === 'error_data_expired') {
      controls.code.onErrorChanged([ValidationError.DataExpired]);
    }

    if (data.code === 'success') {
      onDismiss();
    }
    setSubmiting(false);
  };

  return (
    <ForgotPasswordModal onDismiss={onDismiss} label={t('Email Verification')}>
      <StyledSubtleText fontWeight="300" lineHeight="20px">
        <Trans>Please enter the 6-digits verification code that was sent to</Trans> <strong>{email}</strong>.
        <Trans>The code is valid for 10 minutes.</Trans>
      </StyledSubtleText>

      <FormControl
        mt="40px"
        state={states.code}
        label={t('Verification Code')}
        formatErrorMessage={(e) => verificationErrorMessages[e[0]]}
      >
        <FormInput
          control={controls.code}
          placeholder={t('Enter Code')}
          type="text"
          name="verification_code"
          size={6}
          maxLength={6}
          icon={<Icons.PasswordIcon />}
        />
      </FormControl>

      <StyledSubtleText mt="12px" fontSize="14px" textAlign="center">
        <Trans>Haven’t recieves code</Trans>?{' '}
        {canResend ? (
          <Button
            variant="text"
            p="0px !important"
            height="auto !important"
            onClick={handleResendCode}
            disabled={requestingResend}
          >
            {requestingResend ? (
              <Text fontSize="14px" color="textSubtle" bold>
                <Dots>
                  <Trans>Sending</Trans>
                </Dots>
              </Text>
            ) : (
              <Text fontSize="14px" color="primary" bold>
                <Trans>Resend now</Trans>
              </Text>
            )}
          </Button>
        ) : (
          <>
            <Trans>Resend in</Trans> <span ref={resendCodeTextRef}>...</span> <Trans>seconds</Trans>
          </>
        )}
      </StyledSubtleText>

      <Button
        mt={['24px', '24px', '40px']}
        disabled={!isValid || !states.code.value || submiting}
        onClick={handleSubmit}
      >
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Verify</Trans>
        </Text>
      </Button>

      <Text mt="60px" fontSize="14px" color="textSubtle" textAlign="center">
        <Trans>Code will be expired in</Trans> <strong ref={expireTimeTextRef}>...</strong>
      </Text>
    </ForgotPasswordModal>
  );
};

const StyledSubtleText = styled(Text).attrs({ color: 'textSubtle' })`
  strong {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export default EmailVerificationModal;
