import { useState } from 'react';
import useResizeEventListener from './useResizeEventListener';

export type ElementScrollDirection = 'horizontal' | 'vertical';

const useElementScrollable = (type: ElementScrollDirection = 'vertical') => {
  const [scrollable, setScrollable] = useState(false);
  const [offset, setOffset] = useState(0);

  const { elementRef } = useResizeEventListener(() => {
    if (!elementRef.current) return;
    const offset = type === 'vertical' ? elementRef.current.offsetHeight : elementRef.current.offsetWidth;
    const scroll = type === 'vertical' ? elementRef.current.scrollHeight : elementRef.current.scrollWidth;

    setOffset(offset);
    setScrollable(offset < scroll);
  });

  return { scrollable, elementRef, offset };
};

export default useElementScrollable;
