import { useState } from 'react';
import useDebounceCallback from './useDebounceCallback';
import useElementScrollable, { ElementScrollDirection } from './useElementScrollable';
import { useIsomorphicEffect } from './useIsomorphicEffect';

enum ScrollViewPostion {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

const useElementScrollInfo = (type: ElementScrollDirection = 'vertical') => {
  const { offset, elementRef, scrollable } = useElementScrollable(type);
  const [position, setPosition] = useState<ScrollViewPostion>(null);
  const debounce = useDebounceCallback();

  useIsomorphicEffect(() => {
    if (!offset || !scrollable || !elementRef) {
      setPosition(null);
      return;
    }

    const handler = () => {
      debounce(() => {
        const scrollMargin = type === 'vertical' ? elementRef.current.scrollTop : elementRef.current.scrollLeft;
        const viewSize =
          scrollMargin + (type === 'vertical' ? elementRef.current.offsetHeight : elementRef.current.offsetWidth);
        const scrollSize = type === 'vertical' ? elementRef.current.scrollHeight : elementRef.current.scrollWidth;

        if (elementRef.current.offsetWidth === scrollSize) {
          setPosition(null);
          return;
        }

        if (scrollMargin === 0) {
          setPosition(ScrollViewPostion.Start);
        } else if (scrollSize - viewSize < 1) {
          setPosition(ScrollViewPostion.End);
        } else {
          setPosition(ScrollViewPostion.Center);
        }
      }, 150);
    };

    handler();
    elementRef.current.addEventListener('scroll', handler, {
      passive: true,
    });

    return () => {
      elementRef.current?.removeEventListener('scroll', handler);
    };
  }, [offset, scrollable]);

  return {
    elementRef,
    scroll: position && {
      start: position === ScrollViewPostion.Start,
      center: position === ScrollViewPostion.Center,
      end: position === ScrollViewPostion.End,
    },
  };
};

export default useElementScrollInfo;
