import { StaticJsonRpcProvider, TransactionReceipt } from '@ethersproject/providers';
import Box from 'components/Box/Box';
import Button from 'components/Button';
import { StyledControlContainer } from 'components/FormControl/styled';
import { InputContainer, InputLabel, InputMessage, StyledInput } from 'components/Input/styled';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import TokenSelect from 'components/TokenSelect';
import FormValidator from 'config/constants/formValidator';
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
import {
  QueryDepositStatusEnums,
  QUERY_TRANSACTION_INTERVAL,
  TransactionReceiptStatusEnum,
} from 'config/constants/transactions';
import { Token } from 'config/types';
import { QueryDepositInfo } from 'config/types/transaction';
import { ValidationError } from 'config/types/validator';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PaymentService from 'services/PaymentService';
import { useAllTokens, useTokenSelected } from 'state/app/hooks';
import { getSimplerRpcProvider } from 'utils/providers';
import { HunnyToast } from 'utils/toastify';

import NetworkSelect from 'components/NetworkSelect';
import { useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import { getNetworkInfo } from 'utils/network';
import { isSolToken } from 'utils/token';
import InternalQueryDepositResult from './InternalQueryDepositResult';

const TransactionHashInputErrorMessages = {
  [ValidationError.Required]: 'Transaction hash is required',
};
const InternalQueryDeposit = () => {
  const { isSigned } = useAuth();

  const { wallet } = useAppSelector((state) => state.auth);
  const isSolNetwork = false; // wallet?.type === WalletType.SOL;
  const currentToken = useTokenSelected();

  const tokens = useAllTokens((token) => (isSolNetwork ? isSolToken(token) : !isSolToken(token)), [wallet]);

  const networks = useMemo(
    () => tokens.map((token) => NETWORK_MAP[token.network]).filter((v, i, a) => a.indexOf(v) === i),
    [tokens],
  );

  const [selectedToken, setSelectedToken] = useState<Token>(null);
  const [chainId, setChainId] = useState<ChainIdEnum>(null);

  const [transactionHashInput, setTransactionHashInput] = useState<string>('');
  const [isIntervalQuery, setIsIntervalQuery] = useState<boolean>(false);
  const [subscribeDepositId, setSubscribeDepositId] = useState<number>(-1);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [depositInfo, setDepositInfo] = useState<QueryDepositInfo & { txnHash: string }>(null);

  const network = useMemo(() => NETWORK_MAP[chainId], [chainId]);

  const { t } = useTranslation();
  const { execute } = useRequest();
  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    if (!selectedToken && currentToken && (isSolNetwork ? isSolToken(currentToken) : !isSolToken(currentToken))) {
      setSelectedToken(currentToken);
      setChainId(currentToken.network);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken, selectedToken]);

  useEffect(() => {
    if (!isSigned) {
      setSelectedToken(null);
      setChainId(null);
    }
  }, [isSigned]);

  useEffect(() => {
    if (isIntervalQuery) {
      const timer = setInterval(() => {
        handleQueryDeposit(subscribeDepositId);
      }, QUERY_TRANSACTION_INTERVAL);

      return () => clearInterval(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntervalQuery, subscribeDepositId]);

  const handleQueryDeposit = async (subscribeDepositId) => {
    const response = await execute(PaymentService.queryDepositByHash(subscribeDepositId));
    if (response.data) {
      setDepositInfo({ ...response.data, txnHash: transactionHashInput });
      if (response.data.status !== QueryDepositStatusEnums.Init) {
        setIsIntervalQuery(false);
      }
    } else {
      HunnyToast.error(response.message);
      setIsIntervalQuery(false);
    }

    setSubmiting(false);
  };

  const handleSubscribeDeposit = async () => {
    setDepositInfo(null);
    if (!transactionHashInput) return;

    setSubmiting(true);
    const provider: StaticJsonRpcProvider = getSimplerRpcProvider(selectedToken.network);
    let txnReceiptResponse: TransactionReceipt;

    if (!isSolToken(selectedToken)) {
      try {
        txnReceiptResponse = await provider.getTransactionReceipt(transactionHashInput);
      } catch (error) {
        HunnyToast.error(t('Your transaction hash is invalid'));
        setSubmiting(false);
        return;
      }
    }

    if (
      (txnReceiptResponse && txnReceiptResponse.status === TransactionReceiptStatusEnum.SUCCESS) ||
      isSolToken(selectedToken)
    ) {
      const response = await PaymentService.subscribeDepositByHash(
        selectedToken.code,
        selectedToken.network,
        transactionHashInput,
      );

      if (response.data) {
        setSubscribeDepositId(response.data.id);
        handleQueryDeposit(response.data.id);
        setIsIntervalQuery(true);
      }
    } else {
      HunnyToast.error(t('Sorry, We are unable to locate this TxnHash'));
    }

    setSubmiting(false);
  };

  return (
    selectedToken && (
      <>
        <OpenEffect openType="grow">
          <Box width="100%" mt={42}>
            <StyledControlContainer state={null} mt="10px">
              <InputLabel>
                <Trans>Select Network</Trans>
              </InputLabel>
              <NetworkSelect
                options={networks}
                value={network}
                onNetworkChange={(network) => {
                  const { native } = getNetworkInfo(network.chainId);

                  setSelectedToken(native);
                  setChainId(network.chainId);
                }}
                dropdownContentProps={{
                  maxHeight: '200px !important',
                }}
              />
            </StyledControlContainer>

            <StyledControlContainer state={null} mt="16px">
              <InputLabel>
                <Trans>Select Coin</Trans>
              </InputLabel>
              <TokenSelect
                value={selectedToken}
                onTokenChange={setSelectedToken}
                options={tokens.filter((token) => token.network === network.chainId)}
                dropdownContentProps={{
                  maxHeight: '200px !important',
                }}
              />
            </StyledControlContainer>

            <Box mt={20}>
              <InputLabel fontSize="12px" color="textSubtle">
                <Trans>Enter TxID/TxHash</Trans>
              </InputLabel>

              <InputContainer py={16} pl={2} mt={10} mb={10}>
                <StyledInput
                  value={transactionHashInput}
                  onValueChanged={setTransactionHashInput}
                  placeholder={t('Enter TxID/TxHash')}
                  onErrorChanged={setErrors}
                  validators={[FormValidator.required]}
                />
              </InputContainer>

              <InputMessage color="error" textAlign="right">
                {errors.length > 0 ? <Trans>{TransactionHashInputErrorMessages[errors[0]]}</Trans> : null}
              </InputMessage>
            </Box>

            <Button disabled={!transactionHashInput || submiting} width="100%" mt={30} onClick={handleSubscribeDeposit}>
              <Text fontSize="14px">
                <Trans>Check</Trans>
              </Text>
            </Button>
          </Box>
        </OpenEffect>

        <InternalQueryDepositResult info={depositInfo} isFetching={submiting} />
      </>
    )
  );
};

export default InternalQueryDeposit;
