import { LoginMethod } from 'config/constants/auth';
import { useFetchUserProfile } from 'hooks/useFetchUserProfile';
import { useCallback } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { useAppDispatch } from 'state';
import { setIsSigned, updateDeviceUid } from 'state/app/actions';
import { signedExecuteLogin } from 'state/auth/action';
import { constructEncryptedPassword } from 'utils/auth';
import { getID } from 'utils/fingerprint';

export const useSignInWithEmail = () => {
  const dispatch = useAppDispatch();
  const handleFetchUserProfile = useFetchUserProfile();

  return useCallback(
    async (password: string, email: string) => {
      const prepareResponse = await AuthenticationService.getTraditionalSignInPrepare(email).call();
      if (!prepareResponse?.data) return { code: 'error' };

      const deviceUid = await getID();
      const response = await AuthenticationService.signInByEmail({
        deviceUid,
        email,
        password: constructEncryptedPassword(password, prepareResponse.data),
      });
      if (response?.code === 'success') {
        dispatch(updateDeviceUid({ deviceUid }));
        dispatch(
          signedExecuteLogin({
            loginBy: LoginMethod.Email,
            wallet: null,
          }),
        );
        dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }));
        handleFetchUserProfile();
      }

      return response;
    },
    [dispatch, handleFetchUserProfile],
  );
};
