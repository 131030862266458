import { isEmail } from 'utils/stringHelper';
import BigNumber from 'bignumber.js';
import { HunnyRequest } from 'services/types';
import { transformHunnyRequest } from 'utils/requestHelper';
import { ethers } from 'ethers';
import { GroupValidator, InputValidator, ValidationError } from '../types/validator';

export const commomErrorMessage = {
  Email: 'Please enter a correct email address',
  EmailDuplicate: 'This email is already in use',
  Required: 'Please enter a value.',
  Integer: 'Please enter a correct integer number',
};

class FormValidator {
  static required(value: any): ValidationError {
    return checkInputIsEmpty(value) ? ValidationError.Required : null;
  }

  static blockCharaters(chars: string[]) {
    return (value: string): ValidationError => {
      return chars.find((char) => value.includes(char)) ? ValidationError.IncorrectType : null;
    };
  }

  static requiredNumber(value: any): ValidationError {
    return !/\d/.test(value) ? ValidationError.RequiredNumber : null;
  }

  static requiredUppercase(value: any): ValidationError {
    return !/[A-Z]/.test(value) ? ValidationError.RequiredUppercase : null;
  }

  static checked(value: boolean): ValidationError {
    return !value ? ValidationError.NotChecked : null;
  }

  static integer(value: any): ValidationError | null {
    return !Number.isInteger(Number(value)) ? ValidationError.Integer : null;
  }

  static email(value: any): ValidationError | null {
    return !checkInputIsEmpty(value) && !isEmail(value) ? ValidationError.Email : null;
  }

  static etherAddress(value: any): ValidationError | null {
    return !ethers.utils.isAddress(value) ? ValidationError.IsEtherAddress : null;
  }

  static uniqueEmail(reqFn: (value: any) => HunnyRequest<boolean | null> | null): InputValidator {
    return (value) => {
      if (FormValidator.required(value) || FormValidator.email(value)) return null;
      const request = reqFn(value);

      return transformHunnyRequest(request, (value) => (value ? ValidationError.EmailDuplicate : null));
    };
  }

  static existEmail(reqFn: (value: any) => HunnyRequest<boolean | null> | null): InputValidator {
    return (value) => {
      if (FormValidator.required(value) || FormValidator.email(value)) return null;
      const request = reqFn(value);

      return transformHunnyRequest(request, (value) => (value ? ValidationError.EmailNotExist : null));
    };
  }

  static inputLength(config: { min?: number; max?: number }): InputValidator {
    return (value: any) => {
      const { min, max } = config;

      if (value?.length < min) {
        return ValidationError.TooShort;
      }
      if (value?.length > max) {
        return ValidationError.TooLong;
      }

      return null;
    };
  }

  static requiredLength(number: number): InputValidator {
    return (value: any) => {
      if (value?.length !== number) {
        return ValidationError.IncorrectLength;
      }

      return null;
    };
  }
  // eslint-disable-next-line
  public static equal(value: `$${string}`): GroupValidator;

  public static equal(value: string): InputValidator;

  public static equal(input: `$${string}` | string): GroupValidator | InputValidator {
    if (input[0] === '$') {
      const fieldName = input.replaceAll('$', '');

      return (formControlValues: { [key: string]: { value: any } }) => (value: any) => {
        return JSON.stringify(value) !== JSON.stringify(formControlValues[fieldName].value)
          ? ValidationError.NotEqual
          : null;
      };
    }

    return (value: any) => {
      // eslint-disable-next-line no-self-compare
      return JSON.stringify(value) !== JSON.stringify(value) ? ValidationError.NotEqual : null;
    };
  }

  static gte =
    (min: number | string | BigNumber) =>
    (value: number | string | BigNumber): ValidationError => {
      return new BigNumber(value).gte(new BigNumber(min)) ? ValidationError.GreatThanEqual : null;
    };

  static lte =
    (min: number | string | BigNumber) =>
    (value: number | string | BigNumber): ValidationError => {
      return new BigNumber(value).lte(new BigNumber(min)) ? ValidationError.LessThanEqual : null;
    };

  static min =
    (min: number | string | BigNumber) =>
    (value: number | string | BigNumber): ValidationError => {
      return new BigNumber(min).lte(new BigNumber(value)) ? null : ValidationError.NotEnough;
    };

  static max =
    (max: number | string | BigNumber) =>
    (value: number | string | BigNumber): ValidationError => {
      return new BigNumber(max).lt(new BigNumber(value)) && value !== null ? ValidationError.Insufficient : null;
    };
}

export const checkInputIsEmpty = (value: any) => {
  return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
};

export const checkIsJSON = (str: any): false | any => {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return result;
};

export default FormValidator;
