import { ChainIdEnum } from 'config/constants/network';
import { APIEndpointEnum } from 'config/constants/server';
import { Token } from 'config/types';
import { QueryDepositInfo, Transaction } from 'config/types/transaction';
import { formatToApiNetworkField } from 'utils';
import { HunnyPokerRequest } from './HunnyPokerRequest';
import {
  BalanceResponse,
  BaseResponse,
  HunnyRequest,
  Paging,
  PrepareSignMessageResponse,
  PrepareTokenNetworkPayload,
  PrepareWithdrawPayload,
  TokenUsdPriceResponse,
  VerificationResponse,
  WithdrawResponse,
} from './types';

class PaymentService extends HunnyPokerRequest {
  public getBalances(): HunnyRequest<
    BaseResponse<{
      balances: BalanceResponse[];
      tokensPrice: TokenUsdPriceResponse;
    }>
  > {
    return this._post(APIEndpointEnum.Balances);
  }

  public traditionalDeposit({
    currency,
  }: {
    currency: Token;
  }): HunnyRequest<BaseResponse<{ address: string; minAmount: string }>> {
    const result = this._post(APIEndpointEnum.TraditionalDeposit, {
      currency: currency.code,
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
    });

    return result;
  }

  public getWithdrawFee({
    amount,
    currency,
  }: PrepareTokenNetworkPayload): HunnyRequest<BaseResponse<{ withdrawFee: string }>> {
    const result = this._post(APIEndpointEnum.WithdrawFee, {
      currency: currency.code,
      amount,
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
    });

    return result;
  }

  public prepareTraditionalWithdraw({
    amount,
    currency,
    toAddress,
  }: PrepareTokenNetworkPayload): HunnyRequest<BaseResponse<VerificationResponse>> {
    const result = this._post(APIEndpointEnum.PrepareTraditionalWithdraw, {
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
      currency: currency.code,
      amount,
      toAddress,
    });
    return result;
  }

  public prepareWalletWithdraw(
    amount: string,
    currency: Token,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const payload = {
      amount,
      currency: currency.code,
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
    };

    const result = this._post(APIEndpointEnum.PrepareWalletWithdraw, payload);

    return result;
  }

  public async traditionalWithdraw({
    amount,
    currency,
    toAddress,
    otp,
    verificationCode,
  }: PrepareWithdrawPayload): Promise<BaseResponse<WithdrawResponse>> {
    const result = await this._request(APIEndpointEnum.TraditionalWithdraw, {
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
      currency: currency.code,
      amount,

      toAddress,
      verification: {
        otp,
        token: verificationCode,
      },
    });

    return result;
  }

  public async walletWithdraw(currency: Token, amount: string, sign: string): Promise<BaseResponse<WithdrawResponse>> {
    const result = await this._request(APIEndpointEnum.WalletWithdraw, {
      network: formatToApiNetworkField(ChainIdEnum[currency.network]),
      currency: currency.code,
      amount,
      signature: sign,
    });
    return result;
  }

  public getWithdrawal(code: string): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(APIEndpointEnum.WithdrawalTransaction, {
      ref: Number(code),
    });
    return result;
  }

  public getWithdrawals(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<Transaction>>> {
    const result = this._post(APIEndpointEnum.WithdrawalTransactions, {
      paging: {
        offset,
        limit,
      },
    });
    return result;
  }

  public getTransactionHistory(page: number, limit: number) {
    const result = this._post(APIEndpointEnum.TransactionHistory, {
      page,
      limit,
    });
    return result;
  }

  public getDeposit(hash: string): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(APIEndpointEnum.DepositTransaction, {
      txnHash: hash,
    });

    return result;
  }

  public getDeposits(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<{ item: Transaction }>>> {
    return this._post(APIEndpointEnum.DepositTransactions, {
      paging: {
        offset,
        limit,
      },
    });
  }

  public async subscribeDeposit(chainId: ChainIdEnum, currency: Token): Promise<void> {
    await this._request(APIEndpointEnum.SubscribeDeposit, {
      network: formatToApiNetworkField(ChainIdEnum[chainId]),
      currency: currency.code,
    });
  }

  public async subscribeDepositByHash(
    currency: string,
    chainId: ChainIdEnum,
    txnHash: string,
  ): Promise<BaseResponse<{ id: number }>> {
    const response = await this._request(APIEndpointEnum.SubscribeDepositByHash, {
      currency,
      network: formatToApiNetworkField(ChainIdEnum[chainId]),
      txnHash,
    });

    return response;
  }

  public queryDepositByHash(id: number): HunnyRequest<BaseResponse<QueryDepositInfo>> {
    const result = this._post(APIEndpointEnum.QueryDepositByHash, { id });

    return result;
  }
}

const instance = new PaymentService();
export default instance;
