import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import { StyledControlContainer } from 'components/FormControl/styled';
import { InputLabel, InputMessage } from 'components/Input/styled';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import TokenInput from 'components/TokenInput';
import FormValidator from 'config/constants/formValidator';
import { BIG_ZERO } from 'config/constants/number';
import { Token } from 'config/types';
import { WageringConditionBonus } from 'config/types/bonus/userBonus';
import { ValidationError } from 'config/types/validator';
import useDepositFee from 'hooks/useDepositFee';
import useForm from 'hooks/useForm';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import { useTokenWalletBalance } from 'state/profile/hooks';
import styled from 'styled-components';
import { FlexProps } from 'styled-system';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { combineDepositHash } from 'utils/transaction';
import { useAddDepositTransaction } from 'state/transaction/hooks';
import { TransactionResponse } from '@ethersproject/providers';
import DepositButton from './DepositButton';

const DepositInputErrorMessages = {
  depositValue: {
    [ValidationError.Insufficient]: 'Insufficient Balance',
  },
};

interface DepositByContractProps {
  depositToken: Token;
  selectedBonus: WageringConditionBonus;
  handleSelectedBonus: (bonus: WageringConditionBonus) => Promise<boolean>;
  isSelectedBonusExpired?: boolean;
  isDepositing: boolean;
  setIsDepositing: (isDepositing: boolean) => void;
}

const DepositByContract: React.FC<DepositByContractProps & FlexProps> = ({
  depositToken,
  selectedBonus,
  isSelectedBonusExpired,
  handleSelectedBonus,
  setIsDepositing,
  isDepositing,
  ...props
}) => {
  const { isSigned } = useAuth();
  const estimatedFee = useDepositFee(depositToken);
  const wallet = useAppSelector((state) => state.auth.wallet);
  const tokenAmount = useTokenWalletBalance(depositToken, wallet?.address);
  const [confirmingHash, setConfirmingHash] = useState(null);
  const isConfirmingHashProcessingRef = useRef(false);
  const pendingDepositTransactionHash = useAppSelector(
    (state) => state.transaction.pendingDepositTransactionHash || [],
  );
  const handleAddDepositTransaction = useAddDepositTransaction();

  const { states, controls, validateAll, isValid } = useForm({
    amount: {
      validators: [FormValidator.lte(0), FormValidator.max(tokenAmount?.amount)],
      value: '',
    },
  });

  const balance = tokenAmount ? tokenAmount.amount : BIG_ZERO;

  const onStartDeposit = () => {
    setIsDepositing(true);
  };

  const onFailed = () => {
    setIsDepositing(false);
  };

  const onDepositSuccess = async (transaction: TransactionResponse) => {
    handleAddDepositTransaction(transaction, depositToken, controls.amount.value);
    setTimeout(() => setConfirmingHash(combineDepositHash(transaction.hash, depositToken)), 500);
  };

  useEffect(() => {
    if (!confirmingHash || !pendingDepositTransactionHash) return;
    const isInclude = !!pendingDepositTransactionHash.find((pending) => pending.txnHash === confirmingHash);

    if (!isInclude && isConfirmingHashProcessingRef.current) {
      controls.amount.onValueChanged('');
      setIsDepositing(false);
      setConfirmingHash(null);
    } else if (isInclude && !isConfirmingHashProcessingRef.current) {
      isConfirmingHashProcessingRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmingHash, isDepositing, pendingDepositTransactionHash, setIsDepositing]);

  return (
    <Flex mt="24px" flexDirection="column" {...props}>
      <Flex flex="1 1">
        <StyledControlContainer state={states.amount}>
          <StyledLabel>
            <InputLabel mb="0px !important">
              <Trans>Amount</Trans>
            </InputLabel>
            <InputLabel mb="0px !important">
              <Flex>
                <Trans>Wallet Balance</Trans>:
                {tokenAmount ? (
                  <>
                    {' '}
                    {getFullDisplayBalance(balance, 0, 6)} {depositToken?.name}
                  </>
                ) : (
                  <Skeleton display="inline" minWidth="50px" ml="4px" minHeight="16px !important" />
                )}
              </Flex>
            </InputLabel>
          </StyledLabel>
          <TokenInput
            disabled={isDepositing}
            value={states.amount.value}
            token={depositToken}
            errors={states.amount.errors}
            validators={controls.amount.validators}
            onErrorChanged={controls.amount.onErrorChanged}
            onValueChanged={controls.amount.onValueChanged}
            max={tokenAmount?.amount}
            getFee={depositToken.isNative ? estimatedFee : null}
          />

          <Box minHeight="24px" mt="4px">
            <InputMessage color="error" textAlign="right" mb="4px">
              <Trans>{DepositInputErrorMessages.depositValue[states.amount.errors[0]]}</Trans>
            </InputMessage>

            <Text color="textAlt" fontSize="12px" textAlign="right">
              <Trans>No deposit fee</Trans>
            </Text>
          </Box>
        </StyledControlContainer>
      </Flex>
      <Box width="100%" mt="24px" mb="10px">
        {isSigned && (
          <DepositButton
            id="deposit-button"
            width="100%"
            loading={isDepositing}
            onStart={onStartDeposit}
            onFailed={onFailed}
            selectedToken={depositToken}
            validateAll={validateAll}
            handleSelectedBonus={handleSelectedBonus}
            onSucceed={onDepositSuccess}
            value={states.amount.value}
            disabled={!isValid || !states.amount.value || isSelectedBonusExpired}
            selectedBonus={selectedBonus}
          />
        )}
      </Box>
    </Flex>
  );
};

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default DepositByContract;
