import { HunnyRequest } from 'services/types';

export enum ValidationError {
  Email = 'Email',
  EmailDuplicate = 'EmailDuplicate',
  EmailNotExist = 'EmailNotExist',
  WrongPassword = 'WrongPassword',
  Required = 'Required',
  RequiredNumber = 'RequiredNumber',
  RequiredUppercase = 'RequiredUppercase',
  Integer = 'Integer',
  IncorrectLength = 'IncorrectLength',
  IncorrectType = 'IncorrectType',
  DataExpired = 'DataExpired',
  NotEqual = 'NotEqual',
  TooShort = 'TooShort',
  TooLong = 'TooLong',
  NotEnough = 'NotEnough',
  Insufficient = 'Insufficient',
  IsZero = 'IsZero',
  InCorrect = 'InCorrect',
  NotChecked = 'NotChecked',
  GreatThanEqual = 'GreatThanEqual',
  LessThanEqual = 'LessThanEqual',
  IsEtherAddress = 'IsEtherAddress',
}

export type InputValidator = (value: any) => ValidationError | null | HunnyRequest<ValidationError>;
export type GroupValidator = (formValues: { [fieldName: string]: any }) => InputValidator;
