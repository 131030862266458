import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import Text from 'components/Text';
import { WalletInfo } from 'config/types/wallet';
import { useConnectWallet } from 'hooks/useConnectWallet';
import styled from 'styled-components';
import { getIcon } from 'svgs';
import React from 'react';

interface WalletCardProps {
  wallet: WalletInfo;
  onSelect: (wallet: WalletInfo) => void;
}

const WalletCard: React.FC<WalletCardProps & BoxProps> = ({ wallet, onSelect, ...props }) => {
  const walletConnector = useConnectWallet();
  const Icon = getIcon(wallet.icon);

  return (
    <StyledContainer
      {...props}
      onClick={async () => {
        const result = await walletConnector[wallet.type].connect(wallet.adapter);
        if (result) onSelect(wallet);
      }}
    >
      <Text fontSize="12px" fontWeight="500">
        {wallet.name}
      </Text>
      <Icon width="28px" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  max-height: 46px;
  background: ${({ theme }) => theme.colors.backgroundAlt3};
  border: 1px solid ${({ theme }) => theme.colors.backgroundAlt3};
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export default WalletCard;
