import { NoBscProviderError } from '@binance-chain/bsc-connector';

// INTERGRATE SOLANA
// import { WalletDisconnectionError, WalletNotReadyError } from '@solana/wallet-adapter-base'
// import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { ConnectorNameEnum } from 'config/constants';
import { WalletType } from 'config/types/wallet';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { logError } from 'utils/sentry';
import { HunnyToast } from 'utils/toastify';
import { connectorsByName } from 'utils/web3React';

export const useConnectWallet = (): {
  [type in WalletType]: {
    connect: (adapter: any) => Promise<any>;
    disconnect: any;
  };
} => {
  const { activate, deactivate } = useWeb3React();
  // const { select, disconnect, connected } = useSolWallet()
  const { t } = useTranslation();

  // const handleSolWalletConnect = async (adapter: any) => {
  //   try {
  //     select(adapter.name)
  //     return true
  //   } catch (e) {
  //     if (e instanceof WalletNotReadyError) {
  //       HunnyToast.error(t('Your wallet is not ready'), null, {
  //         toastId: 'not-ready-wallet',
  //       })
  //     } else {
  //       HunnyToast.error(t('Something went wrong'), null, {
  //         toastId: 'reject-connect-wallet',
  //       })
  //       logError(`Connect ${adapter.name} (SOL) Wallet`, e)
  //     }

  //     return true
  //   }
  // }

  const handleEVMDisconnect = useCallback(() => {
    deactivate();
    if (window.localStorage.getItem(ConnectorNameEnum.WalletConnect)) {
      window.localStorage.removeItem(ConnectorNameEnum.WalletConnect);
      connectorsByName.walletconnect.close();
    }
  }, [deactivate]);

  const handleEVMWalletConnect = useCallback(
    async (adapter: any) => {
      if (!adapter) return;

      if (adapter instanceof WalletConnectConnector) {
        if (window.localStorage.getItem(ConnectorNameEnum.WalletConnect)) {
          window.localStorage.removeItem(ConnectorNameEnum.WalletConnect);
          await adapter.close();
        }
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        await activate(adapter, async (error: any) => {
          resolve(false);
          if (
            error instanceof NoEthereumProviderError ||
            (error instanceof NoBscProviderError && !(adapter instanceof WalletConnectConnector))
          ) {
            HunnyToast.error(t('Wallet is not found!'));
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (adapter instanceof WalletConnectConnector) {
              adapter.walletConnectProvider = null;
            }
            HunnyToast.warn(t('Rejected.'), t('You have been rejected connect wallet.'));
          } else if (error.code === -32002) {
            HunnyToast.error(
              t('Please confirm connect wallet.'),
              t('Please check your wallet and confirm to connect HunnyPoker.'),
            );
          } else {
            if (adapter instanceof WalletConnectConnector) {
              handleEVMDisconnect();
              HunnyToast.error(t('Wrong chain'), t('Please switch to your selected network and connect again'));
            }
            logError('Connect EVM Wallet', error);
          }
        });

        resolve(true);
      });
    },
    [activate, handleEVMDisconnect, t],
  );

  // const handleSOLDisconnect = async () => {
  //   if (connected) {
  //     try {
  //       await disconnect()
  //     } catch (error) {
  //       if (error instanceof WalletDisconnectionError)
  //         HunnyToast.error(error.name, error.message, {
  //           toastId: 'reject-disconnect-wallet',
  //         })
  //       logError(`Disconnect SOL Wallet`, error)
  //     }
  //   }
  // }

  return useMemo(
    () => ({
      [WalletType.EVM]: {
        connect: handleEVMWalletConnect,
        disconnect: handleEVMDisconnect,
      },
      [WalletType.BSC]: {
        connect: handleEVMWalletConnect,
        disconnect: handleEVMDisconnect,
      },
      // [WalletType.SOL]: {
      //   connect: handleSolWalletConnect,
      //   disconnect: handleSOLDisconnect,
      // },
    }),
    [handleEVMWalletConnect, handleEVMDisconnect],
  );
};
