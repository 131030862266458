import { DepositCommonBonus, ICommonBonus } from 'config/types/bonus';
import { DepositBonus, UserBonus } from 'config/types/bonus/userBonus';
import useModal from 'hooks/useModal';
import { usePrivateSocket } from 'hooks/usePrivateSocket';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BonusService from 'services/BonusService';
import { useAppDispatch, useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import DepositedActiveBonusModal from 'views/Payment/components/Deposit/DepositedActiveBonusModal';
import { parseUserBonus } from 'services/mapper/Bonus';
import { updateUserBonuses } from './actions';

export const useUserBonus = () => {
  const dispatch = useAppDispatch();
  const { userBonuses: bonuses, preparedBonusId } = useAppSelector((state) => state.bonus);

  const fetchBonuses = useCallback(async (): Promise<UserBonus[]> => {
    const result = await BonusService.getUserBonuses(1, 10).call();
    if (result?.data?.items) {
      dispatch(
        updateUserBonuses({
          userBonuses: result?.data?.items,
        }),
      );
    }
    return result?.data?.items || [];
  }, [dispatch]);

  return { bonuses: bonuses || [], fetchBonuses, preparedBonusId };
};

export const useActivatedBonusesUpdater = () => {
  const { isSigned } = useAuth();
  const { fetchBonuses } = useUserBonus();
  const socket = usePrivateSocket();

  const [presentDepositedActiveBonusModal] = useModal(DepositedActiveBonusModal);

  useEffect(() => {
    if (!isSigned || !socket) {
      return;
    }

    socket.on('DepositBonusActivated', async (data) => {
      if (!data) return;
      fetchBonuses();
      presentDepositedActiveBonusModal({ bonus: parseUserBonus(data) });
    });

    return () => {
      if (socket) {
        socket.off('DepositBonusActivated');
      }
    };
  }, [fetchBonuses, isSigned, presentDepositedActiveBonusModal, socket]);
};

export enum BonusActionTypeEnums {
  Deposit = 'deposit',
  Active = 'active',
  Register = 'resgister',
  Use = 'use',
  Play = 'play',
  Claim = 'claim',
}

export const buildBonusName = (t: (content: string, config?: any) => string, bonus: any): [string, string] => {
  if (!bonus) return ['', ''];

  if (bonus instanceof DepositBonus || bonus instanceof DepositCommonBonus) {
    return [t('{{percent}} on the deposit', { percent: `${bonus.percent}%` }), t('Deposit bonus')];
  }

  // if (bonus instanceof NoDepositBonus || bonus instanceof NoDepositCommonBonus) {
  //   return [
  //     t('Receive {{amount}} {{currency}}', {
  //       amount: bonus.bonusAmount?.amount.toString(),
  //       currency: bonus.bonusAmount?.token?.code,
  //     }),
  //     t('{{currency}} bonus', {
  //       currency: bonus.bonusAmount?.token?.code,
  //     }),
  //   ];
  // }

  return ['', ''];
};
export const useDisplayBonusName = (bonus: ICommonBonus): [string, string] => {
  const { t } = useTranslation();
  return useMemo(() => {
    return buildBonusName(t, bonus);
  }, [bonus, t]);
};
