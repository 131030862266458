import { ChainIdEnum } from 'config/constants/network';
import { Transaction, TransactionStatusEnum, TransactionTypeEnum } from 'config/types/transaction';
import { ApiStatusCode, BaseResponse } from 'services/types';
import { formatApiNetworkField } from 'utils';

export const parseWithdrawalTransaction = (data: any): Transaction => ({
  id: data.ref,
  status: data.status === ApiStatusCode.Success ? TransactionStatusEnum.Succeeded : TransactionStatusEnum.Pending,
  txnHash: data.txnHash,
  value: data.value,
  createTime: parseTimeDate(data.createdAt),
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.chain)],
  type: TransactionTypeEnum.Withdraw,
  fee: data.fee,
});

export const parseDepositTransaction = (data: any): Transaction => ({
  id: data.ref,
  status: data.status === ApiStatusCode.Success ? TransactionStatusEnum.Succeeded : TransactionStatusEnum.Pending,
  txnHash: data.txnHash,
  value: data.value,
  createTime: parseTimeDate(data.createdAt),
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.chain)],
  type: TransactionTypeEnum.Deposit,
  fee: data.fee,
});

export const parseTransactionHistory = (data: any): Transaction => ({
  status: data.status === ApiStatusCode.Success ? TransactionStatusEnum.Succeeded : TransactionStatusEnum.Pending,
  txnHash: data.txnHash,
  value: data.value,
  createTime: parseTimeDate(data.createdAt),
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.chain)],
  type: data.txnType === 0 ? TransactionTypeEnum.Deposit : TransactionTypeEnum.Withdraw,
  fee: data.fee,
});

export const parseTimeNumber = (time: string): number => parseInt(time, 10) * 1000;
export const parseTimeDate = (time: string): number => new Date(time).getTime();

export const getErrorCodeByMessage = (response: BaseResponse<any>): string => {
  if (response.code === 'failed') {
    switch (response.message) {
      case 'The email address already existed':
        return 'error_data_exists';
      default:
        return response.code;
    }
  }
};
