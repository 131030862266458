import { ModalProps } from 'components/Modal/Modal';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Box from 'components/Box/Box';
import { Icons } from 'svgs';
import FormValidator from 'config/constants/formValidator';
import useForm from 'hooks/useForm';
import FormControl from 'components/FormControl';
import CircleLoader from 'components/Loader/CircleLoader';
import { ValidationError } from 'config/types/validator';
import { PasswordNonceResponse } from 'services/types';
import FormInputPassword from 'components/FormControl/FormInputPassword';
import AuthenticationService from 'services/AuthenticationService';
import { constructEncryptedPassword } from 'utils/auth';
import { HunnyToast } from 'utils/toastify';
import { useSignInWithEmail } from '../hooks';
import ForgotPasswordModal from './ForgotPasswordModal';

export interface ResetPasswordFormModalProps {
  email: string;
  nonce: PasswordNonceResponse;
}

export const resetPasswordErrorMessages = {
  [ValidationError.Required]: 'Please enter your password',
  [ValidationError.NotEqual]: 'Password does not match.',
};

const ResetPasswordFormModal: React.FC<ModalProps<ResetPasswordFormModalProps>> = ({
  onDismiss,
  data: { email, nonce },
}) => {
  const { t } = useTranslation();
  const [submiting, setSubmiting] = useState(false);
  const signIn = useSignInWithEmail();

  const { states, controls, validateAll, isValid } = useForm({
    password: {
      validators: [
        FormValidator.required,
        FormValidator.inputLength({ min: 8 }),
        FormValidator.requiredNumber,
        FormValidator.requiredUppercase,
        FormValidator.blockCharaters([' ']),
      ],
      value: '',
      validateOnChange: true,
    },
    confirmPassword: {
      formValidators: [FormValidator.equal('$password')],
      value: '',
      validateOnChange: true,
    },
  });

  const handleSubmit = async () => {
    setSubmiting(true);

    const isValid = await validateAll();
    if (!isValid) {
      setSubmiting(false);
      return;
    }

    const submitRes = await AuthenticationService.submitResetPassword({
      newPassword: constructEncryptedPassword(states.password.value, nonce),
      nonce,
      email,
    });

    if (submitRes.code === 'success') {
      const response = await signIn(states.password.value, email);
      if (response.code === 'success') {
        HunnyToast.success('Password reset successful', 'Awesome, you have successfully updated your password');
        onDismiss();
      }
    }
  };

  return (
    <ForgotPasswordModal id="" onDismiss={onDismiss} label={t('Reset Password')}>
      <Heading>
        <Trans>Please enter your new password</Trans>
      </Heading>

      <FormControl
        mt="12px"
        state={states.password}
        label={t('Password')}
        formatErrorMessage={(errors) => t(resetPasswordErrorMessages[errors[0]])}
        instructionConfig={[
          { message: t('Use at least 8 characters'), validationError: ValidationError.TooShort },
          { message: t('Use 1 or more number'), validationError: ValidationError.RequiredNumber },
          { message: t('Use upper case characters'), validationError: ValidationError.RequiredUppercase },
          { message: t('Password does not allow space'), validationError: ValidationError.IncorrectType },
        ]}
      >
        <FormInputPassword control={controls.password} placeholder={t('Password')} icon={<Icons.PasswordIcon />} />
      </FormControl>

      <FormControl
        mt="20px"
        state={states.confirmPassword}
        label={t('Confirm Password')}
        formatErrorMessage={(errors) => t(resetPasswordErrorMessages[errors[0]])}
      >
        <FormInputPassword
          control={controls.confirmPassword}
          placeholder={t('Confirm Password')}
          icon={<Icons.PasswordIcon />}
        />
      </FormControl>

      <Button
        id="submit-button"
        mt={['24px', '24px', '40px']}
        disabled={!isValid || !states.password.value || submiting}
        onClick={handleSubmit}
      >
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Reset Password</Trans>
        </Text>
      </Button>
    </ForgotPasswordModal>
  );
};

export default ResetPasswordFormModal;
