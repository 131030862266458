// import { useWallet } from '@solana/wallet-adapter-react'
// INTERGRATE SOLANA

import { useWeb3React } from '@web3-react/core';
import { WalletType } from 'config/types/wallet';
import { useTranslation } from 'react-i18next';
import { HunnyToast } from 'utils/toastify';
import { signMessage } from 'utils/web3React';

const useSignMessage = (type: WalletType): ((message: string) => Promise<string>) => {
  // const { signMessage: solSignMessage } = useWallet()
  const { t } = useTranslation();

  const { account, library } = useWeb3React();

  const _handleEtherWalletSignMessage = async (message: string) => {
    return new Promise<string>((resolve) => {
      const callback = (sign: string, error: any) => {
        resolve(sign);

        if (error) {
          if (error.code === 'ACTION_REJECTED') {
            HunnyToast.warn(t('Rejected'), t('You have been rejected sign message on wallet'), {
              toastId: 'sign-message-failed',
            });
          } else {
            HunnyToast.error(t('Error'), error.message, {
              toastId: 'sign-message-failed',
            });
          }
        }
      };
      signMessage(library, account, message, callback);
    });
  };

  // const _handleSolWalletSignMessage = async (inputMessage: string) => {
  //   try {
  //     const message = new TextEncoder().encode(inputMessage)
  //     const signature = await solSignMessage(message)

  //     if (signature) {
  //       const signatureTextString = ethers.utils.hexlify(signature)
  //       return signatureTextString
  //     }
  //   } catch (error) {
  //     HunnyToast.warn(t('Rejected'), t('You have been rejected sign message on wallet'), {
  //       toastId: 'sign-message-failed',
  //     })

  //     logError('sol wallet sign message', error)
  //     return null
  //   }
  // }

  if (type === WalletType.EVM || type === WalletType.BSC) {
    return _handleEtherWalletSignMessage;
  }
  // else if (type === WalletType.SOL) {
  //   return _handleSolWalletSignMessage
  // }
};

export default useSignMessage;
