import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import { Checkbox } from 'components/Checkbox';
import { InputProps } from 'components/Input';
import Text from 'components/Text';
import Column from 'layout/Components/Column';
import React from 'react';
import { Trans } from 'react-i18next';

const CheckCondition: React.FC<InputProps> = ({ value, onValueChanged, ...props }) => {
  return (
    <Column>
      <Flex>
        <Box mr="12px">
          <Checkbox width="18px" height="18px" value={value} onValueChanged={onValueChanged} {...props} />
        </Box>
        <Box
          style={{
            display: 'inline',
          }}
        >
          <Text as="span" fontWeight={500} fontSize="12px" lineHeight="16px" color="textTertiary" mr="4px">
            <Trans>I confirm that I am over 18 years old</Trans>
          </Text>

          {/* <Link
            id="condition-terms-of-service-text"
            style={{
              display: 'inline-flex',
              wordBreak: 'break-word',
            }}
            fontSize="12px"
            color="primary"
            target="_blank"
            href={RouteConfig.TermOfService}
            external
          >
            <Trans>Terms of Service</Trans>.
          </Link> */}
        </Box>
      </Flex>
      {/* {!value && (
        <InputMessage color={`${theme.colors.error} !important`} mt="4px" ml="30px">
          <Trans>Please read and agree to the terms of service.</Trans>
        </InputMessage>
      )} */}
    </Column>
  );
};

export default CheckCondition;
