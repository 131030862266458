import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TokenKeyEnum } from 'config/constants/auth';
import { APIEndpointEnum, BASE_API, EXCLUDE_API_ERROR } from 'config/constants/server';
import { logError } from 'utils/sentry';
import { HunnyToast } from 'utils/toastify';
import { BaseRequest, hunnyAxios } from './BaseRequest';
import Mapper from './mapper/Mapper';
import { ApiStatusCode, BaseResponse, HunnyRequest } from './types';

export abstract class HunnyPokerRequest extends BaseRequest {
  protected async _get(url: string, params?: any, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await hunnyAxios.get(url, { params, ...config });
      return result.data;
    } catch (e) {
      logError(`GET ${url}`, e);
      return null;
    }
  }

  protected _post(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[] },
    mapperKey?: string,
  ): HunnyRequest<BaseResponse<any>> {
    const _source = this.AxiosCancelToken;

    const call = async (): Promise<BaseResponse<any>> => {
      const response = await this._request(
        url,
        payload,
        {
          ...config,
          cancelToken: _source.token,
        },
        mapperKey,
      );

      return response;
    };

    return {
      call,
      cancel: () => {
        _source.cancel();
      },
    };
  }

  protected async _request(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[]; disabledToast?: boolean },
    mapperKey?: string,
  ): Promise<BaseResponse<any>> {
    try {
      const mapper = Mapper.getMapper(mapperKey || url);
      const { excludeErrors = [], ...axiosConfig } = config;

      const token =
        url === APIEndpointEnum.Refresh
          ? localStorage.getItem(TokenKeyEnum.RefreshToken)
          : localStorage.getItem(TokenKeyEnum.AccessToken);

      const _config = this.buildConfig(
        {
          ...axiosConfig,
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
            ...axiosConfig.headers,
          },
        },
        mapper,
      );

      const result: AxiosResponse<BaseResponse<any>> = await this.callRequest(url, _config, payload);

      if (result?.data?.code === 'error_country_banned' && window.location.pathname !== '/restriction') {
        window.location.href = '/restriction';
      } else if (result?.data && result.data.code === 'error_auth_blocked') {
        window.location.replace('/403');
      } else if (
        result?.data &&
        result.data.code !== ApiStatusCode.Success &&
        ![...EXCLUDE_API_ERROR, ...excludeErrors].includes(result.data.message?.toLowerCase())
      ) {
        if (result.data.message && !config?.disabledToast) {
          HunnyToast.error(result.data.message);
        }
        const message = `API ${url} Failed`;
        logError(message, { message, extra: { response: result.data, payload }, tags: ['api_failed'] });
      }

      return result?.data;
    } catch (e) {
      if (!axios.isCancel(e)) logError(`CALL ${url}`, e);
      return null;
    }
  }

  protected async callRequest(url: string, config: AxiosRequestConfig, payload?: any) {
    return hunnyAxios.post(url, payload, {
      ...config,
      validateStatus: (id) => id !== 404,
    });
  }

  protected buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig {
    let _config = {
      baseURL: typeof window === 'undefined' ? process.env.INTERNAL_API || BASE_API : BASE_API,
      // withCredentials: true,
      ...config,
    };

    if (mapper) {
      _config = { ..._config, transformResponse: mapper };
    }

    return _config;
  }
}
