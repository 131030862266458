import { ChainIdEnum } from 'config/constants/network';
import { ethers } from 'ethers';

import BigNumber from 'bignumber.js';
import ERC20 from 'config/constants/abis/ERC20.json';
import HunnyPoker from 'config/constants/abis/HunnyPoker.json';

import { Token, TokenAmount } from 'config/types';
import { StaticJsonRpcProvider, TransactionResponse } from '@ethersproject/providers';
import { BIG_ZERO, UINT256_MAX } from 'config/constants/number';
import { getBalanceAmount, getDecimalAmount } from './formatBalance';
// import { PublicKey } from '@solana/web3.js'
// import { web3 } from '@project-serum/anchor'
// import { TOKEN_PROGRAM_ID } from 'config/constants/solanaConfig'
// INTERGRATE SOLANA
// import { AccountLayout } from '@solana/spl-token'
import { logError } from './sentry';
import { forkjoinRequest } from './requestHelper';
import { isSolToken } from './token';
import { getSimplerRpcProvider } from './providers';

export const getTokenContract = (token: Token, signer?: ethers.Signer | ethers.providers.Provider): ethers.Contract => {
  const rpcProvider = getSimplerRpcProvider(token.network);
  const signerOrProvider = signer ?? rpcProvider;

  return new ethers.Contract(token.address, ERC20.abi, signerOrProvider);
};

export const getHunnyPokerContract = (
  address: string,
  signer: ethers.Signer | ethers.providers.Provider,
): ethers.Contract => {
  return new ethers.Contract(address, HunnyPoker.abi, signer);
};

export const getNativeWalletBalance = async (chainId: ChainIdEnum, userAddress: string): Promise<BigNumber> => {
  try {
    const connection = getSimplerRpcProvider(chainId);
    // EVM network
    if (connection instanceof StaticJsonRpcProvider) {
      const balance = await connection.getBalance(userAddress);

      return new BigNumber(balance.toString());
    }
    // INTGERGARTE SOLANA
    // SOL network
    // if (connection instanceof web3.Connection) {
    //   const lamportUnit = await connection.getBalance(new PublicKey(userAddress))
    //   const nativeUnit = new BigNumber(lamportUnit)
    //   return nativeUnit
    // }
  } catch (e: any) {
    logError('Fetch native token  balance failed', {
      message: e.message,
      extra: { chainId, address: userAddress },
    });
    return BIG_ZERO;
  }
};

export const getTokenWalletBalance = async (token: Token, userAddress: string): Promise<BigNumber> => {
  try {
    const connection = getSimplerRpcProvider(token.network);
    // EVM network
    if (connection instanceof StaticJsonRpcProvider) {
      const contract = getTokenContract(token);
      const balance = await contract.balanceOf(userAddress);
      return new BigNumber(balance.toString());
    }
    // SOL network
    // if (connection instanceof web3.Connection) {
    //   const tokenAccounts = await connection.getTokenAccountsByOwner(new PublicKey(userAddress), {
    //     programId: TOKEN_PROGRAM_ID,
    //     mint: new PublicKey(token.address),
    //   })

    //   if (tokenAccounts.value?.length) {
    //     const accountInfo = AccountLayout.decode(tokenAccounts.value[0].account.data)

    //     return new BigNumber(accountInfo.amount.toString())
    //   }

    //   return BIG_ZERO
    // }
  } catch (e: any) {
    logError('Fetch token balance failed', {
      message: e.message,
      extra: { token, address: userAddress },
    });
    return BIG_ZERO;
  }
};

export const getCurrencyBalance = async (token: Token, address: string): Promise<TokenAmount> => {
  const { network } = token;
  let balance = null;

  if (token.isNative) {
    balance = await getNativeWalletBalance(network, address);
  } else {
    balance = await getTokenWalletBalance(token, address);
  }
  return {
    token,
    amount: getBalanceAmount(balance, token.decimals),
  };
};

export const getTokenAllowance = async (token: Token, owner: string, spender: string) => {
  try {
    const contract = getTokenContract(token);
    const response = await contract.allowance(owner, spender);
    const currentAllowance = new BigNumber(response.toString());

    return currentAllowance;
  } catch (error) {
    logError(`Fetching allowance failed ${token.name} ${token.network}`, error);
    return new BigNumber(0);
  }
};

export const approve = async (signer: ethers.Signer, token: Token, spender: string) => {
  const contract = getTokenContract(token, signer);
  try {
    const tx = await contract.approve(spender, UINT256_MAX.toString(10));
    const receipt = await tx.wait();
    if (receipt.status) {
      return receipt.transactionHash;
    }
    return null;
  } catch (e) {
    logError(`Approve token:${token.name} spender: ${spender} network: ${token.network} Failed`, e);
    return null;
  }
};

export const deposit = async (
  signer: ethers.Signer,
  token: Token,
  amount: string,
  contractAddress: string,
): Promise<TransactionResponse> => {
  const nullAddress = '0x0000000000000000000000000000000000000000';
  const contract = getHunnyPokerContract(contractAddress, signer);
  const decimalAmount = getDecimalAmount(new BigNumber(amount), token.decimals);

  try {
    const tx: TransactionResponse = await contract.deposit(
      token.isNative ? nullAddress : token.address,
      decimalAmount.toString(10),
      {
        value: token.isNative ? decimalAmount.toString(10) : '0',
      },
    );

    return tx;
  } catch (e) {
    logError(`Deposit token:${token.name} network: ${token.network} Failed`, e);
    return null;
  }
};

export const estimateDepositFee = async (token: Token, amount: string, contractAddress: string) => {
  if (token.isNative && isSolToken(token)) {
    return new BigNumber('0.00005');
  }

  const provider = getSimplerRpcProvider(token.network);
  const nullAddress = '0x0000000000000000000000000000000000000000';
  const contract = getHunnyPokerContract(contractAddress, provider);
  const decimalAmount = getDecimalAmount(new BigNumber(amount), token.decimals);

  try {
    const [gasPrice, gasUnits] = await forkjoinRequest([
      getSimplerRpcProvider(token.network).getGasPrice(),
      contract.estimateGas.deposit(token.isNative ? nullAddress : token.address, decimalAmount.toString(10), {
        value: token.isNative ? decimalAmount.toString(10) : '0',
      }),
    ]);

    const transactionFee = gasPrice.mul(gasUnits);
    return new BigNumber(ethers.utils.formatUnits(transactionFee, 'ether').toString());
  } catch (e) {
    logError(`estimateDepositFee token:${token.name} network: ${token.network} Failed`, e);
    return null;
  }
};
