import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Button from 'components/Button';
import Heading from 'components/Heading';
import ModalBody from 'components/Modal/components/ModalBody';
import Modal, { ModalProps } from 'components/Modal/Modal';
import Text from 'components/Text';
import useDetectKeyboardOpen from 'hooks/useDetectKeyboardOpen';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from 'state/auth/hooks';
import styled from 'styled-components';
import { AuthModalMethodEnums, AuthModalPageEnums } from 'config/types/authentication';
import { Introduction } from './components/Introduction';
import ReferralCode from './components/ReferralCode';
import SwitchMethod from './components/SwitchMethod';
import SwitchPage from './components/SwitchPage';
import { Template, Content, Footer, Header } from './components/Template';
import TraditionalAuth from './components/Traditional';
import WalletAuth from './components/Wallet';

export interface AuthModalProps {
  page?: AuthModalPageEnums;
  method?: AuthModalMethodEnums;
}
const Authentication: React.FC<ModalProps<AuthModalProps>> = ({ onDismiss, data: { page, method } }) => {
  const { t } = useTranslation();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { isSigned } = useAuth();

  const [currentPage, setCurrentPage] = useState(page || AuthModalPageEnums.LOG_IN);
  const [currentMethod, setCurrentMethod] = useState(method || AuthModalMethodEnums.WEB3);
  const [isShowMoreWallet, setIsShowMoreWallet] = useState<boolean>(false);

  const isLoginPage = currentPage === AuthModalPageEnums.LOG_IN;
  const title = t('Welcome to our community');

  useEffect(() => {
    if (isSigned) {
      onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned]);

  return (
    <Container id="popup-authen" onDismiss={onDismiss}>
      <ModalBody height="100%">
        <Flex height="100%">
          <Introduction title={title} />

          <MainContent>
            <Template>
              <Header>
                <Heading scale="md">{isLoginPage ? <Trans>Log in</Trans> : <Trans>Sign up</Trans>}</Heading>
                <Button id="button-cancel" variant="text" display={['flex', 'flex', 'none']} onClick={onDismiss}>
                  <Text color="textSubtle" fontSize="14px">
                    <Trans>Cancel</Trans>
                  </Text>
                </Button>
              </Header>

              <StyledContent>
                {!isShowMoreWallet && (
                  <SwitchMethod mb="24px" method={currentMethod} onMethodChange={setCurrentMethod} />
                )}
                {!(currentPage === AuthModalPageEnums.LOG_IN && currentMethod === AuthModalMethodEnums.TRADITIONAL) && (
                  <ReferralCode mb="20px" />
                )}

                {currentMethod === AuthModalMethodEnums.WEB3 && (
                  <WalletAuth page={currentPage} isShowMore={isShowMoreWallet} setShowMore={setIsShowMoreWallet} />
                )}

                <TraditionalAuth
                  page={currentPage}
                  hide={currentMethod !== AuthModalMethodEnums.TRADITIONAL}
                  setPage={setCurrentPage}
                  onClose={onDismiss}
                />
              </StyledContent>
              {!isKeyboardOpen && !isShowMoreWallet && (
                <StyledFooter>
                  <SwitchPage presentedPage={currentPage} onSwitchPage={setCurrentPage} />
                </StyledFooter>
              )}
            </Template>
          </MainContent>
        </Flex>
      </ModalBody>
    </Container>
  );
};

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  flex: 1 1;
  overflow: overlay;
`;

const StyledFooter = styled(Footer)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Container = styled(Modal)`
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0px;
  padding: 0;
  width: 100%;
  height: var(--screen-height);

  max-width: unset !important;

  .modal-closebutton {
    pointer-events: none;
    display: none;
    top: 24px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    max-height: 715px !important;
    min-height: 715px !important;
    max-width: 512px !important;
    border-radius: ${({ theme }) => theme.radii.extraLarge};

    .modal-closebutton {
      pointer-events: auto;
      display: flex;
      z-index: 1;
    }
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    min-width: 800px;
  }
`;

const MainContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin-left: 0;
  box-sizing: border-box;
  position: relative;
  overflow: auto;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    max-width: 57%;
    min-width: 57%;

    overflow: hidden;
    margin-left: -15px;
    border-radius: ${({ theme }) => theme.radii.extraLarge};
    background: ${({ theme }) => theme.colors.modalBackground};
  }
`;

export default Authentication;
