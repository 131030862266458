import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
// import { clusterApiUrl } from '@solana/web3.js'
// import { web3 } from '@project-serum/anchor'
// INTERGRATE SOLANA

// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

export class SimpleRpcProvider {
  private static _simpleRpcProviderMap = {};

  public static get(chainId: ChainIdEnum) {
    if (!this._simpleRpcProviderMap[chainId]) {
      // if (chainId === ChainIdEnum.SOL_TESTNET || chainId === ChainIdEnum.SOL) {
      //   const endpoint =
      //     chainId === ChainIdEnum.SOL_TESTNET
      //       ? clusterApiUrl(WalletAdapterNetwork.Testnet)
      //       : 'https://solana-mainnet.g.alchemy.com/v2/JH7TEGB_ecM-SpBFtuimdmH2MrfC5-Tg'
      //   this._simpleRpcProviderMap[chainId] = new web3.Connection(endpoint)
      // } else {

      const rpcCollections = NETWORK_MAP[chainId]?.rpcCollections || [];
      const selectedRpcUrl = getRandomRpcFromList(rpcCollections);
      this._simpleRpcProviderMap[chainId] = new StaticJsonRpcProvider(selectedRpcUrl);
      // }
    }

    return this._simpleRpcProviderMap[chainId];
  }
}

export const getRandomRpcFromList = (rpcCollections: string[]) => {
  if (rpcCollections.length) {
    const randomRpc = rpcCollections[Math.floor(Math.random() * 3)];

    if (randomRpc) return randomRpc;
    return rpcCollections[0];
  }

  return '';
};

export const getSimplerRpcProvider = (chainId: ChainIdEnum) => {
  return SimpleRpcProvider.get(chainId);
};
