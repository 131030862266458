import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Text from 'components/Text';
import Box from 'components/Box/Box';
import useForm, { FieldState } from 'hooks/useForm';
import FormValidator, { commomErrorMessage } from 'config/constants/formValidator';
import FormInput from 'components/FormControl/FormInput';
import FormControl from 'components/FormControl';
import FormInputPassword from 'components/FormControl/FormInputPassword';
import { Icons } from 'svgs';
import { ValidationError } from 'config/types/validator';
import Button from 'components/Button';
import OpenEffect from 'components/OpenEffect';
import styled from 'styled-components';
import { InputContainer } from 'components/Input/styled';
import useCallbackValueOnDestroy from 'hooks/useCallbackValueOnDestroy';
import useModal from 'hooks/useModal';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import useDebounceCallback from 'hooks/useDebounceCallback';
import CircleLoader from 'components/Loader/CircleLoader';
import { AuthModalPageEnums } from 'config/types/authentication';
import PasswordRecoveryModal from './modals/PasswordRecoveryModal';
import { TraditionalFormProps } from './TraditionalSignUpForm';
import { useSignInWithEmail } from './hooks';

export const loginFormErrorMessages = {
  email: {
    [ValidationError.Email]: commomErrorMessage.Email,
    [ValidationError.Required]: 'Please enter your email',
  },
  password: {
    [ValidationError.Required]: 'Please enter your password',
    [ValidationError.WrongPassword]: 'Incorrect password',
  },
};

const TraditionalLoginForm: React.FC<TraditionalFormProps> = ({ initialEmail, saveEmail, setPage }) => {
  const { t } = useTranslation();
  const signIn = useSignInWithEmail();
  const debounceCallback = useDebounceCallback();

  const [submiting, setSubmiting] = useState(false);
  const [handlePresentPasswordRecoveryModal] = useModal(PasswordRecoveryModal);

  const { states, controls, validateAll, isValid } = useForm({
    email: {
      validators: [FormValidator.required, FormValidator.email],
      value: '',
    },
    password: {
      validators: [FormValidator.required],
      value: '',
    },
  });

  useIsomorphicEffect(() => {
    debounceCallback(() => {
      controls.email.onValueChanged(initialEmail);
    }, 150);
  }, [initialEmail]);

  useCallbackValueOnDestroy(states.email.value, saveEmail);

  const formatEmailErrorMessage = (errors: ValidationError[]) => {
    if (errors[0] === ValidationError.EmailNotExist) {
      return (
        <Trans>
          This email does not exist. Please enter a different email or{' '}
          <Text
            color="primary"
            display="inline"
            fontSize="12px"
            bold
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPage(AuthModalPageEnums.SIGN_UP);
            }}
          >
            Sign up
          </Text>{' '}
          new account.
        </Trans>
      );
    }

    return t(loginFormErrorMessages.email[errors[0]]);
  };

  const handleSignIn = async () => {
    setSubmiting(true);

    const isValid = await validateAll();
    if (!isValid) {
      setSubmiting(false);
      return;
    }

    const data = await signIn(states.password.value, states.email.value);

    setSubmiting(false);
  };

  return (
    <OpenEffect openType="slideBottomToTop" duration={0.5}>
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
      >
        <StyledFormControl state={states.email} label={t('Email')} formatErrorMessage={formatEmailErrorMessage}>
          <FormInput
            control={controls.email}
            placeholder={t('Email')}
            type="email"
            name="email"
            icon={<Icons.EmailIcon />}
          />
        </StyledFormControl>

        <StyledFormControl
          mt="12px"
          state={states.password}
          label={t('Password')}
          formatErrorMessage={(errors) => t(loginFormErrorMessages.password[errors[0]])}
        >
          <FormInputPassword control={controls.password} placeholder={t('Password')} icon={<Icons.PasswordIcon />} />
        </StyledFormControl>

        <StyledForgotPasswordText
          fontSize="12px"
          color="primary"
          mt="8px"
          id="forgotpassword-text"
          onClick={() => {
            handlePresentPasswordRecoveryModal({
              email: states.email.value,
              switchSignUpPage: (draftEmail) => {
                controls.email.onValueChanged(draftEmail);
                setTimeout(() => {
                  setPage(AuthModalPageEnums.SIGN_UP);
                });
              },
            });
          }}
        >
          <Trans>Forgot Password?</Trans>
        </StyledForgotPasswordText>

        <Button
          type="submit"
          disabled={!isValid || submiting}
          width="100%"
          mt="24px"
          onClick={handleSignIn}
          id="login-submit-button"
        >
          {submiting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
            <Trans>Log in</Trans>
          </Text>
        </Button>
      </Box>
    </OpenEffect>
  );
};

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty
      ? `
     ${InputContainer} {
        background-color: transparent !important;
        border-color: ${theme.colors.strokeAlt} !important;
      }
    `
      : ''}
`;

const StyledForgotPasswordText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

export default TraditionalLoginForm;
