import { escapeRegExp, inputRegex } from 'utils/stringHelper';
import React from 'react';
import { InputProps } from '.';
import { StyledInput } from './styled';

const NumericalInput: React.FC<InputProps & { placeholder: string; tabIndex: number }> = ({
  value,
  onValueChanged,
  ...props
}) => {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onValueChanged(nextUserInput);
    }
  };

  return (
    <StyledInput
      {...props}
      value={value}
      inputMode="decimal"
      type="text"
      minLength={1}
      maxLength={48}
      pattern="^[0-9]*[.,]?[0-9]*$"
      autoFocus
      onValueChanged={(value) => {
        // replace commas with periods, because we exclusively uses period as the decimal separator
        enforcer(value.replace(/,/g, '.'));
      }}
    />
  );
};

export default NumericalInput;
