import { DepositBonus, WageringConditionBonus } from 'config/types/bonus/userBonus';
import { useEffect, useRef, useState } from 'react';
import BonusService from 'services/BonusService';
import { useAppDispatch } from 'state';
import { useAuth } from 'state/auth/hooks';
import { updatePreparedBonusId } from 'state/bonus/actions';
import { useUserBonus } from 'state/bonus/hooks';

export const emptyBonus = { id: 0 } as any;

export const useWaitingDepositToActiveBonus = () => {
  const [bonuses, setBonuses] = useState<WageringConditionBonus[]>([]);
  const [selectedBonus, setSelectedBonus] = useState<WageringConditionBonus>(emptyBonus);
  const [isSelectedBonusExpired, setIsSelectedBonusExpired] = useState(false);
  const { preparedBonusId } = useUserBonus();

  const dispatch = useAppDispatch();

  const currentSelectedBonusRef = useRef(emptyBonus);
  const { hasSession } = useAuth();

  useEffect(() => {
    const fetch = async () => {
      const response = await BonusService.getUserBonuses().call();
      const bonuses = response?.data?.items || [];

      const filterBonusDeposit = bonuses.filter((bonus) => bonus instanceof DepositBonus) as DepositBonus[];

      if (filterBonusDeposit.length > 0) setBonuses([emptyBonus, ...filterBonusDeposit]);
      else setBonuses([]);

      const selectedBonus = filterBonusDeposit.find((bonus) => bonus.id === preparedBonusId);
      currentSelectedBonusRef.current = selectedBonus || emptyBonus;
      setSelectedBonus(selectedBonus || emptyBonus);
    };
    if (hasSession) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession]);

  useEffect(() => {
    if (!selectedBonus.id) {
      setIsSelectedBonusExpired(false);
      return;
    }

    setIsSelectedBonusExpired(selectedBonus.condition.isExpired);
    const interval = setInterval(() => setIsSelectedBonusExpired(selectedBonus.condition.isExpired), 1000);

    return () => clearInterval(interval);
  }, [selectedBonus]);

  const handleSelectedBonus = async (bonus: WageringConditionBonus): Promise<boolean> => {
    if (bonus.id === selectedBonus.id) return false;

    setSelectedBonus(bonus);
    const isSuccess = await BonusService.prepareDepositBonus(bonus.id);
    if (isSuccess) {
      currentSelectedBonusRef.current = bonus;
      dispatch(updatePreparedBonusId(bonus.id));
    } else {
      setSelectedBonus(currentSelectedBonusRef.current);
    }

    return isSuccess;
  };

  return { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired };
};
