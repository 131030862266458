/* eslint-disable class-methods-use-this */
import { APIEndpointEnum, APP_SITE, SOCKET_API } from 'config/constants/server';
import { ManagerOptions, SocketOptions } from 'socket.io-client';
import { BaseSocket } from './base';

class PrivateSocket extends BaseSocket {
  constructor() {
    super(SOCKET_API);
  }

  protected _buildConfig(): Partial<ManagerOptions & SocketOptions> {
    const config: any = {
      path: APIEndpointEnum.PrivateSocket,
      transports: ['websocket'],
    };

    if (APP_SITE !== SOCKET_API) {
      config.withCredentials = true;
    }
    return config;
  }
}

const privateSocket = new PrivateSocket();
export default privateSocket;
