import { Token } from 'config/types';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'state';
import { estimateDepositFee } from 'utils/infura';

function useDepositFee(token: Token) {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes);
  const tokenDepositInfo = useMemo(() => {
    if (!token) return null;

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress;

    if (!spender) return null;

    return {
      spender,
      token,
    };
  }, [token, tokenInfoes]);

  const estimateFee = useCallback(async () => {
    const fee = await estimateDepositFee(tokenDepositInfo.token, '1', tokenDepositInfo.spender);
    return fee;
  }, [tokenDepositInfo]);

  return estimateFee;
}

export default useDepositFee;
