/* eslint-disable max-len */
import PendingTransaction from 'components/TransactionToast/PendingTransaction';
import CompletedTransaction from 'components/TransactionToast/CompletedTransaction';
import { Transaction, TransactionStatusEnum, TransactionTypeEnum } from 'config/types/transaction';
import { usePrivateSocket } from 'hooks/usePrivateSocket';
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { parseDepositTransaction, parseWithdrawalTransaction } from 'services/mapper/utils';
import PaymentService from 'services/PaymentService';
import { useAppDispatch, useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import { HunnyToast, showToast } from 'utils/toastify';
import { combineDepositHash, getDepositHashInfo } from 'utils/transaction';
import tokens from 'config/constants/tokens';

import { TransactionResponse } from '@ethersproject/providers';
import { Token } from 'config/types';
import { useHandleFetchUserBalance } from 'state/profile/hooks';
import {
  addPendingDepositTransaction,
  addPendingWithdrawTransaction,
  clearPendingTransaction,
  removePendingDepositTransaction,
  removePendingWithdrawTransaction,
  updateDepositPendingTransaction,
  updateWithdrawPendingTransaction,
} from './action';

export const usePollPendingTransaction = () => {
  const dispatch = useAppDispatch();
  const { isSigned, hasSession } = useAuth();
  const handleFetchUserBalance = useHandleFetchUserBalance();
  const toastIdsRef = useRef([]);
  const socket = usePrivateSocket();

  const pendingWithdrawTransactionCode: Transaction[] = useAppSelector(
    (state) => state.transaction.pendingWithdrawTransactionCode || [],
  );
  const pendingDepositTransactionHash: Transaction[] = useAppSelector(
    (state) => state.transaction.pendingDepositTransactionHash,
  );

  useEffect(() => {
    if (!isSigned) {
      if (!hasSession && (pendingWithdrawTransactionCode.length || pendingDepositTransactionHash.length)) {
        dispatch(clearPendingTransaction());
      }
    }
  }, [pendingWithdrawTransactionCode, pendingDepositTransactionHash, isSigned, hasSession, dispatch]);

  // TODO review it
  useEffect(() => {
    if (!isSigned) return;
    const newPendingWithdrawCodes = pendingWithdrawTransactionCode.filter(
      (code) => !toastIdsRef.current.includes(code),
    );

    if (newPendingWithdrawCodes.length) {
      newPendingWithdrawCodes.forEach(async (transaction) => {
        const { txnHash } = transaction;

        const response = await PaymentService.getWithdrawal(txnHash).call();
        if (response?.data && response.data.status === TransactionStatusEnum.Pending) {
          handleFetchUserBalance();
          showToast(
            <PendingTransaction
              transaction={{
                ...response.data,
                fee: '0',
              }}
            />,
            {
              autoClose: false,
              toastId: txnHash,
              onClose: () => {
                dispatch(removePendingWithdrawTransaction({ code: txnHash }));
              },
            },
          );
        } else if (response?.data?.status === TransactionStatusEnum.Succeeded) {
          handleFetchUserBalance();

          dispatch(removePendingWithdrawTransaction({ code: txnHash }));
        }
      });

      toastIdsRef.current = [...toastIdsRef.current, ...newPendingWithdrawCodes];
    }
  }, [pendingWithdrawTransactionCode, isSigned, handleFetchUserBalance, dispatch]);

  // TODO review it
  useEffect(() => {
    if (!isSigned) return;

    const newPendingDepositHashs = pendingDepositTransactionHash.filter((hash) => !toastIdsRef.current.includes(hash));

    if (newPendingDepositHashs.length) {
      newPendingDepositHashs.forEach(async (transaction: Transaction) => {
        const { txnHash } = transaction;
        if (!txnHash) return;
        const { hash } = getDepositHashInfo(txnHash);

        const response = await PaymentService.getDeposit(hash).call();
        if (response && !response.data) {
          handleFetchUserBalance();
          showToast(<PendingTransaction transaction={transaction} />, {
            autoClose: false,
            toastId: txnHash,
            onClose: () => {
              dispatch(removePendingDepositTransaction({ hash: txnHash }));
            },
          });
        } else if (response?.data?.status === TransactionStatusEnum.Succeeded) {
          handleFetchUserBalance();
          dispatch(removePendingDepositTransaction({ hash: txnHash }));
        }
      });
      toastIdsRef.current = [...toastIdsRef.current, ...newPendingDepositHashs];
    }
  }, [pendingDepositTransactionHash, isSigned, handleFetchUserBalance, dispatch]);

  useEffect(() => {
    const removedPendingTxnCodes = toastIdsRef.current.filter((code) => !pendingWithdrawTransactionCode.includes(code));

    if (removedPendingTxnCodes.length) {
      removedPendingTxnCodes.forEach((code) => {
        HunnyToast.dismiss(code.txnHash);
      });
    }
  }, [pendingWithdrawTransactionCode]);

  useEffect(() => {
    const removedPendingDepositHash = toastIdsRef.current.filter(
      (code) => !pendingDepositTransactionHash.includes(code),
    );

    if (removedPendingDepositHash.length) {
      removedPendingDepositHash.forEach((hash) => {
        HunnyToast.dismiss(hash.txnHash);
      });
    }
  }, [pendingDepositTransactionHash]);

  useEffect(() => {
    if (!isSigned || !socket) {
      return;
    }
    socket.on('Withdrawal', (data) => {
      if (data) {
        const transaction = parseWithdrawalTransaction(data);

        dispatch(removePendingWithdrawTransaction({ code: transaction.id.toString() }));
        handleFetchUserBalance();
        showToast(<CompletedTransaction transaction={transaction} />, {}, 'success');
      }
    });

    return () => {
      if (socket) {
        socket.off('Withdrawal');
      }
    };
  }, [dispatch, handleFetchUserBalance, isSigned, socket]);

  useEffect(() => {
    if (!isSigned || !socket) {
      return;
    }

    socket.on('Deposit', (data) => {
      if (data) {
        const transaction = parseDepositTransaction(data);
        const token = tokens[transaction.network][transaction.currency];
        dispatch(removePendingDepositTransaction({ hash: combineDepositHash(transaction.txnHash, token) }));
        handleFetchUserBalance();
        showToast(<CompletedTransaction transaction={transaction} />, {}, 'success');
      }
    });

    return () => {
      if (socket) {
        socket.off('Deposit');
      }
    };
  }, [dispatch, handleFetchUserBalance, isSigned, socket]);
};

export const useAddDepositTransaction = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (transaction: TransactionResponse, token: Token, amount: string) => {
      dispatch(
        addPendingDepositTransaction({
          id: transaction.blockNumber,
          status: TransactionStatusEnum.Pending,
          currency: token.code,
          txnHash: combineDepositHash(transaction.hash, token),
          value: amount,
          createTime: Date.now(),
          network: token.network,
          type: TransactionTypeEnum.Deposit,
          fee: '0',
        }),
      );
    },
    [dispatch],
  );
};

export const useAddWithdrawTransaction = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (txnCode: string, token: Token, amount: string, fee: string) => {
      dispatch(
        addPendingWithdrawTransaction({
          id: Date.now(),
          status: TransactionStatusEnum.Pending,
          currency: token.code,
          txnHash: txnCode,
          value: amount,
          createTime: Date.now(),
          network: token.network,
          type: TransactionTypeEnum.Withdraw,
          fee,
        }),
      );
    },
    [dispatch],
  );
};

export const useTransactionHashDeposit = () => {
  const pendingDepositTransactionHash: Transaction[] = useAppSelector(
    (state) => state.transaction.pendingDepositTransactionHash,
  );

  const dispatch = useAppDispatch();
  const handleFetchUserBalance = useHandleFetchUserBalance();
  const refFetching = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      refFetching.current = true;
      await Promise.all(
        pendingDepositTransactionHash.map(async (transaction: Transaction) => {
          const txn = { ...transaction };
          const { txnHash, status } = txn;

          if (!txnHash || status === TransactionStatusEnum.Succeeded) return txn;

          const { hash } = getDepositHashInfo(txnHash);

          const response = await PaymentService.getDeposit(hash).call();
          if (response?.data?.status === TransactionStatusEnum.Succeeded) {
            txn.status = TransactionStatusEnum.Succeeded;
            handleFetchUserBalance();
            dispatch(updateDepositPendingTransaction(txn));
          } else {
            txn.status = TransactionStatusEnum.Pending;
          }

          return txn as Transaction;
        }),
      );
      refFetching.current = false;
    };

    const pendingHash = pendingDepositTransactionHash.filter((txn) => txn.status !== TransactionStatusEnum.Succeeded);
    let intervalPending = null;
    if (pendingHash.length && !refFetching.current) {
      fetch();
      intervalPending = setInterval(fetch, 5000);
    } else {
      clearInterval(intervalPending);
    }
    return () => {
      clearInterval(intervalPending);
    };
  }, [dispatch, handleFetchUserBalance, pendingDepositTransactionHash]);

  return useMemo(
    () => ({
      transaction: pendingDepositTransactionHash,
    }),
    [pendingDepositTransactionHash],
  );
};

export const useTransactionCodeWithdraw = () => {
  const pendingWithdrawTransactionCode: Transaction[] = useAppSelector(
    (state) => state.transaction.pendingWithdrawTransactionCode,
  );

  const dispatch = useAppDispatch();
  const handleFetchUserBalance = useHandleFetchUserBalance();
  const refFetching = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      refFetching.current = true;
      await Promise.all(
        pendingWithdrawTransactionCode.map(async (transaction: Transaction) => {
          const txn = { ...transaction };
          const { txnHash, status } = txn;

          if (!txnHash || status === TransactionStatusEnum.Succeeded) return txn;

          const response = await PaymentService.getWithdrawal(txnHash).call();
          if (response?.data?.status === TransactionStatusEnum.Succeeded) {
            txn.status = TransactionStatusEnum.Succeeded;
            handleFetchUserBalance();
            dispatch(updateWithdrawPendingTransaction(txn));
          } else {
            txn.status = TransactionStatusEnum.Pending;
          }

          return txn as Transaction;
        }),
      );
      refFetching.current = false;
    };

    const pendingHash = pendingWithdrawTransactionCode.filter((txn) => txn.status !== TransactionStatusEnum.Succeeded);
    let intervalPending = null;
    if (pendingHash.length && !refFetching.current) {
      fetch();
      intervalPending = setInterval(fetch, 5000);
    } else {
      clearInterval(intervalPending);
    }
    return () => {
      clearInterval(intervalPending);
    };
  }, [dispatch, handleFetchUserBalance, pendingWithdrawTransactionCode]);

  return useMemo(
    () => ({
      transaction: pendingWithdrawTransactionCode,
    }),
    [pendingWithdrawTransactionCode],
  );
};
