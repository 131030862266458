import Box from 'components/Box/Box';
import Text from 'components/Text';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import Input from '.';

export const InputLabel = styled(Text).attrs({ as: 'label' })`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  transition: 0.2s all;
`;
export const InputMessage = styled(Text)`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  display: block;
`;

export const InputContainer = styled(Box)<{ $disabled?: boolean }>`
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: ${({ theme }) => theme.radii.small};
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:has(input:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.stroke} !important;

    input {
      cursor: not-allowed !important;
    }
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.stroke} !important;

          input {
            cursor: not-allowed !important;
          }
        `
      : ''}

  &:has(input:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.stroke} !important;

    input {
      cursor: not-allowed !important;
    }
  }

  &:focus-within,
  &:hover {
    border-color: ${({ theme }) => theme.colors.strokeAlt};
  }
`;

export const StyledInput = styled(Input)`
  background-color: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.radii.small};
  width: 100%;

  color: ${({ theme }) => theme.colors.textTertiary};
  font-size: 14px;
  font-weight: 400;

  background-clip: content-box;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.textSubtle}aa;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    -webkit-background-clip: text !important;
    opacity: 1;
    caret-color: ${({ theme }) => theme.colors.text};
  }

  ${space}
`;
