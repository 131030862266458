import { LoginMethod, TokenKeyEnum } from 'config/constants/auth';
import { ActionQueryEnum } from 'hooks/useActionQueryListener';
import { useRouter } from 'hooks/useRouter';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticationService from 'services/AuthenticationService';
import { ApiStatusCode } from 'services/types';
import { useAppDispatch, useAppSelector } from 'state';
import { setIsSigned, updateDeviceUid } from 'state/app/actions';
import { getID } from 'utils/fingerprint';
import { logError } from 'utils/sentry';
import { HunnyToast } from 'utils/toastify';
import { logout, updateAuthToken } from './action';

export const useLogout = () => {
  const deviceUid = useAppSelector((state) => state.app.deviceUid);
  const clientLogout = useClientLogout();
  return useCallback(async () => {
    const isLogout = await AuthenticationService.logout(deviceUid);
    if (isLogout?.code === ApiStatusCode.Success) {
      clientLogout();
    }
  }, [clientLogout, deviceUid]);
};

export const useClientLogout = () => {
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    localStorage.removeItem(TokenKeyEnum.AccessToken);
    localStorage.removeItem(TokenKeyEnum.RefreshToken);
    localStorage.removeItem(TokenKeyEnum.UserInfo);
    await dispatch(logout());
    await dispatch(setIsSigned({ isSigned: false }));
  }, [dispatch]);
};

export const useAuthRefresh = () => {
  const dispatch = useAppDispatch();
  const deviceUid = useAppSelector((state) => state.app.deviceUid);
  const { loginBy, address } = useUserInfo();

  const logout = useClientLogout();
  const router = useRouter();

  const email = useAppSelector((state) => state.auth.email);
  const wallet = useAppSelector((state) => state.auth.wallet);

  const { t } = useTranslation();

  const refresh = async () => {
    if (!address) return;

    let _deviceUid = deviceUid;

    if (!_deviceUid) {
      _deviceUid = await getID();
    }
    const result = await AuthenticationService.refresh(address, _deviceUid);

    if (!result || result.code === 'network_error') {
      // TODO handle network error
      return logout();
    }
    if (!result.data?.accessToken || !(loginBy === LoginMethod.Wallet ? wallet : email)) {
      const msg = 'Your session expired. Please login again';
      logError(msg, {
        message: msg,
        tags: ['session_expired'],
        extra: {
          errorCode: result?.code,
          isLoginByWallet: loginBy === LoginMethod.Wallet,
          wallet,
          email,
          deviceUid,
          _deviceUid,
        },
      });

      HunnyToast.warn(t(msg));
      await logout();
      router.push({
        ...router,
        query: {
          ...router.query,
          action: loginBy === LoginMethod.Wallet ? ActionQueryEnum.Login : ActionQueryEnum.LoginEmail,
        },
      });
    } else {
      if (!deviceUid) {
        dispatch(updateDeviceUid({ deviceUid: _deviceUid }));
      }
      if (result?.data?.accessToken) {
        dispatch(
          updateAuthToken({
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
          }),
        );
        dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }));

        return true;
      }
    }

    return false;
  };

  return refresh;
};

export const useAuth = () => {
  const { loginBy, uid, username, hasSession, accessToken, refreshToken } = useUserInfo();
  const signedAtTime = useAppSelector((state) => state.app.signedAtTime);
  const isSigned = useAppSelector((state) => state.app.isSigned);

  return useMemo(
    () => ({ isSigned, username, uid, loginBy, hasSession, signedAtTime, accessToken, refreshToken }),
    [isSigned, username, uid, loginBy, hasSession, signedAtTime, accessToken, refreshToken],
  );
};

export const useUserInfo = () => {
  const loginBy = useAppSelector((state) => state.auth.loginBy);
  const uid = useAppSelector((state) => state.auth.uid);
  const username = useAppSelector((state) => state.auth.username);
  const address = useAppSelector((state) => state.auth.address);
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);

  return useMemo(
    () => ({ username, uid, loginBy, address, hasSession: username && uid && address, accessToken, refreshToken }),
    [username, uid, loginBy, address, accessToken, refreshToken],
  );
};
