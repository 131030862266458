/* eslint-disable react/default-props-match-prop-types */
import { NextLinkFromReactRouter } from 'components/NextLink';
import React from 'react';
import styled from 'styled-components';
import getExternalLinkProps from 'utils/getExternalLinkProps';
import Text from '../Text';
import { LinkProps } from './types';

const StyledLink = styled(Text)`
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const Link: React.FC<LinkProps> = ({ external, href, children, target, title, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};

  return external ? (
    <a title={title} href={href} target={target} {...internalProps}>
      <StyledLink {...props}>{children}</StyledLink>
    </a>
  ) : (
    <NextLinkFromReactRouter title={title} to={href} {...internalProps}>
      <StyledLink {...props}>{children}</StyledLink>
    </NextLinkFromReactRouter>
  );
};

Link.defaultProps = {
  color: 'text',
};

export default Link;
