import { useRouter } from 'hooks/useRouter';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import Authentication from 'views/Authentication';
import Payment, { PaymentPageEnums } from 'views/Payment';
import { AuthModalMethodEnums, AuthModalPageEnums } from 'config/types/authentication';
import { useIsomorphicEffect } from './useIsomorphicEffect';
import useModal from './useModal';
import useQueryParam from './useQueryParam';

export enum ActionQueryEnum {
  Login = 'onLogin',
  LoginEmail = 'onEmailLogin',
  SignUp = 'onSignUp',
  UnlockHUSD = 'unlockHUSD',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  OpenLuckySpin = 'openLuckySpin',
}

export const useActionQueryHandler = () => {
  const [onPresentAuthModal] = useModal(Authentication);
  const [onPressentModalPaymnet] = useModal(Payment);

  const { isSigned, hasSession } = useAuth();
  const actions = useMemo(
    () => ({
      [ActionQueryEnum.Login]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.LoginEmail]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN, method: AuthModalMethodEnums.TRADITIONAL }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.Deposit]: {
        call: () => onPressentModalPaymnet(),
        condition: isSigned,
        fallback: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
      },
      [ActionQueryEnum.Withdraw]: {
        call: () => onPressentModalPaymnet({ defaultPage: PaymentPageEnums.Withdraw }),
        condition: isSigned,
        fallback: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
      },
      [ActionQueryEnum.SignUp]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.SIGN_UP }),
        condition: !isSigned && !hasSession,
      },
    }),
    [isSigned, hasSession, onPresentAuthModal, onPressentModalPaymnet],
  );

  const handle = useCallback(
    (action: ActionQueryEnum) => {
      if (!action || isSigned === null) return;
      const selectedAction = actions[action.toString()];
      if (!selectedAction || selectedAction.condition === false) {
        if (selectedAction?.fallback) {
          selectedAction.fallback();
        }
      } else selectedAction.call();
    },
    [actions, isSigned],
  );

  return handle;
};

const useActionQueryListener = () => {
  const router = useRouter();
  const { removeParam } = useQueryParam();

  const handleAction = useActionQueryHandler();

  const isLayoutRendered = useAppSelector((state) => state.app.isLayoutRendered);

  useIsomorphicEffect(() => {
    const { action } = router.query;
    if (!action || !isLayoutRendered) return;
    handleAction(action as ActionQueryEnum);
    removeParam('action');
  }, [router.query.action, handleAction, isLayoutRendered]);
};

export default useActionQueryListener;
