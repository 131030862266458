import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import styled from 'styled-components';
import React from 'react';

const TableHeader: React.FC<BoxProps> = ({ children, width, ...props }) => (
  <StyledTh width={width}>
    <StyledHeader {...props}>{children}</StyledHeader>
  </StyledTh>
);

const StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.normal};
`;
const StyledTh = styled.th<{ width: any }>`
  width: ${({ width }) => width};

  &:first-child ${StyledHeader} {
    border-top-left-radius: ${({ theme: { radii } }) => radii.default};
  }

  &:last-child ${StyledHeader} {
    border-top-right-radius: ${({ theme: { radii } }) => radii.default};
  }
`;

export default styled(TableHeader)``;
