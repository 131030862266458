import Box from 'components/Box/Box';
import Text from 'components/Text';
import { AuthModalPageEnums } from 'config/types/authentication';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface SwitchPageProps {
  presentedPage: AuthModalPageEnums;
  onSwitchPage: (nextPage: AuthModalPageEnums) => void;
}
const SwitchPage: React.FC<SwitchPageProps> = ({ presentedPage, onSwitchPage }) => {
  const { t } = useTranslation();
  const dataMapping = {
    [AuthModalPageEnums.LOG_IN]: {
      // eslint-disable-next-line quotes, @typescript-eslint/quotes
      title: t("Don't have an account?"),
      onClick: () => onSwitchPage(AuthModalPageEnums.SIGN_UP),
      buttonTitle: t('Sign up'),
    },
    [AuthModalPageEnums.SIGN_UP]: {
      title: t('Already have an account?'),
      onClick: () => onSwitchPage(AuthModalPageEnums.LOG_IN),
      buttonTitle: t('Log in'),
    },
  };

  return (
    <Container>
      <Text fontSize={['14px']}>
        <Trans>{dataMapping[presentedPage].title}</Trans>
      </Text>

      <Content
        id={presentedPage === AuthModalPageEnums.LOG_IN ? 'footer-signup-text' : 'footer-login-text'}
        onClick={dataMapping[presentedPage].onClick}
        ml="5px"
      >
        <StyledButtonTitle>{dataMapping[presentedPage].buttonTitle}</StyledButtonTitle>
      </Content>
    </Container>
  );
};

const StyledButtonTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  font-size: 14px;
`;

const Container = styled(Box)`
  display: flex;
  word-break: break-all;
  white-space: pre;
`;

const Content = styled(Box)`
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export default SwitchPage;
