import {
  BaseResponse,
  DeepLinkResponse,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessageResponse,
  VerificationResponse,
} from 'services/types';
import { getErrorCodeByMessage } from './utils';

export const PrepareSignMessageMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);
  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      acceptMessageText: response.data.message,
      acceptMessage: response.data.message,
    },
  };
};
export const RegisterMapper = (rawResponse: string): BaseResponse<LoginResponse> => {
  const response = JSON.parse(rawResponse);
  return {
    code: response.code,
    message: response.message,
    data: response?.data?.accessToken && {
      accessToken: response?.data.accessToken,
      refreshToken: response?.data.refreshToken,
    },
  };
};

export const DeepLinkMapper = (rawResponse: string): BaseResponse<DeepLinkResponse> => {
  const response = JSON.parse(rawResponse);
  return {
    code: response.code,
    message: response.message,
    data: {
      token: response?.data?.token || '',
    },
  };
};

export const LoginResponseMapper = (rawResponse: string): BaseResponse<LoginResponse> => {
  const response: any = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response?.data && {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      uid: response.data.uid || null,
    },
  };
};

export const PasswordNonceResponseMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  return {
    code: response.code,
    message: response.message,
    errors: [getErrorCodeByMessage(response)],
    data: response.data && {
      ...parseDataNonce(response.data),
    },
  };
};

export const VerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: parseInt(response.data?.expireTime || 0, 10),
      nextResendTime: parseInt(response.data?.nextResendTime || 0, 10),
      token: response.data.token,
    },
  };
};

export const ResetPasswordVerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);
  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response?.data
      ? {
          expireTime: parseInt(response.data.expireTime, 10),
          nextResendTime: parseInt(response.data.nextResendTime, 10),
          token: response.data.token,
        }
      : null,
  };
};

export const NewPasswordNonceMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      ...parseDataNonce(response.data),
      resetToken: response.data.resetToken,
    },
  };
};

const parseDataNonce = (data): PasswordNonceResponse => ({
  id: data.nonceId,
  key: data.nonceId,
  value: data.nonce,
  token: data.token,
});
