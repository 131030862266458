import Box from 'components/Box/Box';
import Button from 'components/Button';
import FormControl from 'components/FormControl';
import FormInput from 'components/FormControl/FormInput';
import FormInputPassword from 'components/FormControl/FormInputPassword';
import { InputContainer } from 'components/Input/styled';
import CircleLoader from 'components/Loader/CircleLoader';
import OpenEffect from 'components/OpenEffect';
import Text from 'components/Text';
import { LoginMethod } from 'config/constants/auth';
import FormValidator, { commomErrorMessage } from 'config/constants/formValidator';
import { AuthModalPageEnums } from 'config/types/authentication';
import { ValidationError } from 'config/types/validator';
import useCallbackValueOnDestroy from 'hooks/useCallbackValueOnDestroy';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useForm, { FieldState } from 'hooks/useForm';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import useModal from 'hooks/useModal';
import { useReferralCode } from 'hooks/useReferralCode';
import { useRequest } from 'hooks/useRequest';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AuthenticationService from 'services/AuthenticationService';
import { PasswordNonceResponse, RegisteredEmailPayload, VerificationResponse } from 'services/types';
import { useAppDispatch } from 'state';
import { setIsSigned, updateDeviceUid } from 'state/app/actions';
import { signedExecuteLogin, updateAuthToken } from 'state/auth/action';
import styled, { css } from 'styled-components';
import { Icons } from 'svgs';
import { constructEncryptedPassword } from 'utils/auth';
import { getID } from 'utils/fingerprint';
import { forkjoinRequest, transformHunnyRequest } from 'utils/requestHelper';
import CheckCondition from './CheckCondition';
import EmailVerificationModal from './modals/EmailVerificationModal';

const signupFormErrorMessages = {
  email: {
    [ValidationError.Required]: 'Please enter your email',
    [ValidationError.Email]: commomErrorMessage.Email,
  },
  password: {
    [ValidationError.Required]: 'Please enter your password',
  },
  isConfirmedTerms: {
    [ValidationError.NotChecked]: 'Please read and agree to the terms of service.',
  },
};

export type TraditionalFormProps = {
  saveEmail: (email: string) => void;
  initialEmail: string;
  setPage: (page: AuthModalPageEnums) => void;
  onClose?: () => void;
};

const buildRegisterEmailExistedFn = (value: string) => {
  const request = AuthenticationService.getTraditionalSignUpPrepare({ email: value, referralCode: null });
  return transformHunnyRequest(request, (res) => {
    return !!(res && !res.data);
  });
};

const TraditionalSignUpForm: React.FC<TraditionalFormProps> = ({ saveEmail, initialEmail, setPage, onClose }) => {
  const { t } = useTranslation();
  const { execute } = useRequest();
  const debounceCallback = useDebounceCallback();
  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal);
  const dispatch = useAppDispatch();
  const refCode = useReferralCode();

  const [submiting, setSubmiting] = useState(false);

  const { states, controls, validateAll, isValid } = useForm({
    email: {
      validators: [FormValidator.required, FormValidator.email, FormValidator.uniqueEmail(buildRegisterEmailExistedFn)],
      value: '',
    },
    password: {
      validators: [
        FormValidator.required,
        FormValidator.inputLength({ min: 8 }),
        FormValidator.requiredNumber,
        FormValidator.requiredUppercase,
        FormValidator.blockCharaters([' ']),
      ],
      value: '',
      validateOnChange: true,
    },
    isConfirmedTerms: {
      validators: [FormValidator.checked],
      value: true,
      validateOnChange: true,
    },
  });

  useIsomorphicEffect(() => {
    debounceCallback(() => {
      controls.email.onValueChanged(initialEmail);
    }, 200);
  }, [initialEmail]);

  useCallbackValueOnDestroy(states.email.value, saveEmail);

  const formatEmailErrorMessage = (errors: ValidationError[]) => {
    if (errors[0] === ValidationError.EmailDuplicate) {
      return (
        <>
          <Trans>This email is already in use. Please enter a different email or</Trans>{' '}
          <Text
            color="primary"
            display="inline"
            fontSize="12px"
            bold
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPage(AuthModalPageEnums.LOG_IN);
            }}
            id="link-to-tab-login"
          >
            <Trans>Log in</Trans>
          </Text>{' '}
          <Trans>to continue</Trans>
        </>
      );
    }

    return t(signupFormErrorMessages.email[errors[0]]);
  };

  const buidHandleVerifiedValidFn = (passwordNonce: PasswordNonceResponse) => {
    return async (verification: VerificationResponse, code: string) => {
      const deviceUid = await getID();

      const params: RegisteredEmailPayload = {
        email: states.email.value,
        password: constructEncryptedPassword(states.password.value, passwordNonce),
        deviceUid,
        verification: {
          ...verification,
          otp: code,
        },
        referralCode: refCode.refCode,
      };

      const result = await AuthenticationService.registerEmail(params);

      if (result?.data?.accessToken) {
        dispatch(updateDeviceUid({ deviceUid }));
        dispatch(
          signedExecuteLogin({
            loginBy: LoginMethod.Email,
          }),
        );
        dispatch(
          updateAuthToken({
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
          }),
        );
        dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }));
        if (onClose) onClose();
      }

      return result;
    };
  };

  const handleRegister = async () => {
    setSubmiting(true);
    const isValid = await validateAll();
    if (!isValid) {
      setSubmiting(false);
      return;
    }

    const [verification, passwordNonce] = await forkjoinRequest([
      execute(AuthenticationService.verifyRegisterEmail(states.email.value)),
      execute(AuthenticationService.getTraditionalSignUpPrepare({ email: states.email.value, referralCode: null })),
    ]);
    if (!verification?.data || !passwordNonce.data) {
      setSubmiting(false);
      // Show error
      return;
    }
    handlePresentEmailVerificationModal({
      email: states.email.value,
      initialVerifiedResult: verification.data,
      verifiedHunnyRequestFn: () =>
        transformHunnyRequest(AuthenticationService.verifyRegisterEmail(states.email.value), (res) => res?.data),
      onSubmit: buidHandleVerifiedValidFn(passwordNonce.data),
    });

    setSubmiting(false);
  };

  return (
    <OpenEffect openType="slideBottomToTop" duration={0.5}>
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleRegister();
        }}
      >
        <StyledFormControl state={states.email} label={t('Email')} formatErrorMessage={formatEmailErrorMessage}>
          <FormInput
            control={controls.email}
            placeholder={t('Email')}
            type="email"
            name="email"
            icon={<Icons.EmailIcon />}
            adornment={
              states.email.isValidating ? (
                <Box className="hide-when-focus" height="20px">
                  <CircleLoader />
                </Box>
              ) : null
            }
          />
        </StyledFormControl>

        <StyledFormControl
          mt="12px"
          state={states.password}
          label={t('Password')}
          formatErrorMessage={(errors) => t(signupFormErrorMessages.password[errors[0]])}
          instructionConfig={[
            { message: t('Use at least 8 characters'), validationError: ValidationError.TooShort },
            { message: t('Use 1 or more number'), validationError: ValidationError.RequiredNumber },
            { message: t('Use upper case characters'), validationError: ValidationError.RequiredUppercase },
            { message: t('Password does not allow space'), validationError: ValidationError.IncorrectType },
          ]}
        >
          <FormInputPassword control={controls.password} placeholder={t('Password')} icon={<Icons.PasswordIcon />} />
        </StyledFormControl>

        <Button
          type="submit"
          id="signup-submit-button"
          disabled={!isValid || submiting}
          width="100%"
          my="24px"
          onClick={handleRegister}
        >
          {submiting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
            <Trans>Create Account</Trans>
          </Text>
        </Button>

        <Box>
          <CheckCondition {...controls.isConfirmedTerms} />
        </Box>
      </Box>
    </OpenEffect>
  );
};

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty && !state.isValidating
      ? css`
          ${InputContainer} {
            background-color: transparent !important;
            border-color: ${theme.colors.success} !important;
          }
        `
      : ''}
`;

export default TraditionalSignUpForm;
