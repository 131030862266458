import Flex from 'components/Box/Flex';
import Modal, { ModalBody, ModalProps } from 'components/Modal';
import { UserBonus } from 'config/types/bonus/userBonus';

import Box from 'components/Box/Box';
import Button from 'components/Button';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { usePlayGame } from 'hooks/usePlayGame';
import { useCloseAllModal } from 'hooks/useModal';

interface DepositedActiveBonusModalProps {
  bonus: UserBonus;
}

const DepositedActiveBonusModal: React.FC<ModalProps<DepositedActiveBonusModalProps>> = ({
  onDismiss,
  data: { bonus },
}) => {
  const handlePlayGame = usePlayGame();
  const closeAllModal = useCloseAllModal();

  return (
    <StyledModal onDismiss={onDismiss}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="130px" height="120px">
            <Image src="/images/bonusCenter/gift-header-title.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            <Trans>Bonus Activated!</Trans>
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textSubtle"
            mt="8px"
            textAlign="center"
            maxWidth="320px"
            marginX="auto"
            lineHeight="17.07px"
          >
            {/* <Trans
              // Your bonus <highlight_tag>{{bonusName}}</highlight_tag> has been activated
              i18nKey="bonuseActivatedWhenDepositDesc"
              values={{ bonusName: buildBonusName(t, bonus)[0] }}
              components={{
                highlight_tag: <Text color="text" bold fontSize="inherit" display="inline !important" />,
              }}
            /> */}
            Your bonus
            <Text color="text" mx="4px" bold fontSize="inherit" display="inline !important">
              {bonus?.info?.name || ' '}
            </Text>
            has been activated
          </Text>
        </Flex>

        <Button
          maxWidth="290px"
          mx="auto"
          width="100%"
          mt="24px"
          onClick={() => {
            handlePlayGame();
            onDismiss();
            closeAllModal();
          }}
        >
          <Text small bold>
            <Trans>Check bonus</Trans>
          </Text>
        </Button>

        <Button variant="text" maxWidth="290px" mx="auto" width="100%" mt="4px" onClick={onDismiss}>
          <Text small bold color="textSubtle">
            <Trans>Close</Trans>
          </Text>
        </Button>
      </StyledModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 360px !important;
    max-width: 360px !important;
  }
`;

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`;

export default DepositedActiveBonusModal;
