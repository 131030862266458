import BigNumber from 'bignumber.js';
import { Token } from 'config/types';
import { useRequest } from 'hooks/useRequest';
import { useMemo, useState, useCallback, useEffect } from 'react';
import PaymentService from 'services/PaymentService';

export const useWithdrawFee = (selectedToken: Token) => {
  const { execute } = useRequest();
  const [withdrawFee, setWithdrawFee] = useState<string>('');

  const fetchFee = useCallback(
    async (amount: string) => {
      const response = await execute(
        PaymentService.getWithdrawFee({
          currency: selectedToken,
          amount,
        }),
      );

      if (response?.data) setWithdrawFee(response.data.withdrawFee);
    },
    [execute, selectedToken],
  );

  useEffect(() => {
    fetchFee('1');

    return () => {
      setWithdrawFee('0');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  return useMemo(() => {
    return { withdrawFee: new BigNumber(withdrawFee), updateFeeWithdraw: fetchFee };
  }, [fetchFee, withdrawFee]);
};
