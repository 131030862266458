import { useRouter } from 'hooks/useRouter';

const useQueryParam = () => {
  const router = useRouter();

  const removeParam = (param: string) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query as any);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
  };

  const addParam = (key: string, value: string) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query as any);
    params.set(key, value);
    router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
  };

  return {
    removeParam,
    addParam,
  };
};

export default useQueryParam;
