import FingerprintJS, { prepareForSources, sources, loadSources } from '@fingerprintjs/fingerprintjs';

let Visitor: any;

export async function getID(): Promise<string> {
  if (!Visitor) {
    await prepareForSources(100);
    const getComponents = loadSources(sources, {}, [
      'fontPreferences',
      'screenFrame',
      'screenResolution',
      'sessionStorage',
      'localStorage',
      'indexedDB',
      'openDatabase',
      'cookiesEnabled',
    ]);
    const components = await getComponents();

    Visitor = {
      components,
      visitorId: FingerprintJS.hashComponents(components),
    };
  }
  return Visitor.visitorId;
}
