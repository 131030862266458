import { BonusInfomation, IBonusCondition, ICommonBonus, IWageringCondition } from 'config/types/bonus';
import { ApiBonusTypeEnums } from 'config/types/bonus/index';
import {
  DepositBonus,
  IUserBonus,
  IUserWageringCondition,
  NoDepositBonus,
  UserBonus,
} from 'config/types/bonus/userBonus';
import { BaseResponse, Paging } from 'services/types';

export const UserBonusMapper = (rawResponse: string): BaseResponse<Paging<UserBonus>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response?.data && {
      items: response?.data?.map((item) => parseUserBonus(item)).filter((item) => item) || [],
    },
  };
};

export const UserBonusDetailsMapper = (rawResponse: string): BaseResponse<UserBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    };
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseUserBonus(response.data),
  };
};

export const parseUserBonus = (apiBonus: any): UserBonus => {
  const bonus: IUserBonus = {
    id: apiBonus.bonusId,
    apiStatus: apiBonus.status,
    ...parseCommonBonus(apiBonus),
  };

  const buildWageringConditionData = (): IUserWageringCondition => {
    return {
      ...parseWageringCondition(apiBonus),
      currentWager: apiBonus?.currentRakeFeeAmount,
      targetWager: apiBonus?.rakeFeeAmount,
    };
  };

  switch (apiBonus.bonusType) {
    case ApiBonusTypeEnums.DepositBonus:
      return new DepositBonus(bonus, buildWageringConditionData());
    case ApiBonusTypeEnums.NoDepositBonus:
      return new NoDepositBonus(bonus, buildWageringConditionData());
    default:
      return null;
  }
};

const parseCommonBonus = (apiBonus: any): ICommonBonus => {
  return {
    condition: parseBonusCondition(apiBonus),
    info: parseBonusInfomation(apiBonus),
  };
};

const parseBonusCondition = (apiBonus: any): IBonusCondition => {
  return {
    expiryDate: apiBonus.expiredTime,
  };
};

const parseBonusInfomation = (apiBonus: any): BonusInfomation => {
  return {
    image: apiBonus.image,
    name: apiBonus.title || '',
    badge: apiBonus.badgeTitle || '',
  };
};

const parseWageringCondition = (apiBonus: any): IWageringCondition => {
  return {
    minDeposit: apiBonus.minDeposit,
    rakeFeeRequirement: apiBonus.rakeFeeRequirement,
    activeDurationInDays: apiBonus.playthroughCompletionDuration,
  };
};
