import { MyProfileInfo } from 'config/types/profile';
import { BaseResponse } from 'services/types';
import { parseUserBalanceItem } from './Payment';

export const UserProfileMapper = (rawResponse: string): BaseResponse<MyProfileInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse);

  if (!response)
    return {
      code: 'network_error',
      data: null,
    };

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      referralCode: response.data.referralCode,
      referralsNumber: response.data.referralsNumber,
      referredBy: response.data.referredBy,
      socketId: response.data.socketId,
      uid: response.data.uID,
      preparedBonusId: response.data?.preparedBonusId || null,
      userAvatar:
        response.data?.userAvatar === '1' || !response.data?.userAvatar
          ? '/images/profile/avatar.png'
          : response.data?.userAvatar,
      username: response.data.userName,
      balances: response.data.balances.map(parseUserBalanceItem),
    },
  };
};
