export enum TransactionPageEnums {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Reward = 'reward',
  Bonus = 'bonus',
  Swap = 'swap',
}

export const TransactionPages = [
  {
    content: 'Deposit',
    value: TransactionPageEnums.Deposit,
  },
  {
    content: 'Withdraw',
    value: TransactionPageEnums.Withdraw,
  },
  {
    content: 'Reward',
    value: TransactionPageEnums.Reward,
  },
  {
    content: 'Bonus',
    value: TransactionPageEnums.Bonus,
  },
  {
    content: 'Swap',
    value: TransactionPageEnums.Swap,
  },
];

export const QUERY_TRANSACTION_INTERVAL = 5000;

export enum TransactionReceiptStatusEnum {
  SUCCESS = 1,
  FAILED = 0,
}

export enum QueryDepositStatusEnums {
  Init = 1,
  ScanFailed = -2,
  InvalidData = -3,
  InvalidMinimumFee = -4,
  Success = 10,
}
