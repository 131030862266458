import { ModalProps } from 'components/Modal';
import { useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Context } from '../contexts/Modals';

function useModal<S>(Component: React.FC<ModalProps<S>>) {
  const { onDismiss, onPresent } = useContext(Context);
  const [id, setId] = useState();

  const handlePresent = useCallback(
    (data?: S) => {
      const id = uuidv4();
      setId(id);
      onPresent(id, Component, data || {});
    },
    [Component, onPresent],
  );

  const handleOnDimiss = useCallback(() => {
    onDismiss(id);
  }, [onDismiss, id]);
  return [handlePresent, handleOnDimiss];
}

export function useCloseAllModal() {
  const { closeModal } = useContext(Context);

  return closeModal;
}

export default useModal;
