import Heading from 'components/Heading/Heading';
import { ModalBody, ModalHeader } from 'components/Modal';
import Modal, { ModalProps } from 'components/Modal/Modal';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import HistoryTransactionList from './HistoryTransactionList';

const HistoryTransaction: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <StyledModal onDismiss={onDismiss}>
      <ModalHeader>
        <Heading scale="xs" ml="12px">
          <Trans>Transaction History</Trans>
        </Heading>
      </ModalHeader>
      <ModalBody overflowY="auto" borderTopLeftRadius={theme.radii.default} borderTopRightRadius={theme.radii.default}>
        <StyledTransactionList>
          <HistoryTransactionList />
        </StyledTransactionList>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};
  min-height: 85vh;

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  & .modal-closebutton {
    right: 12px;
    top: 24px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 420px;
    max-width: 420px;
    min-height: 576px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;

const StyledTransactionList = styled(ModalBody)`
  overflow-y: scroll;
  padding: 24px 0;

  max-height: 75vh;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: 516px;
  }
  height: calc(100% - 48px);

  box-sizing: border-box;
`;
export default HistoryTransaction;
