import styled from 'styled-components';
import { variant } from 'styled-system';
import Text from '../Text/Text';
import { tags, scales, HeadingProps } from './types';

const styleScale = {
  [scales.XS]: {
    fontSize: ['14px', '14px', '16px', '16px'],
  },
  [scales.MD]: {
    fontSize: ['14px', '16px', '16px', '20px'],
  },
  [scales.LG]: {
    fontSize: ['18px', '20px', '20px', '24px'],
  },
  [scales.XL]: {
    fontSize: ['22px', '26px', '26px', '28px'],
  },
  [scales.XXL]: {
    fontSize: '44px',
  },
};

const Heading = styled(Text).attrs({ bold: true })<HeadingProps>`
  font-weight: 600;
  line-height: 1.1;

  ${variant({
    prop: 'scale',
    variants: styleScale,
  })}
`;

Heading.defaultProps = {
  as: tags.H2,
  scale: scales.MD,
};

export default Heading;
