import Flex from 'components/Box/Flex';
import Image from 'components/Image';
import Text from 'components/Text';
import { Network } from 'config/types';
import styled from 'styled-components';
import React from 'react';

const NetworkSelectItem: React.FC<{ item: Network }> = ({ item }) => {
  return (
    <StyledActiveNetwork id="network-select-item">
      <Image src={item?.networkInfo.icon} width={24} height={24} />
      <Text fontSize="14px" fontWeight="bold" color="textSubtle" ml="10px">
        {item?.networkInfo.shortName}
      </Text>
    </StyledActiveNetwork>
  );
};

const StyledActiveNetwork = styled(Flex)`
  width: 100%;

  align-items: center;
`;

export default NetworkSelectItem;
